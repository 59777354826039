import React from "react";
import { Navbar, Nav, Container, Image } from "react-bootstrap";
import AccessibilityBar from "../AccessibilityBar/AccessibilityBar";
import "./style.css";
import MainMenu from "../MainMenu/MainMenu";
import { links } from "../../pages/root/menuLinks";
import OffcanvasNavigation from "../OffcanvasNavigation/OffcanvasNavigationChanged";

const Header = () => {
  return (
    <header>
      <Container>
        <AccessibilityBar />
        <OffcanvasNavigation includeSearch={false} links={links} />
      </Container>
    </header>
  );
};

export default Header;

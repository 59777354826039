import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import "./style.css";

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container className="footerContainer">
        <Row className="d-flex">
          <Col
            md={12}
            className="d-flex items-stretch justify-content-between footerContainerContent"
          >
            <div className="column text-left first-footer-column">
              <Link to="/" className="navbar-brand">
                <img
                  className="footer-img"
                  src="/media/1053/bredbandsforum_vit.png"
                  alt="Bredbandsforum"
                />
              </Link>
            </div>
            <div className="column text-left second-footer-column">
              <h6>Bredbandsforum</h6>
              <p>
                <span>
                  Box 6101, 102 32 Stockholm
                  <br />
                  <a href="tel:08-678%2055 00" title="08-678 55 00">
                    08-678 55 00&nbsp;
                  </a>
                  <br />
                </span>{" "}
                <a href="mailto:kontakt@bredbandsforum.se" title="kontakt">
                  kontakt@bredbandsforum.se
                </a>
              </p>
            </div>
            <div className="column text-left">
              <ul>
                <li>
                  <Link to="/">Start</Link>
                </li>
                <li>
                  <Link to="/om-bredbandsforum/">Om Bredbandsforum</Link>
                </li>
                <li>
                  <Link to="/bredbandskoordinatorer/">
                    Bredbandskoordinatorer
                  </Link>
                </li>
                <li>
                  <Link to="/bredbandslyftet/">Bredbandslyftet</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

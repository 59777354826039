import { Container, Navbar, Row } from "react-bootstrap";
import "./style.css";

export const AccessibilityBar = () => {
  return (
    <Container className="accessibilityBar">
      <Navbar className="justify-content-end">
        <ul className="nav">
          <li className="nav-item align-items-center">
            <a
              className="nav-link"
              target="_self"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
            >
              <img
                src="/media/1090/brev.jpg"
                width="16"
                height="16"
                alt="Kontakta oss"
              />
              Kontakta oss
            </a>
          </li>
          <li className="nav-item align-items-center">
            <a className="nav-link" target="_self" href="/laettlaest/">
              <img
                src="/media/1089/ll.jpg"
                width="16"
                height="16"
                alt="Lättläst"
              />
              Lättläst
            </a>
          </li>
          <li className="nav-item align-items-center">
            <a className="nav-link" target="_self" href="/english/">
              <img
                src="/media/1088/glob.jpg"
                width="16"
                height="16"
                alt="English"
              />
              English
            </a>
          </li>
        </ul>
      </Navbar>
    </Container>
  );
};

export default AccessibilityBar;

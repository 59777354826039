import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Templet1 } from "../bredbandsforum/templates/templet1";

function ErrorPage() {
  return (
    <>
      <Footer />
      <Templet1
        headline="Sidan kunde inte hittas"
        images="/media/1237/visbyhav.jpg"
        textbody={[
          <div>
            <h3>
              Tyvärr har vi flyttat eller avpublicerat sidan du försökte komma
              till!
            </h3>
            <p>
              På Bredbandsforums nya webbplats kan du hitta bland annat hitta
              information om våra{" "}
              <a
                data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
                href="/faerdplan-foer-maaluppfyllnad-2025/"
                title="Pågående arbetsgrupper"
              >
                pågående
              </a>{" "}
              och{" "}
              <a
                data-udi="umb://document/41c4cb777ea642549ca051fd42d036ad"
                href="/avslutade-arbetsgrupper/"
                title="Avslutade arbetsgrupper"
              >
                avslutade
              </a>{" "}
              arbetsgrupper, kontaktuppgifter till Bredbandsforums kansli och de{" "}
              <a
                data-udi="umb://document/ac9b855af4a24bb788ff63a7501d274c"
                href="/bredbandskoordinatorer/"
                title="Bredbandskoordinatorer"
              >
                regionala bredbandskoordinatorerna
              </a>
              , eller läsa mer om regeringens{" "}
              <a
                data-udi="umb://document/d58cd2cbb7ea49678db520079b9a1a85"
                href="/sveriges-bredbandsstrategi/"
                title="Sveriges bredbandsstrategi"
              >
                bredbandsstrategi för Sverige
              </a>
              .
            </p>
            <p>
              Letar du efter Byanätsforum så har organisationen en ny webbplats:{" "}
              <a
                href="http://www.byanatsforum.se"
                title="Byanätsforums webbplats"
              >
                www.byanatsforum.se
              </a>
            </p>
            <p>
              Prova annars att börja om från{" "}
              <a
                data-udi="umb://document/156f1933e3274dceb665110d62720d03"
                href="/"
                title="Start"
              >
                startsidan
              </a>
              &nbsp;eller att göra en sökning på webbplatsen.
            </p>
          </div>,
        ]}
        cardtitle="Webbkarta nya bredbandsforum.se"
        carddata={[
          {
            textbody: (
              <a
                data-udi="umb://document/bedadca0dc9a456d9a858fa48ca9d306"
                href="/om-bredbandsforum/"
                title="Om Bredbandsforum"
              >
                Om Bredbandsforum
              </a>
            ),
          },
        ]}
      />
    </>
  );
}

export default ErrorPage;

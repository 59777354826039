import { Templet1 } from "../templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Riktlinjer för personuppgiftshantering"
        topParagraph="Så här behandlar Bredbandsforums kansli personuppgifter som inhämtas genom webbplatsen www.bredbandsforum.se"
        images="/media/1111/1575918-locked-keyboard.jpg"
        textbody={[
          <h3>Personuppgiftsansvar</h3>,
          <p>
            Bredbandsforums kansli ansvarar för det löpande arbetet i
            Bredbandsforum. Kansliet administreras av myndigheten Post- och
            telestyrelsen (org.nr 202100-4359), och Post- och telestyrelsen är
            därför personuppgiftsansvarig för behandling av personuppgifter och
            ansvarar för att personuppgifter behandlas i enlighet med gällande
            lagstiftning. Har du frågor om hur Bredbandsforums kansli hanterar
            personuppgifter kan du kontakta kansliet på{" "}
            <a href="http://bredbandsforum.se/Templates/Pages/kontakt@bredbandsforum.se">
              kontakt@bredbandsforum.se
            </a>{" "}
            eller Post- och telestyrelsens dataskyddsombud på{" "}
            <a href="http://bredbandsforum.se/Templates/Pages/dataskyddsombudet@pts.se">
              dataskyddsombudet@pts.se
            </a>
            .
          </p>,
          <hr />,
          <h3>Ändamål</h3>,
          <p>
            Bredbandsforums kansli behandlar personuppgifter som samlas in via
            webbplatsen vid:
          </p>,
          <ul>
            <li>Beställning av informationsmaterial</li>
            <li>Prenumeration via e-post på nyhetsbrev</li>
            <li>Hantering av anmälningar till Bredbandsforums arrangemang</li>
          </ul>,
          <hr />,
          <h3>Offentlighetsprincipen</h3>,
          <p>
            Eftersom Bredbandsforums kansli är placerat på den statliga
            myndigheten Post- och telestyrelsen omfattas vi av
            offentlighetsprincipen. Den innebär att handlingar hos myndigheten,
            inklusive personuppgifter, kan bli allmänna handlingar som kan komma
            att lämnas ut till den som begär det. I vissa fall kan dock
            uppgifter anses vara sekretessbelagda och lämnas därför inte ut. Läs
            mer om offentlighetsprincipen och om sekretess på{" "}
            <a href="https://www.regeringen.se/sa-styrs-sverige/det-demokratiska-systemet-i-sverige/offentlighetsprincipen/">
              regeringens webbplats.
            </a>
          </p>,
          <hr />,
          <h3>Överföring av personuppgifter</h3>,
          <p>
            Bredbandsforums kansli kan komma att vidarebefordra personuppgifter
            som lämnats via webbplatsen om vi är fel instans för informationen
            och denna bör skickas vidare för att hamna rätt.
          </p>,
          <hr />,
          <h3>Rättigheter</h3>,
          <p>
            För att ändra, lägga till eller ta bort uppgifter om dig själv eller
            invända mot en personuppgiftsbehandling, eller om du vill veta vilka
            uppgifter som finns sparade om dig (registerutdrag), kan du kontakta
            Bredbandsforums kansli eller Post- och telestyrelsen.{" "}
            <a href="https://www.pts.se/sv/om-pts/om-webbplatsen/juridisk-information/behandling-av-personuppgifter/behandling-av-personuppgifter---rattigheter/">
              Mer information om dina rättigheter finns här
            </a>
            .
          </p>,
          <p>
            För att klaga på något som rör behandlingen av dina personuppgifter
            kan du höra av dig till Bredbandsforums kansli, Post- och
            telestyrelsen, samt även till Datainspektionen.
          </p>,
          <hr />,
          <h3>Gallring</h3>,
          <p>
            Personuppgifter som samlats in för beställning av
            informationsmaterial och hantering av anmälningar till
            Bredbandsforums arrangemang kommer att finnas kvar hos
            Bredbandsforums kansli tills syftet med insamlingen inte längre
            kvarstår, dvs tills material skickats eller anmälan behandlats.
            Personuppgifter som samlats in för prenumeration på Bredbandsforums
            nyhetsbrev kommer att finnas kvar till dess du meddelar att du vill
            att de tas bort.
          </p>,
          <hr />,
          <h3>Mer information</h3>,
          <p>
            Mer utförlig information om Post- och telestyrelsens hantering av
            personuppgifter hittar du på myndighetens webbplats:{" "}
            <a href="https://www.pts.se/sv/om-pts/om-webbplatsen/juridisk-information/behandling-av-personuppgifter/">
              https://www.pts.se/sv/om-pts/om-webbplatsen/juridisk-information/behandling-av-personuppgifter/
            </a>
          </p>,
        ]}
      />
    </>
  );
}
export default Page;

import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="The Swedish Broadband Forum"
        topParagraph="The Swedish Broadband Forum is an important meeting place, an arena for dialogue and collaboration between the government, public authorities and organisations, businesses and operators in the Swedish broadband market."
        images="/media/1094/3058204-ljummen-aprilkvall.jpg"
        textbody={[
          <h3>Cooperation towards world-class broadband</h3>,
          <p>
            Organized in 2010, the Swedish Broadband Forum is part of the
            government's broadband strategy to promote deployment of broadband
            throughout Sweden. The work is characterised by seeking solutions
            through mutually beneficial projects and discussions.
          </p>,
          <p>
            The overall objective of the{" "}
            <a href="https://digital-strategy.ec.europa.eu/en/policies/broadband-sweden">
              Swedish Broadband Strategy
            </a>{" "}
            is that Sweden should have world-class broadband, which means that,
            in the short term, 95 per cent of all households and businesses
            should have access to broadband at a rate of at least 100 Mbps by
            2020. In the long term, the government has created goals in two
            areas; access to high-speed broadband in all of Sweden and access to
            reliable and high-quality mobile services. The objective of these
            goals is that 98 percent of the population should have access to
            broadband at a minimum capacity of 1 Gbps home, as well as in the
            workplace, the remaining 1,9 percent at a minimum capacity of 100
            Mbps, and 0,1 percent at a minimum capacity of 30 Mbps, no later
            than the year 2025. The goals also include everyone’s access to
            reliable and high-quality mobile services, where they usually are
            found, no later than the year 2023.
          </p>,
          <p>
            To run the day-to-day activities in the Swedish Broadband Forum an
            administrative office is stationed at the{" "}
            <a href="https://pts.se/en/">
              Swedish Post and Telecom Agency (PTS)
            </a>
            .
          </p>,
        ]}
        cardtitle="Contact us"
        carddata={[
          {
            headline: <h6>E-mail:</h6>,
            textbody: (
              <a href="mailto:kontakt@bredbandsforum.se">
                kontakt@bredbandsforum.se
              </a>
            ),
          },

          {
            headline: <h6>Postal address:</h6>,
            textbody: <p>Bredbandsforum, Box 6101, 102 32 Stockholm</p>,
          },

          {
            headline: <h6>Visiting address:</h6>,
            textbody: (
              <p>Post- och telestyrelsen, Hälsingegatan 38, Stockholm</p>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

import { Templet1 } from "../templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Tillgänglighet för Bredbandsforum.se"
        topParagraph="Bredbandsforums kansli står bakom den här webbplatsen. Vi vill att så många som möjligt ska kunna använda webbplatsen. Den här artikeln beskriver hur Bredbandsforum.se uppfyller lagen om tillgänglighet till digital offentlig service, eventuella kända tillgänglighetsproblem och hur du kan rapportera brister till oss så att vi kan åtgärda dem."
        images="/media/1267/3688240-a-satellite-in-orbit.jpg"
        textbody={[
          <h3>Hur tillgänglig är webbplatsen?</h3>,
          <p>
            Den här webbplatsen är förenlig med&nbsp;lagen om tillgänglighet
            till digital offentlig service.
          </p>,
          <hr />,
          <h3>Vad kan du göra om du inte kan använda delar av webbplatsen?</h3>,
          <p>
            Om du behöver information från www.bredbandsforum.se på något annat
            format, till exempel tillgänglig PDF, stor text, Lättläst,
            ljudinspelning eller punktskrift kan du kontakta oss på följande
            sätt:
          </p>,
          <ul>
            ,
            <li>
              skicka e-post till{" "}
              <a href="mailto:mailto@kontakt@bredbandsforum.se">
                kontakt@bredbandsforum.se
              </a>
            </li>
            <li>ring 08-678 55 00</li>
          </ul>,
          <hr />,
          <h3>Rapportera brister i webbplatsens tillgänglighet</h3>,
          <p>
            Vi strävar hela tiden efter att förbättra webbplatsens
            tillgänglighet. Om du upptäcker problem som inte är beskrivna på den
            här sidan, eller om du anser att vi inte uppfyller lagens krav,{" "}
            <a
              data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
              title="Bredbandsforums kansli"
            >
              kontakta oss
            </a>{" "}
            så att vi får veta att problemet finns.
          </p>,
          <hr />,
          <h3>Tillsyn</h3>,
          <p>
            Myndigheten för digital förvaltning har ansvaret för tillsyn
            för&nbsp;lagen om tillgänglighet till digital offentlig service. Om
            du inte är nöjd med hur vi hanterar dina synpunkter kan du&nbsp;
            <span>
              <span>
                <a href="https://www.digg.se/utveckling--innovation/digital-tillganglighet/anmal-bristande-tillganglighet">
                  kontakta Myndigheten för digital förvaltning
                </a>
              </span>
            </span>
            &nbsp;och påtala det.
          </p>,
          <hr />,
          <h3>Teknisk information om webbplatsens tillgänglighet</h3>,
          <p>
            Den här webbplatsen är förenlig med nivå AA i standarden&nbsp;
            <span>
              <span>
                <a href="https://www.w3.org/TR/WCAG21/">
                  Web Content Accessibility Guidelines version 2.1.
                  <span>
                    <span></span>
                  </span>
                </a>
              </span>
            </span>
          </p>,
          <hr />,
          <h3>Hur vi testat webbplatsen</h3>,
          <p>
            En extern aktör har gjort en oberoende granskning av
            www.bredbandsforum.se.
          </p>,
          <p>Webbplatsen publicerades den 29 januari 2019.</p>,
          <p>
            Senaste bedömningen av webbplatsens tillgänglighet gjordes den 3
            juli 2019.
          </p>,
          <p>Identifierade fel åtgärdades under 2019.</p>,
          <p>Redogörelsen uppdaterades senast den 10 mars 2020.</p>,
        ]}
      />
    </>
  );
}
export default Page;

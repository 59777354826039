import { Link, createBrowserRouter } from "react-router-dom";
import English from "./pages/bredbandsforum/english";
import RootLayout from "./pages/root/Root";

export const newslist = [
  {
    route: "bredbandsforums-nyhetsbrev-december-2018",

    pageData: {
      headline: "Bredbandsforums nyhetsbrev – december 2018",
      createDate: "18 december 2018",
      textBody: [
        <div>
          <p className="ingress">
            Med{" "}
            <a
              data-udi="umb://document/23ba939607e1464ab5a68a5806faba20"
              href="/om-bredbandsforum/bredbandsforums-nyhetsbrev/"
              title="Bredbandsforums nyhetsbrev"
            >
              Bredbandsforums nyhetsbrev
            </a>{" "}
            vänder vi oss till dig som är intresserad av vad som är på gång inom
            Bredbandsforum med arbetet att ge Sverige bredband i världsklass.
          </p>

          <p>
            Det artonde numret publicerades i december 2018 och innehåller
            följande artiklar:
          </p>
          <ul>
            <li>Uppkopplad! – Nationell bredbandskonferens i Vara</li>
            <li>Bredbandslyftet</li>
            <li>Infrastruktur för digitalisering</li>
            <li>Nya Byanätsforum</li>
            <li>Två år med utbyggnadslagen</li>
            <li>Material från Bredbandsforums styrgrupp</li>
            <li>Nöjdare företag i kommuner med snabbt bredband</li>
            <li>Ny teknik för mobilt bredband</li>
          </ul>
          <p>
            <strong>
              Läs Bredbandsforums artonde nyhetsbrev – december 2018
            </strong>
          </p>
          <p>
            <strong>
              Ladda ned en PDF-version av artikeln "Ny teknik för mobilt
              bredband"
            </strong>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "pts-vaegledning-om-traadloes-teknik",

    pageData: {
      headline: "PTS vägledning om trådlös teknik",
      createDate: "17 januari 2019",
      textBody: [
        <div>
          <p className="ingress">
            PTS har genomfört ett regeringsuppdrag att ta fram vägledning och
            information till regionala och lokala aktörer kring hur fasta och
            trådlösa tekniker kan komplettera varandra för att nå uppsatta
            bredbandsmål.
          </p>

          <p>
            PTS fick förra året i uppdrag av regeringen att ta fram material för
            vägledning och information till regionala och lokala aktörer om hur
            trådbundna och trådlösa tekniker kan komplettera varandra för att
            bidra till att nå uppsatta bredbandsmål.
          </p>
          <p>
            Vägledningen och informationen ska tydliggöra på vilket sätt
            trådbundna och trådlösa tekniker kan komplettera varandra för att
            bidra till att nå uppsatta bredbandsmål.
          </p>
          <p>
            <a href="https://www.pts.se/globalassets/startpage/dokument/bransch/radio/arrangemang-och-forum/tradlosa-tekniker/pts_vagledning-tradlosa-tekniker.pdf">
              Ladda ned vägledningen på från PTS webbplats&nbsp;
            </a>
            <a
              href="https://www.pts.se/globalassets/startpage/bransch/radio/arrangemang-och-forum/tradlosa-tekniker/pts_vagledning-tradlosa-tekniker.pdf"
              title="Vägledning om trådlös teknik (PDF)"
            >
              (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "nya-bredbandsforumse",

    pageData: {
      headline: "Nya bredbandsforum.se",
      createDate: "29 januari 2019",
      textBody: [
        <div>
          <p className="ingress">Välkommen till nya bredbandsforum.se!</p>

          <p>
            Bredbandsforums webbplats har fått en uppdaterad design, ny grafisk
            profil, och bättre funktionalitet. Vår förhoppning är att det nu ska
            vara enklare för dig som besökare att hitta det du söker med färre
            klick. Som tidigare kan du läsa om Bredbandsforums{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              pågående
            </a>{" "}
            och{" "}
            <a
              data-udi="umb://document/41c4cb777ea642549ca051fd42d036ad"
              href="/avslutade-arbetsgrupper/"
              title="Avslutade arbetsgrupper"
            >
              avslutade
            </a>{" "}
            arbetsgrupper, om{" "}
            <a
              data-udi="umb://document/bedadca0dc9a456d9a858fa48ca9d306"
              href="/om-bredbandsforum/"
              title="Om Bredbandsforum"
            >
              Bredbandsforums uppdrag
            </a>{" "}
            inom ramen för den{" "}
            <a
              data-udi="umb://document/d58cd2cbb7ea49678db520079b9a1a85"
              href="/sveriges-bredbandsstrategi/"
              title="Sveriges bredbandsstrategi"
            >
              nationella bredbandsstrategin
            </a>
            , samt om de{" "}
            <a
              data-udi="umb://document/ac9b855af4a24bb788ff63a7501d274c"
              href="/bredbandskoordinatorer/"
              title="Bredbandskoordinatorer"
            >
              regionala bredbandskoordinatorernas
            </a>{" "}
            arbete. Du kan även ta del av allt{" "}
            <a
              data-udi="umb://document/c4deb14b8bd74601a6da2cbd5fec3dd1"
              href="/om-bredbandsforum/material-fraan-styrgruppen/"
              title="Material från styrgruppen"
            >
              material
            </a>{" "}
            från Bredbandsforums styrgruppsmöten samt så klart prenumerera på{" "}
            <a
              data-udi="umb://document/23ba939607e1464ab5a68a5806faba20"
              href="/om-bredbandsforum/bredbandsforums-nyhetsbrev/"
              title="Bredbandsforums nyhetsbrev"
            >
              Bredbandsforums nyhetsbrev
            </a>
            .
          </p>
          <p>
            Har du synpunkter på eller frågor om webbplatsen kan du kontakta
            kansliet på{" "}
            <a href="mailto:kontakt@bredbandsforum.se">
              kontakt@bredbandsforum.se
            </a>
            .
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "efteranslutningar",

    pageData: {
      headline:
        "Utmaningar med att efteransluta fastigheter, hushåll och företag till fibernät",
      createDate: "1 april 2019",
      textBody: [
        <div>
          <p>
            Under vintern har Bredbandsforum arbetat med att identifiera
            utmaningar kring att efteranslutningar till fibernätet, och förslag
            på hur olika aktörer kan förenkla processen. I{" "}
            <a
              data-udi="umb://media/9e4c1a8d52514db49a67e007189789f4"
              href="/media/1238/utmaningar-med-efteranslutningar-bredbandsforums-kansli-februari-2019.pdf"
              title="Utmaningar med efteranslutningar - Bredbandsforums kansli februari 2019.pdf"
            >
              rapporten
            </a>{" "}
            kan man även läsa om vad som kännetecknar olika typer av
            efteranslutningar och vilka frågeställningar som är aktuella att
            arbeta vidare med.
          </p>

          <p>
            År 2025 bör hela Sverige ha tillgång till snabbt bredband. Så är det
            slutgiltiga målet i regeringens bredbandsstrategi formulerat. Målet
            består i sin tur av tre delmål som alla ska vara uppfyllda för att
            det övergripande målet ska vara uppfyllt. Det första delmålet är att
            98 procent av alla hushåll och företag i Sverige antingen bör ha
            tillgång till 1 Gbit/s eller ha fiber eller motsvarande i sin
            absoluta närhet och därmed möjlighet att köpa en anslutning.
          </p>
          <p>
            Att ett hushåll eller ett företag har fiber i sin absoluta närhet
            men inte har tillgång till en anslutning kan ha olika orsaker. Vissa
            har tackat nej till ett erbjudande om en fiberanslutning när
            möjligheten fanns. Utbyggnaden kan ha stannat vid en viss punkt, t
            ex på grund av hur ett område har styckats upp i projekteringen,
            eller på grund av att en kommungräns står i vägen för ett kommunalt
            stadsnät att ansluta fastigheter i en angränsande kommun. Oavsett
            anledning kommer en viss andel av de hushåll och företag som
            delmålet omfattar inte ha möjlighet att köpa en bredbandsanslutning
            utan ytterligare arbete och kostnader, trots att ett fibernät finns
            i närheten.
          </p>
          <p>
            Regeringens bredbandsmål syftar till att säkerställa att Sveriges
            hushåll och företag inte ska uppleva begränsningar utifrån de
            digitala tjänster som de efterfrågar och använder. Det innebär att
            en så stor andel som möjligt av de som har fiber i sin absoluta
            närhet bör realisera denna närhet i en faktisk anslutning. Detta
            sker genom att nätbyggarna efteransluter fastigheter till
            fibernätet.
          </p>
          <p>
            Bredbandsforum har därför arbetat med att identifiera de viktigaste
            utmaningarna på området, och vad olika aktörer kan göra för att
            underlätta processen. Resultatet finns att läsa om i en rapport som
            du hittar{" "}
            <a
              data-udi="umb://media/9e4c1a8d52514db49a67e007189789f4"
              href="/media/1238/utmaningar-med-efteranslutningar-bredbandsforums-kansli-februari-2019.pdf"
              title="Utmaningar med efteranslutningar - Bredbandsforums kansli februari 2019.pdf"
            >
              här.
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Dokument",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/9e4c1a8d52514db49a67e007189789f4"
              href="/media/1238/utmaningar-med-efteranslutningar-bredbandsforums-kansli-februari-2019.pdf"
              title="Utmaningar med efteranslutningar - Bredbandsforums kansli februari 2019.pdf"
            >
              <strong>
                Ladda ned rapporten om att efteransluta fastigheter, hushåll och
                företag till fibernät <span id=">_som_pilhuvud">&gt;</span>
              </strong>
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route:
      "sveriges-digitala-konkurrenskraft-staar-sig-vael-i-internationell-jaemfoerelse",

    pageData: {
      headline:
        "Sveriges digitala konkurrenskraft står sig väl i internationell jämförelse",
      createDate: "12 juni 2019",
      textBody: [
        <div>
          <p className="ingress">
            Regeringens digitaliseringsstrategi sätter målet att Sverige ska
            bäst i världen på att använda digitaliseringens möjligheter. EU:s
            index för digital ekonomi och samhälle 2018, Desi, visar att vi är
            på väg i rätt riktning.
          </p>
          <p>
            DESI är ett verktyg för att jämföra hur länder i EU lyckas med
            digitaliseringen av samhället.
          </p>
          <p data-text="633">
            I årets undersökning hamnar Sverige på en andraplats, tätt efter
            Finland. Sveriges totalvärde i indexet har ökat sedan förra året och
            är nu 70,4, att jämföra med genomsnittet i EU på 54.
          </p>
          <p data-text="886">
            De fem områden som EU analyserar är tillgång till bredband,
            humankapital, användning av internet, integrering av digital teknik
            och det offentligas digitalisering.
          </p>
          <p data-text="633">
            <a
              data-udi="umb://media/e7ee358ff6bf4d06a8b533aaccb1f9cc"
              href="/media/1244/desi.jpg"
              title="DESI.jpg"
            >
              <img
                id="__mcenew"
                src="/media/1244/desi.jpg"
                alt="Sveriges digitala konkurrenskraft står sig väl i internationell jämförelse"
                data-udi="umb://media/e7ee358ff6bf4d06a8b533aaccb1f9cc"
              />
            </a>
          </p>
          <p>
            Läs hela rapporten på Eu-kommissionens webbplats:{" "}
            <a href="https://ec.europa.eu/digital-single-market/desi">
              https://ec.europa.eu/digital-single-market/desi{" "}
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "vaegledning-om-hur-kommuner-boer-agera-paa-bredbandsmarknaden",

    pageData: {
      headline: "Vägledning om hur kommuner bör agera på bredbandsmarknaden",
      createDate: "17 juni 2019",
      textBody: [
        <div>
          <p>
            Post- och telestyrelsen (PTS) publicerade 2018 en rapport om
            kommunernas roller på bredbandsmarknaden. Myndigheten har nu omsatt
            rapporten till en praktisk vägledning som kan användas av
            beslutsfattare eller tjänstemän inom kommuner och stadsnät när det
            gäller bredbandsfrågor.
          </p>
          <p>
            Kommuner är viktiga aktörer i bredbandsutbyggnaden. Genom att agera
            i rollen som t ex tillståndsgivare eller markägare påverkar kommunen
            förutsättningarna för olika aktörers utbyggnad av fast och mobilt
            bredband. I många fall äger också kommunerna stadsnät som bygger
            bredbandsnät och agerar på marknaden i konkurrens med privata
            aktörer.
          </p>
          <p>
            Den nya vägledningen innehåller tips inom olika aspekter av
            bredbandsområdet.
          </p>
          <p>
            Ta del av vägledningen på{" "}
            <a href="https://www.pts.se/globalassets/startpage/dokument/ovrigt/ovrig-extern-info/pts_brochure_09.pdf">
              PTS webbplats
            </a>
            .
          </p>
          <p>
            På{" "}
            <a href="https://www.youtube.com/watch?v=-dIxLYeurT0&amp;feature=youtu.be">
              Youtube
            </a>{" "}
            kan du se en kort informationsfilm om vägledningen.
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "infrastruktur-for-digitalisering",

    pageData: {
      headline:
        "Helhetsgrepp om robusthet och tillförlitlighet en förutsättning för samhällets digitalisering",
      createDate: "18 juni 2019",
      textBody: [
        <div>
          <p>
            <strong>
              Bredbandsforums arbetsgrupp Infrastruktur för digitalisering har
              lämnat sin slutrapport till Bredbandsforums styrgrupp och
              digitaliseringsminister Anders Ygeman. Enligt arbetsgruppen behövs
              nya nätverk, stödfunktioner och uppdrag på robusthetsområdet för
              att främja införandet av digitala tjänster.
            </strong>
          </p>
          <p>
            Bredbandsforums arbetsgrupp{" "}
            <em>Infrastruktur för digitalisering</em> föreslår i en ny rapport
            åtgärder för en ökad robusthet och tillförlitlighet i
            bredbandsinfrastrukturen. I slutrapporten kartläggs den kedja av
            olika typer av bredbandsinfrastruktur, aktörer och lösningar som
            måste samspela för att säkerställa en god robusthet och
            tillförlitlighet. Arbetsgruppens slutsats är att det finns brister,
            och att vi ännu inte är redo att möta de krav som morgondagens
            digitala tjänster kommer att ställa. Det finns otydligheter kring
            roller och mandat, vem som har ansvar för vad och hur avtal bör
            utformas. Det finns också ett mycket stort antal nätägare i Sverige
            med varierande förutsättningar att garantera en tillförlitlig
            bredbandsanslutning.
          </p>
          <p>
            Arbetsgruppen lämnar i slutrapporten flera förslag som syftar till
            att stödja de aktörer som ska möta dessa utmaningar, och vissa
            aktiviteter har redan initierats. Nya nätverk för samverkan och
            erfarenhetsutbyte behövs, och bland annat kommer SKL nu att etablera
            ett beställarnätverk för kommuner och regioner. Den nationella nivån
            kan också ta ett större ansvar, och arbetsgruppen föreslår ett
            nationellt samverkansforum för infrastruktur för välfärdens
            digitalisering. Det är viktigt att myndigheter får nya uppdrag som
            syftar till att förtydliga roller och ansvar på området, och
            genomföra övningar som kan ge stöd och vägledning. Arbetsgruppen har
            även kartlagt hur robusthetsfrågor kan hanteras av Sveriges
            fiberföreningar, ett arbete som kommer tas vidare av Byanätsforum.
          </p>
          <p>
            <em>
              Sveriges bredbandsnät måste vara robusta och tillförlitliga om
              användningen av digitala tjänster ska ta fart på allvar. Om inte
              beställare och användare kan lita på infrastrukturen kommer det
              att hämma digitaliseringen. Möjligheten att ta del av digitala
              tjänster ska inte heller bero på vem som äger och driver nätet där
              man bor. Alla aktörer kommer vara viktiga i det fortsatta arbetet
              för en infrastruktur som kan bära framtidens tjänster,
            </em>{" "}
            säger arbetsgruppens ordförande Dan Sjöblom.
          </p>
          <p>
            <a
              data-udi="umb://media/ce9956c705a6430597247b9b89ddc078"
              href="/media/1251/slutrapport-infrastruktur-foer-digitalisering.pdf"
              title="Slutrapport Infrastruktur för digitalisering.pdf"
            >
              <strong>
                Klicka här för att läsa arbetsgruppens slutrapport
              </strong>
            </a>
          </p>
          <ul>
            <li>
              <a
                data-udi="umb://media/c261f5a98b3f4e0faf42cee2ecab29eb"
                href="/media/1245/bilaga-1-analys-av-aktoerskedjor.pdf"
                title="Bilaga 1 Analys av aktörskedjor.pdf"
              >
                Läs bilaga 1: Analys av aktörskedjor
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/bbfc4bcdb5bd44a1848af084e0fad1b3"
                href="/media/1246/bilaga-2-foerstudie-byanaet-kvalitet-och-robusthet.pdf"
                title="Bilaga 2 Förstudie byanät-kvalitet och robusthet.pdf"
              >
                Läs bilaga 2: Förstudie byanät - kvalitet och robusthet
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/5a245d2ce32d4201b819b65256a8380a"
                href="/media/1247/bilaga-3-foerslag-om-oevning-vad-haender-om-naetet-gaar-ner.pdf"
                title="Bilaga 3 Förslag om övning – Vad händer om nätet går ner.pdf"
              >
                Läs bilaga 3: Förslag om övning – Vad händer om nätet går ner
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/be41e5f04ff945a792d59788a7ee4cf8"
                href="/media/1248/bilaga-4-krav-paa-bredbandsanslutning-foer-offentliga-digitala-tjaenster.pdf"
                title="Bilaga 4 Krav på bredbandsanslutning för offentliga digitala tjänster.pdf"
              >
                Läs bilaga 4: Krav på bredbandsanslutning för offentliga
                digitala tjänster
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/934d41f3b2334dd7a862751e612b80ab"
                href="/media/1249/bilaga-5-foerslag-om-bestaellarnaetverk.pdf"
                title="Bilaga 5 Förslag om beställarnätverk.pdf"
              >
                Läs bilaga 5: Förslag om beställarnätverk
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/aafd490964c14b6c97643f36d25e9798"
                href="/media/1250/bilaga-6-checklista-foer-byanaet-i-foervaltningsfasen.pdf"
                title="Bilaga 6 Checklista för byanät i förvaltningsfasen.pdf"
              >
                Läs bilaga 6: Checklista för byanät i förvaltningsfasen
              </a>
            </li>
          </ul>
        </div>,
      ],
      asideTitle: "Dokument",
      asideBody: [
        <div>
          <ul>
            <li>
              <a
                data-udi="umb://media/ce9956c705a6430597247b9b89ddc078"
                href="/media/1251/slutrapport-infrastruktur-foer-digitalisering.pdf"
                title="Slutrapport Infrastruktur för digitalisering.pdf"
              >
                <strong>
                  Klicka här för att läsa arbetsgruppens slutrapport
                </strong>
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/c261f5a98b3f4e0faf42cee2ecab29eb"
                href="/media/1245/bilaga-1-analys-av-aktoerskedjor.pdf"
                title="Bilaga 1 Analys av aktörskedjor.pdf"
              >
                Läs bilaga 1: Analys av aktörskedjor
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/bbfc4bcdb5bd44a1848af084e0fad1b3"
                href="/media/1246/bilaga-2-foerstudie-byanaet-kvalitet-och-robusthet.pdf"
                title="Bilaga 2 Förstudie byanät-kvalitet och robusthet.pdf"
              >
                Läs bilaga 2: Förstudie byanät - kvalitet och robusthet
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/5a245d2ce32d4201b819b65256a8380a"
                href="/media/1247/bilaga-3-foerslag-om-oevning-vad-haender-om-naetet-gaar-ner.pdf"
                title="Bilaga 3 Förslag om övning – Vad händer om nätet går ner.pdf"
              >
                Läs bilaga 3: Förslag om övning – Vad händer om nätet går ner
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/be41e5f04ff945a792d59788a7ee4cf8"
                href="/media/1248/bilaga-4-krav-paa-bredbandsanslutning-foer-offentliga-digitala-tjaenster.pdf"
                title="Bilaga 4 Krav på bredbandsanslutning för offentliga digitala tjänster.pdf"
              >
                Läs bilaga 4: Krav på bredbandsanslutning för offentliga
                digitala tjänster
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/934d41f3b2334dd7a862751e612b80ab"
                href="/media/1249/bilaga-5-foerslag-om-bestaellarnaetverk.pdf"
                title="Bilaga 5 Förslag om beställarnätverk.pdf"
              >
                Läs bilaga 5: Förslag om beställarnätverk
              </a>
            </li>
            <li>
              <a
                data-udi="umb://media/aafd490964c14b6c97643f36d25e9798"
                href="/media/1250/bilaga-6-checklista-foer-byanaet-i-foervaltningsfasen.pdf"
                title="Bilaga 6 Checklista för byanät i förvaltningsfasen.pdf"
              >
                Läs bilaga 6: Checklista för byanät i förvaltningsfasen
              </a>
            </li>
          </ul>
        </div>,
      ],
    },
  },
  {
    route:
      "pts-ska-konkretisera-utformningen-av-framtidens-stoedinsatser-foer-bredbandsutbyggnaden",

    pageData: {
      headline:
        "PTS ska konkretisera utformningen av framtidens stödinsatser för bredbandsutbyggnaden",
      createDate: "27 juni 2019",
      textBody: [
        <div>
          <p className="ingress">
            Regeringen har i dag, i Post- och telestyrelsens regleringsbrev,
            gett myndigheten i uppdrag att främja utbyggnaden av snabbt bredband
            och konkretisera hur framtidens stöd för utbyggnaden av snabbt
            bredband skulle kunna utformas på ett effektivt sätt.
          </p>
          <p>
            PTS presenterade i november 2017 ett förslag om en centralt styrd
            nationell stödordning för bredbandsutbyggnad där offentliga aktörer
            gör kartläggningar och prioriteringar över de områden som behöver
            stöd samt konkurrensutsätter bredbandsutbyggnaden.
          </p>
          <p>
            Nu får PTS i uppdrag att utifrån det tidigare förslaget konkretisera
            hur framtida stödinsatser ska kunna utformas på ett effektivt sätt.
          </p>
          <p>
            PTS ska redovisa uppdraget till regeringen senast den 17 januari
            2020.
          </p>
          <p>
            Ta del av pressmeddelandet på regeringens webbplats:{" "}
            <a href="https://www.regeringen.se/pressmeddelanden/2019/06/pts-ska-konkretisera-utformningen-av-framtidens-stodinsatser-for-bredbandsutbyggnaden/"></a>
          </p>
          <p>
            <a href="https://www.regeringen.se/pressmeddelanden/2019/06/pts-ska-konkretisera-utformningen-av-framtidens-stodinsatser-for-bredbandsutbyggnaden/">
              https://www.regeringen.se/pressmeddelanden/2019/06/pts-ska-konkretisera-utformningen-av-framtidens-stodinsatser-for-bredbandsutbyggnaden/
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "bredbandsforum-ska-fraemja-infrastruktur-foer-mobilt-bredband",

    pageData: {
      headline: "Bredbandsforum ska främja infrastruktur för mobilt bredband",
      createDate: "1 juli 2019",
      textBody: [
        <div>
          <p>
            En ny{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              arbetsgrupp inom Bredbandsforum
            </a>{" "}
            ska främja utbyggnaden av infrastruktur för mobilt bredband. Ett
            viktigt fokus för gruppen är att analysera hur de mobila
            bredbandsnäten behöver se ut år 2023 om vi ska kunna uppnå
            regeringens bredbandsmål, och föreslå åtgärder för att främja
            måluppfyllnad. Arbetsgruppen kommer att ledas av Post- och
            telestyrelsens generaldirektör Dan Sjöblom, och ska slutrapportera
            sitt arbete den 31 maj 2020.
          </p>
          <p>
            <a
              data-udi="umb://document/d58cd2cbb7ea49678db520079b9a1a85"
              href="/sveriges-bredbandsstrategi/"
              title="Sveriges bredbandsstrategi"
            >
              Regeringens bredbandsstrategi
            </a>{" "}
            innehåller ett delmål om mobilt bredband; ”
            <em>
              år 2023 bör hela Sverige ha tillgång till mobila tjänster av god
              kvalitet”.{" "}
            </em>
            Detta betyder att användare bör ha möjlighet att använda mobila
            tjänster utanför hemmet och arbetet, med en uppkoppling som är så
            stabil och av sådan kvalitet att man inte upplever begränsningar.
            Användarens applikation eller tjänst ska fungera som förväntat, och
            detta gäller även uppkopplade saker.
          </p>
          <p>
            Vilka mobila tjänster som efterfrågas utvecklas över tid.
            Konsumenter och företag ställer högre krav på stabilitet och
            tillgänglighet i mobilnäten och användningsmönster förändras.
            Bredbandsforums arbetsgrupp{" "}
            <em>Infrastruktur för mobilt bredband 2023 </em>har därför fått i
            uppdrag att dels identifiera utmaningar och föreslå lösningar
            kopplat till den pågående utbyggnaden av infrastruktur för mobilt
            bredband, samt analysera vilka krav människor, företag, och
            uppkopplade saker i framtiden kommer att ställa på de mobila
            bredbandsnäten.
          </p>
          <p>
            Med utgångspunkt i utmaningar som berör den pågående utbyggnaden ska
            arbetsgruppen:
          </p>
          <ul>
            <li>
              Beskriva tillämpliga eller möjliga modeller för offentliga och
              privata aktörer att samverka kring utbyggnaden av infrastruktur
              för mobilt bredband i hela landet.
            </li>
            <li>
              Beskriva hur teknikutvecklingen av de mobila bredbandsnäten kommer
              att påverka täckning och kapacitet i områden som inte har
              tillräcklig täckning och kapacitet i dagsläget.
            </li>
            <li>
              Undersöka om arbetsgruppen eller Bredbandsforum kan spela en roll
              i genomförandet av relevanta förslag i PTS rapport PTS-ER-2018:13
            </li>
          </ul>
          <p>
            Med utgångspunkt i utmaningar som rör den kommande utbyggnaden ska
            arbetsgruppen:
          </p>
          <ul>
            <li>
              Analysera hur infrastrukturen för mobilt bredband kommer att
              motsvara framtidens behov och krav, och föreslå åtgärder eller
              vidare arbete i syfte att uppfylla regeringens bredbandsmål år
              2023.
            </li>
            <li>
              Beskriva förutsättningarna för förtätning av sändarplatser och vid
              behov föreslå hur förutsättningarna kan förbättras genom
              samverkan.
            </li>
            <li>
              Undersöka om kommande möjligheter till fast yttäckande radioaccess
              (FWA) kan fungera som en katalysator för utbyggnaden och
              utvecklingen av de mobila bredbandsnäten.
            </li>
          </ul>
          <p>
            Läs mer om uppdraget i{" "}
            <a
              data-udi="umb://media/e9d2c0679b934ec6bead2f72c20161c3"
              href="/media/1254/direktiv-infrastruktur-foer-mobilt-bredband-2023.pdf"
              title="Direktiv Infrastruktur för Mobilt Bredband 2023.pdf"
            >
              arbetsgruppens direktiv.
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "nya-stoedpengar-till-bredbandsutbyggnad",

    pageData: {
      headline: "Nya stödpengar till bredbandsutbyggnad",
      createDate: "6 september 2019",
      textBody: [
        <div>
          <p>
            Regeringen har nu presenterat sitt budgetförslag för nästa år.
            Budgeten innehåller bland annat en satsning på landsbygdspolitik,
            där det aviseras att Landsbygden ska få 150 miljoner mer till
            bredbandsutbyggnad år 2020. Satsningen innebär också att regeringen
            tillför det nya stödsystemet som Post- och telestyrelsen arbetar på
            att utforma 200 miljoner år 2021 och 300 miljoner 2022.
            <br />
            <br />
            "Satsningarna på landsbygden som Centerpartiet får igenom i budgeten
            kommer att leda till bättre infrastruktur, digitalisering, starkare
            konkurrenskraft inom jord- och skogsbruk men också förstärkt
            mediebevakning på landsbygden", säger Annie Lööf, partiledare för
            Centern, i en kommentar.
          </p>
          <p>
            Läs mer om satsningen på regeringens webbplats:{" "}
            <a href="https://www.regeringen.se/pressmeddelanden/2019/09/stor-satsning-pa-landsbygdspolitik-i-budget-2020/">
              https://www.regeringen.se/pressmeddelanden/2019/09/stor-satsning-pa-landsbygdspolitik-i-budget-2020/
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "byanaetsforum-anordnar-nationell-konferens",

    pageData: {
      headline: "Byanätsforum anordnar nationell konferens",
      createDate: "30 september 2019",
      textBody: [
        <div>
          <p className="ingress">
            Byanätsforum arrangerar den 10 oktober 2019 en nationell konferens
            och mötesplats där alla bredbandsföreningar i landet bjuds in att
            delta i regionala samlingar som alla knyts samman via videolänk.
          </p>
          <p>
            Utöver representanter från bredbandsföreningarna kommer även andra
            intresserade att bjudas in såsom tjänstemän och politiker.
            Konferensen sker i samarbete mellan landets regionala
            bredbandskoordinatorer samt riksorganisationen Hela Sverige ska
            leva, Coompanion och LRF.
          </p>
          <p>
            Byanätsforums kansli kommer att ansvara för en nationell del med
            flera intressanta talare som kommer att sändas ut via videolänk till
            samtliga regionala mötesplatser. I anslutning till den nationella
            direktsändningen kommer det i de flesta fallen att arrangeras lokala
            samtal om bredbandsutbyggnaden. Deltagarna kommer även ha möjlighet
            att ställa frågor och vara aktiva via det digitala verktyget
            mentimeter.
          </p>
          <p>
            Läs mer om konferensen och anmäl dig på Byanätsforums webbplats:{" "}
            <a href="https://www.byanatsforum.se/konferens-2019/">
              https://www.byanatsforum.se/konferens-2019/
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route:
      "22-miljarder-kraevs-foer-att-naa-bredbandsmaal-till-2025-enligt-post-och-telestyrelsen",

    pageData: {
      headline:
        "22 miljarder krävs för att nå bredbandsmål till 2025 enligt Post- och telestyrelsen",
      createDate: "8 oktober 2019",
      textBody: [
        <div>
          <p className="ingress">
            22 miljarder utöver de kommersiella aktörernas investeringar krävs
            för att hela Sverige ska ha tillgång till snabbt bredband år 2025 i
            enlighet med regeringens bredbandsmål. Det visar PTS uppföljning av
            regeringens bredbandsstrategi.
          </p>
          <hr />
          <p>
            PTS bedömer att det behövs ytterligare investeringar, utöver
            förväntade kommersiella investeringar, på minst 22 miljarder kronor
            för att nå regeringens bredbandsmål för 2025. Myndigheten har inte
            beräknat hur mycket det skulle kosta att nå målet med hjälp av andra
            tekniker än med fiber.
          </p>
          <p>
            - Det krävs nu en fortsatt kraftsamling från alla aktörer engagerade
            i bredbandsutbyggnaden, säger PTS generaldirektör Dan Sjöblom.
            Engagemanget från kommersiella aktörer behöver även framöver mötas
            upp av statliga insatser genom bredbandsstöd för att vi ska nå fram.
            Dessutom behöver vi titta närmare på i vilken utsträckning trådlös
            teknik i framtiden kan bidra till att så många som möjligt kan få
            tillgång till 100 Mbit/s under de närmaste åren.
          </p>
          <h3>
            <strong>Tre delmål för 2025</strong>
          </h3>
          <p>
            Regeringens bredbandsmål för 2025 har tre delmål som PTS följt upp i
            sin rapport. För samtliga delmål gäller att hushållet eller
            företaget antingen har en anslutning ända fram till byggnaden eller
            har bredbandsinfrastruktur i sin absoluta närhet, till exempel vid
            tomtgräns.&nbsp;
          </p>
          <p>
            Det första delmålet är att 98 procent av alla hushåll och företag
            bör ha tillgång till 1 Gbit/s. Enligt PTS prognoser kommer 97–98
            procent att ha sådan tillgång år 2025.&nbsp;
          </p>
          <p>
            Det andra delmålet innebär att ytterligare 1,9 procent (dvs 99,9
            procent) bör ha tillgång till minst 100 Mbit/s. PTS bedömer att
            97,4–98,4 procent kommer att ha sådan tillgång 2025.
          </p>
          <p>
            Det tredje delmålet är att samtliga hushåll och företag bör ha
            tillgång till minst 30 Mbit/s, vilket PTS bedömer kommer att uppnås
            till 2025.
          </p>
          <p>
            Läs mer på PTS webbplats:{" "}
            <a href="https://pts.se/sv/nyheter/internet/2019/22-miljarder-kravs-for-att-na-bredbandsmal-till-2025/">
              https://pts.se/sv/nyheter/internet/2019/22-miljarder-kravs-for-att-na-bredbandsmal-till-2025/
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "nya-uppdrag-till-bredbandsforum",

    pageData: {
      headline: "Nya uppdrag till Bredbandsforum",
      createDate: "4 november 2019",
      textBody: [
        <div>
          <hr />
          <p className="ingress">
            På Bredbandsforums styrgruppsmöte den 15 oktober prioriterade
            styrgruppen ett antal aktiviteter som ska genomföras under det
            kommande året. Bredbandsforums kansli ska bland annat gräva djupare
            i frågor om efteranslutningar till fibernätet samt frågor om drift
            och felavhjälpning i fastighetsnät.
          </p>
          <p>
            <a
              data-udi="umb://document/0ed20c41008c46cb8b437dca2ad783fa"
              href="/om-bredbandsforum/bredbandsforums-styrgrupp/"
              title="Bredbandsforums styrgrupp"
            >
              Bredbandsforums styrgrupp
            </a>{" "}
            har en viktig roll. Det är styrgruppens uppgift att säkerställa att
            de förslag, rekommendationerna och aktiviteter som föreslås av
            Bredbandsforums arbetsgrupper följs upp, prioriteras och genomförs.
            I oktober varje år görs därför en genomgång av lagda förslag, behov
            av ytterligare insatser och beslut om vilka aktiviteter som bör
            prioriteras. På styrgruppsmötet den 15 oktober 2019 beslutade
            styrgruppen att ge{" "}
            <a
              data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
              title="Bredbandsforums kansli"
            >
              Bredbandsforums kansli
            </a>{" "}
            i uppdrag att genomföra följande aktiviteter:
          </p>
          <ul>
            <li>
              Ta fram inriktningsförslag för kommande arbetsgrupper inom
              Bredbandsforum. Förslagen diskuteras på Bredbandsforums nästa
              styrgruppsmöte i mars 2020.
            </li>
            <li>
              Förbereda en diskussionsövning om att förändra eller bredda
              Bredbandsforums fokus. Övningen genomförs på Bredbandsforums nästa
              styrgruppsmöte i mars 2020.
            </li>
            <li>
              Formera en dialoggrupp för att diskutera roller och ansvar för
              drift och felavhjälpning i fastighetsnät vid leverans av
              samhällstjänster. Förslaget kommer från Bredbandsforums
              arbetsgrupp{" "}
              <a
                data-udi="umb://document/5cce2ff4405b49c39c4a21b8c0b3b363"
                href="/nyheter/infrastruktur-for-digitalisering/"
                title="Helhetsgrepp om robusthet och tillförlitlighet en förutsättning för samhällets digitalisering"
              >
                Infrastruktur för digitalisering
              </a>
              .
            </li>
            <li>
              Genomföra en förstudie i hur efteranslutningar hanteras i
              kommunala och regionala bredbandsstrategier, och i samband med
              detta intervjua ett antal kommunala och regionala
              bredbandskoordinatorer eller bredbandssamordnare, samt undersöka
              hur data och statistik om efteranslutningar kan förbättras eller
              förtydligas. Dessa förslag kommer från Bredbandsforums{" "}
              <a
                data-udi="umb://document/14cf4930dc17419cbf4dd02c844b58e0"
                href="/nyheter/efteranslutningar/"
                title="Utmaningar med att efteransluta fastigheter, hushåll och företag till fibernät"
              >
                dialoggrupp om efteranslutningar
              </a>
              .
            </li>
          </ul>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "noejdare-foeretag-i-kommuner-med-snabbt-bredband",

    pageData: {
      headline: "Nöjdare företag i kommuner med snabbt bredband",
      createDate: "4 december 2019",
      textBody: [
        <div>
          <p className="ingress">
            Uppdatering av Bredbandsforums årliga jämförelse av företagsklimat
            och bredbandstillgång
          </p>
          <p>
            Vid en jämförelse av Svenskt Näringslivs ranking av företagsklimatet
            med Post-och telestyrelsens kartläggning av företagens tillgång till
            snabbt bredband fann Bredbandsforum ett tydligt samband under åren
            2014, 2015, 2016, 2017 och 2018. I de kommuner där företagen totalt
            sett är mest nöjda med sina förutsättningar, har fler företag också
            tillgång till snabbt bredband.
          </p>
          <p>
            I en ny jämförelse med siffror från 2019 finner vi samma samband.
          </p>
          <p>
            <img
              id="__mcenew"
              src="/media/1273/total-2019.png"
              alt="Nöjdare företag i kommuner med snabbt bredband"
              data-udi="umb://media/0701edb99a104a31bed7f0eb637a24d8"
            />
          </p>
          <p>
            <em>
              Tillgång till bredband med 100 Mbit/s relaterat till totalranking.{" "}
            </em>
          </p>
          <p>
            <em>
              Källa: PTS-ER-2019:05 och{" "}
              <a href="http://www.foretagsklimat.se">www.foretagsklimat.se</a>{" "}
              med bearbetning av Bredbandsforums kansli.
            </em>
          </p>
          <p>
            Bland de 20 högst rankade kommunerna 2019 har i genomsnitt 77
            procent av företagen tillgång till bredband om minst 100 Mbit/s,
            jämfört med 58 procent i de 20 lägst rankade kommunerna. Om vi
            utökar spannet ser vi att i genomsnitt 72 procent av företagen har
            tillgång till snabbt bredband i de 50 högsta rankade kommunerna,
            jämfört med 59 procent i de 50 lägst rankade. I Svenskt Näringslivs
            enkätundersökning har företagen även specifikt svarat på hur nöjda
            de är med it-och telenätets utbyggnad och kvalitet i sina respektive
            kommuner. När vi jämför företagens svar med bredbandskartläggningen
            ser vi ett tydligt samband mellan tillgången till högre hastigheter
            och hur nöjd företagarna är med it-och telenätet i kommunen.
          </p>
          <p>
            <img
              id="__mcenew"
              src="/media/1272/ittele2019.png"
              alt="Nöjdare företag i kommuner med snabbt bredband"
              data-udi="umb://media/ebe9b8c451e14ec79bd3921e81062f95"
            />
          </p>
          <p>
            <em>
              Tillgång till 100 Mbit/s-bredband relaterat till ranking av it-och
              telenät.{" "}
            </em>
          </p>
          <p>
            <em>
              Källa: PTS-ER-2019:05 och{" "}
              <a href="http://www.foretagsklimat.se">www.foretagsklimat.se</a>{" "}
              med bearbetning av Bredbandsforums kansli.{" "}
            </em>
          </p>
          <p>
            Bland de 20 högst rankade kommunerna avseende it-och telenätet har i
            genomsnitt 79 procent av företagen tillgång till bredband med en
            överföringshastighet om 100 Mbit/s jämfört med i genomsnitt 42
            procent bland de 20 lägst rankade kommunerna. Tillgång till ett
            snabbt och framtidssäkert bredband är viktigt för både stora och små
            företag, och möjligheten att få en snabb bredbandsuppkoppling kan
            påverka uppfattningen om företagsklimatet i en kommun. I dagsläget
            har 76 procent av Sveriges företag tillgång till bredband med en
            överföringshastighet om 100 Mbit/s enligt PTS senaste
            bredbandskartläggning.
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "sexton-bredbandslyft-senare",

    pageData: {
      headline: "Sexton Bredbandslyft senare",
      createDate: "20 december 2019",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums initiativ Bredbandslyftet är en vägledning för
            kommuner som vill se över strategiska vägval i bredbandsfrågor.
            Sedan Bredbandsforums kommungrupp skapade konceptet har nu
            Bredbandsforums kansli genomfört Bredbandslyftet tillsammans med
            sexton kommuner runtom i Sverige. Här berättar Christa Ahlenblom –
            projektledare på Bredbandsforums kansli – om vilka erfarenheter vi
            kan dra av de hittills genomförda Bredbandslyften.
          </p>
          <p>
            Bredbandsforums arbetsgrupp <em>Kommungruppen</em> skapade en
            vägledning för kommuner som vill se över strategiska vägval i
            bredbandsfrågor. Vägledningen sker inte i form av till exempel en
            broschyr med allmänna råd och rekommendationer. Istället utvecklade
            arbetsgruppen ett program, Bredbandslyftet, som genom dialog och
            diskussion kan vägleda kommuner i de utmaningar och vägval man står
            inför i bredbandsfrågan. Läs mer om bredbandslyftet{" "}
            <a href="http://www.bredbandsforum.se/bredbandslyftet">här</a>.
          </p>
          <p>
            Bredbandsforums kansli har nu genomfört Bredbandslyftet tillsammans
            med kommunerna Mullsjö, Gnesta, Norrtälje, Norberg, Pajala,
            Kramfors, Ånge, Skövde, Hultsfred, Tierp, Nykvarn, Nynäshamn,
            Vansbro, Säter, Lysekil och Strömsund.
          </p>
          <p>
            Christa Ahlenblom är projektledare på Bredbandsforums kansli och
            ansvarar för Bredbandslyftet.
          </p>
          <p>
            <em>
              <strong>
                Hej Christa! Varför är det viktigt att Bredbandsforums kansli
                besöker kommunerna fysiskt?
              </strong>
              <br />
            </em>
          </p>
          <p>
            – Genom att mötas fysiskt och åka ut till kommunen kan vi från
            Bredbandsforums kansli få en mycket större förståelse för kommunens
            utmaningar. Vi får mer energi och dynamik i diskussionerna och en
            helt annan kontakt med varandra. Erfarenheten säger oss också att
            det blir en bättre och bredare uppslutning till fysiska möten, och
            en av huvudpoängen med Bredbandslyftet är just att hela kommunen är
            representerad, på politisk- och tjänstemannanivå. Det leder till att
            så många som möjligt får en ökad kunskap i frågorna, och att fler
            känner ett ansvar för bredbandsutbyggnaden i kommunen. Det bör
            hanteras som en gemensam fråga, det är viktigt att inte någon sitter
            ensam och brottas med utmaningar som kräver strategiska beslut på
            politisk nivå.
          </p>
          <p>
            <strong>
              <em>
                Vilka är förutsättningarna för ett riktigt bra möte och
                efterarbete?
              </em>
            </strong>
          </p>
          <p>
            – Högsta politiska ledningen behöver vara på plats, liksom de som
            har ett mandat att ta ekonomiska beslut. Då kan man få till en
            strategisk diskussion som man vet kan leda vidare till konkreta
            åtgärder. Den specifika modellen för hur diskussionen ska ske vilar
            på att den kan resultera i möjliga handlingsalternativ för det
            fortsatta arbetet samt eventuellt beslut om nya vägval.
          </p>
          <p>
            – På tal om åtgärder så är en framgångsfaktor att vi just formulerar
            några konkreta fokusområden för kommunen att följa upp fram till
            uppföljningsmötet. Då sparkas arbetet igång medan alla har
            diskussionerna i färskt minne. Under Bredbandslyftet skapas dessutom
            en grupp som tillsammans med kommunens bredbandsansvarige följer upp
            rekommendationerna med stöd av den regionala
            bredbandssamordnaren.&nbsp;
          </p>
          <p>
            – Kartläggningsanalysen som Bredbandslyftet bistår kommunerna med är
            också en mycket viktig pusselbit för att kunna komma vidare.
          </p>
          <p>
            <strong>
              <em>
                Finns det något gemensamt hos de kommuner vi hittills besökt med
                Bredbandslyftet?
              </em>
            </strong>
          </p>
          <p>
            – Alla har höga ambitioner på området, även fast de kommit olika
            långt. Vi har varit hos kommuner som har en väldigt hög
            fiberpenetration och nu behöver lösa den sista biten på landsbygden
            medan andra kämpar med att prioritera områden eller ta ett
            strategiskt nytag. Gemensamt är att alla siktar högt.
          </p>
          <p>
            – Landsbygdsutmaningen återkommer också. Däremot behöver lösningarna
            som man kan tänka sig inte vara desamma i alla kommuner vi besökt. I
            vissa kommuner finns det många fiberföreningar, i andra inte en
            enda. Vissa har ett stadsnät som kan fortsätta jobba för att bygga
            ut bredband på landsbygden, andra samarbetar med nationella
            operatörer.
          </p>
          <p>
            – Bredbandslyftet går också till så att de regionala
            bredbandskoordinatorerna deltar på mötet och har en viktig roll i
            att stötta kommunen och bredbandssamordnaren på kommunen både innan,
            under och efter själva Bredbandslyftet. Som tur är har ju nu hela
            landet gemensamt att vi har regionala bredbandskoordinatorer som gör
            ett fantastiskt jobb.
          </p>
          <p>
            <strong>
              <em>
                Så vad har Bredbandslyftet resulterat i för de sexton kommuner
                vi hittills besökt?
              </em>
            </strong>
          </p>
          <p>
            – Vissa har fått kontakt med nya marknadsaktörer eller återupptagen
            kontakt med marknadsaktörer man tidigare samarbetat med men där
            samarbetet inte har fungerat. Vissa har också sett över sina egna
            processer och planer och på så sätt förhoppningsvis skapat större
            incitament för samverkan inom kommunen. I alla Bredbandslyft brukar
            vi understryka vikten av tydlig information till kommuninvånarna om
            vilka planer och alternativ som finns, och att kommunen kan använda
            den stora trovärdighet man har hos medborgarna för att informera om
            bredband på ett sätt som kan driva på utbyggnaden.
          </p>
          <p>
            – Bredbandslyftet innebär ett nära samarbete med den regionala
            bredbandskoordinatorn, som därigenom får en bättre förståelse för
            kommunens utmaningar och som kan bistå med stöd och vägledning samt
            kontakter.
          </p>
          <p>
            – Den återkoppling vi får kan sammanfattas i att de som deltar i
            Bredbandslyftet får en konkret draghjälp att komma igång, och
            resultatet är att det blir en förnyad aktivitet i frågan - inte
            minst genom att kommunledningen inser hur det ligger till och kan ta
            ansvar för hur man ska kunna lösa de utmaningar som kvarstår med att
            uppnå bredbandsmålen.
          </p>
          <p>
            Representerar du en kommun och är intresserad av Bredbandslyftet kan
            du kontakta din{" "}
            <a href="/bredbandskoordinatorer/">
              regionala bredbandskoordinator
            </a>
            . De regionala bredbandskoordinatorerna samordnar genomförandet av
            Bredbandslyftet i sin respektive region.
          </p>
        </div>,
      ],
      asideTitle: "Mer information",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://document/f18c5ad8ff714c6088f24929f15aff14"
              href="/bredbandslyftet/"
              title="Bredbandslyftet"
            >
              Bredbandslyftet
            </a>
          </p>
          <p>
            <a
              data-udi="umb://document/ac9b855af4a24bb788ff63a7501d274c"
              href="/bredbandskoordinatorer/"
              title="Bredbandskoordinatorer"
            >
              Regionala Bredbandskoordinatorer
            </a>
          </p>
          <p>
            <a
              data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
              title="Bredbandsforums kansli"
            >
              Bredbandsforums kansli
            </a>
          </p>
          <p>
            <a
              data-udi="umb://document/41c4cb777ea642549ca051fd42d036ad"
              href="/avslutade-arbetsgrupper/"
              title="Avslutade arbetsgrupper"
            >
              Bredbandsforums arbetsgrupper
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "pts-foerslag-till-nytt-bredbandsstoed",

    pageData: {
      headline: "PTS förslag till nytt bredbandsstöd",
      createDate: "30 januari 2020",
      textBody: [
        <div>
          <p>
            Post- och telestyrelsen fick förra året i uppdrag av regeringen att
            föreslå hur ett framtida bredbandsstöd skulle kunna utformas på ett
            effektivt sätt. Nu har myndigheten{" "}
            <a href="https://www.regeringen.se/pressmeddelanden/2020/01/anders-ygeman-tar-emot-forslag-om-ny-modell-for-bredbandsstod/">
              redovisat sitt uppdrag
            </a>{" "}
            till regeringen.
          </p>
          <p>
            Förslaget innebär att PTS beviljar stöd till utbyggnad som möjliggör
            minst 1 Gbit/s, i linje med regeringens bredbandsmål för 2025.
            Stödet ska riktas till områden som saknar snabbt bredband och där
            utbyggnad på kommersiell väg inte förväntas ske.
          </p>
          <p>
            PTS föreslår att stödet omfattar utbyggnad till avlämningspunkter i
            absoluta närheten av hushållen och företagen. Nätägaren ska sedan
            vara skyldig att erbjuda anslutning av hushåll eller företag till en
            skälig anslutningsavgift. Syftet är att det offentliga ska bidra
            till att möjliggöra anslutning, samtidigt som utbyggnadsprojekten
            inte ska stå och falla med enskilda individers efterfrågan.
          </p>
          <p>
            Enligt förslaget utlyser PTS stöd per region. Såväl privata som
            offentliga aktörer kan därefter ansöka om stöd till
            utbyggnadsprojekt som de själva utformar. PTS rangordnar projekten
            och beslutar om stöd till de projekt som ger mest utbyggnad av
            bredband till hushåll och företag för pengarna. Flera projekt per
            region och aktör kan tilldelas stöd. Regionerna föreslås få en
            central roll i modellen genom att peka ut vilka platser som är mest
            prioriterade att bygga till med stöd.
          </p>
          <p>
            Enligt förslaget kommer PTS ansvara för löpande uppföljning och
            kontroll av att villkoren i stödbeslutet efterlevs samt kräva
            tillbaka medel om så inte sker.
          </p>

          <a href="https://pts.se/globalassets/startpage/dokument/icke-legala-dokument/rapporter/2020/internet/pts-forslag-till-investeringsstod-for-bredband-pts-er-20-02.pdf">
            Klicka här för att ladda ned rapporten från pts.se.
          </a>
          <br />
          <hr />
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "saa-kan-efteranslutningar-till-fibernaetet-fraemjas",

    pageData: {
      headline:
        "Så kan efteranslutningar till fibernätet främjas – ny rapport från Bredbandsforums kansli",
      createDate: "11 mars 2020",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums kansli lämnar i en{" "}
            <a
              data-udi="umb://media/c2fc761fb61548159a1dd291ebf89a26"
              href="/media/1283/saa-kan-efteranslutningar-av-hushaall-foeretag-och-fastigheter-till-fibernaetet-fraemjas.pdf"
              title="Så kan efteranslutningar av hushåll, företag och fastigheter till fibernätet främjas.pdf"
            >
              ny rapport
            </a>{" "}
            rekommendationer på hur efteranslutningar av hushåll, företag och
            fastigheter till fibernätet kan främjas. Undersökningen är en
            fortsättning på arbetet i Bredbandsforums{" "}
            <a
              data-udi="umb://document/14cf4930dc17419cbf4dd02c844b58e0"
              href="/nyheter/efteranslutningar/"
              title="Utmaningar med att efteransluta fastigheter, hushåll och företag till fibernät"
            >
              dialoggrupp om efteranslutningar
            </a>
            , och Bredbandsforums rapport{" "}
            <a
              data-udi="umb://media/9e4c1a8d52514db49a67e007189789f4"
              href="/media/1238/utmaningar-med-efteranslutningar-bredbandsforums-kansli-februari-2019.pdf"
              title="Utmaningar med efteranslutningar - Bredbandsforums kansli februari 2019.pdf"
            >
              ”
              <em>
                Utmaningar med att efteransluta fastigheter, hushåll och företag
                till fibernät
              </em>
              ”
            </a>
            .
          </p>
          <p>
            Bredbandsforums kansli har undersökt hur frågor om efteranslutningar
            till fibernätet hanteras strategiskt av olika aktörer.
            Undersökningen visar att många nätägare arbetar aktivt med att
            efteransluta hushåll och företag, men att frågor om
            efteranslutningar ännu inte behandlas i många andra viktiga aktörers
            främjande- eller samordningsarbete.
          </p>
          <p>
            Bakgrunden till Bredbandforums arbete på detta område är att ett
            antal hushåll och företag väljer att inte ansluta sig i varje
            fiberutbyggnadsprojekt. I efterhand så är en anslutning till det
            befintliga nätet i de flesta fall den enda praktiska möjligheten för
            dessa hushåll och företag att få tillgång till bredband genom
            fibernätet. Frågor om dessa så kallade efteranslutningar kommer att
            växa i betydelse, i och med att fler kommuner och regioner börjar
            närma sig de utbyggnadsmål som antagits i strategier och
            handlingsplaner.&nbsp;Bredbandsforums kansli lämnar i den nya
            rapporten ett antal rekommendationer på hur olika aktörer kan börja
            arbeta mer aktivt med frågor om efteranslutningar. Bakgrunden till
            rekommendationerna förklaras närmare i dokumentet, men i korthet
            föreslår Bredbandsforums kansli följande:
          </p>
          <ul>
            <li>
              Vid uppdatering av en kommunal eller regional bredbandsstrategi
              bör efteranslutningsfrågor behandlas, och kommunala och regionala
              bredbandsstrategier bör innehålla en konkret målsättning för
              efteranslutningar.
            </li>
            <li>
              PTS bör i kommande kartläggningsarbete samla in data som möjliggör
              för myndigheten att särskilja hushåll och företag där
              förutsättningar finns för en efteranslutning till fibernätet, från
              hushåll och företag där förutsättningar saknas.
            </li>
            <li>
              PTS kan med hjälp av befintlig data öka kunskapen om
              efteranslutningar på en nationellt aggregerad nivå, och med hjälp
              av befintlig data ta fram underlag som underlättar för en
              strategisk hantering av efteranslutningsfrågor.
            </li>
            <li>
              De regionala bredbandskoordinatorerna bör utforma en metod för
              kartläggning av efteranslutningsadresser på regional och kommunal
              nivå, ta fram exempel på hur efteranslutningsfrågor kan hanteras
              strategiskt, och genomföra en regional pilotstudie.
            </li>
            <li>
              Nationella aktörer bör bättre ta tillvara efteranslutningsfrågor i
              det arbete man redan bedriver för att främja
              bredbandsutbyggnad.&nbsp;
            </li>
            <li>
              Fiberföreningarnas hantering av efteranslutningar bör kartläggas.
            </li>
            <li>
              Nätägare bör proaktivt kommunicera med kommunala och regionala
              samordningsfunktioner om planerade eftermarknadskampanjer.
            </li>
          </ul>
          <p>
            <a
              data-udi="umb://media/c2fc761fb61548159a1dd291ebf89a26"
              href="/media/1283/saa-kan-efteranslutningar-av-hushaall-foeretag-och-fastigheter-till-fibernaetet-fraemjas.pdf"
              title="Så kan efteranslutningar av hushåll, företag och fastigheter till fibernätet främjas.pdf"
            >
              Klicka här för att ladda ned rapporten (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ner rapporten",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/c2fc761fb61548159a1dd291ebf89a26"
              href="/media/1283/saa-kan-efteranslutningar-av-hushaall-foeretag-och-fastigheter-till-fibernaetet-fraemjas.pdf"
              title="Så kan efteranslutningar av hushåll, företag och fastigheter till fibernätet främjas.pdf"
            >
              <strong>
                Så kan efteranslutningar av hushåll, företag och fastigheter
                till fibernätet främjas (PDF)
              </strong>
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "fortsatt-fokus-paa-bredbandsutbyggnad-i-sveriges-regioner",

    pageData: {
      headline: "Fortsatt fokus på bredbandsutbyggnad i Sveriges regioner",
      createDate: "18 mars 2020",
      textBody: [
        <div>
          <p>
            Ett aktivt arbete med samordning av bredbandsutbyggnaden på
            nationell, regional som kommunal nivå är viktigt om målen i
            regeringens bredbandsstrategi ska kunna uppnås. Därför beslutade
            regeringen att införa regionala bredbandskoordinatorer i alla län
            under perioden 2015–2020, och det nationella sekretariatet på
            Bredbandsforums kansli följer årligen upp de regionala
            bredbandskoordinatorernas verksamhet.
          </p>
          <p>
            Den senaste uppföljningsrapporten visar att samverkan, kartläggning
            och stöd till kommuner var de områden som koordinatorerna lade mest
            tid på 2019. Jämfört med 2018 var det tiden för samverkan mellan
            regioner som ökade mest, detta då flera bredbandskoordinatorer
            börjat arbeta mer regionsöverskridande med olika strategiska frågor.
            I majoriteten av regionerna fungerar samverkan mellan kommunerna
            väl, och består vanligtvis av informations- och erfarenhetsutbyte.
          </p>
          <p>
            Kartläggning av bredbandssituationen i länet har varit ett
            prioriterat område under året och är ett viktigt underlag för
            planering, uppföljning och inriktning i det regionala
            bredbandsarbetet. Detta fokus på kartläggning beror delvis på att de
            regionala bredbandskoordinatorerna har en viktig roll att spela i
            PTS process för det{" "}
            <a href="https://www.pts.se/bredbandsstod">
              nya nationella bredbandsstödet
            </a>
            .
          </p>
          <p>
            Stöd i kartläggningsarbetet och kunskaps- eller
            informationsaktiviteter är viktiga delar i koordinatorernas stöd
            till kommunerna. Exempel på andra stödjande aktiviteter är regional
            upphandling av bredbandsutbyggnad, samordning av projekt för
            ortsammanbindande nät samt genomförande av Bredbandslyftet,
            Bredbandsforums program för vägledning till kommuner.
          </p>
          <p>
            Klicka här för att hämta rapporten{" "}
            <a
              data-udi="umb://media/ba2330fe384240e59997200c104b16fa"
              href="/media/1284/2019-uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2019-dnr-20-160.pdf"
              title="2019 - Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2019 - dnr 20-160.pdf"
            >
              <em>
                Uppföljning av de regionala bredbandskoordinatorernas verksamhet
                2019
              </em>
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned rapporten",
      asideBody: [
        <p>
          <a
            data-udi="umb://media/ba2330fe384240e59997200c104b16fa"
            href="/media/1284/2019-uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2019-dnr-20-160.pdf"
            title="2019 - Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2019 - dnr 20-160.pdf"
          >
            <em>
              <strong>
                Uppföljning av de regionala bredbandskoordinatorernas verksamhet
                2019 (PDF)
              </strong>
            </em>
          </a>
        </p>,
      ],
    },
  },
  {
    route: "infrastruktur2023",

    pageData: {
      headline:
        "Så skapar vi digital mobilitet i hela Sverige - Slutrapport från Infrastruktur för mobilt bredband 2023",
      createDate: "9 juni 2020",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums arbetsgrupp{" "}
            <a href="/paagaaende-arbetsgrupper/">
              <em>
                <u>Infrastruktur för mobilt bredband 2023</u>
              </em>
            </a>{" "}
            har undersökt hur tillgången till mobilt bredband i Sverige kan
            förbättras. Tillgången till mobilt bredband är i dagsläget
            förhållandevis god, men behöver utvecklas för att möta konsumenters,
            företags, och offentliga verksamheters kommande behov och krav.
            Arbetsgruppen lämnar i sin slutrapport konkreta förslag på hur olika
            aktörer kan främja utbyggnaden av infrastruktur för mobilt bredband.
          </p>

          <p>
            Tillgången till mobilt bredband i Sverige behöver förbättras. Detta
            innebär inte att de mobila bredbandsnäten i dagsläget inte räcker
            till – tvärtom är Sverige ett föregångsland när det gäller digital
            mobilitet. Men utvecklingen går fort. Konsumenter, företag och
            offentliga verksamheter kräver allt mer avancerade tjänster även
            utanför hemmet och den fasta arbetsplatsen, och därmed ökar behoven
            av högre prestanda och en stabil uppkoppling oavsett var man
            befinner sig. Om behoven inte möts riskerar vi att gå miste om de
            stora vinster tillgång till digital mobilitet kan medföra.
          </p>
          <p>
            Bredbandsforums arbetsgrupp{" "}
            <a href="/paagaaende-arbetsgrupper/">
              <em>
                <u>Infrastruktur för mobilt bredband 2023</u>
              </em>
            </a>{" "}
            har undersökt hur utbyggnaden och utvecklingen av mobila
            bredbandsnät kan främjas. Arbetsgruppen konstaterar i slutrapporten
            från arbetet att det krävs förbättrad samordning mellan viktiga
            aktörer, utökad finansiering, vägledning till aktörer som ställs
            inför komplicerade frågeställningar, och flera andra
            främjandeinsatser. Då kan en fortsatt utbyggnad av infrastrukturen
            för mobilt bredband stimuleras.
          </p>
          <p>
            Arbetsgruppen beskriver i slutrapporten en ny samverkansprocess på
            lokal och regional nivå för utbyggnad av infrastruktur för mobilt
            bredband, och föreslår att den vidareutvecklas i ett pilotförsök.
            Arbetsgruppen lämnar även flera förslag på hur olika aktörer kan
            bidra i arbetet med att förbättra tillgången till mobilt bredband.
            Exempelvis föreslås regeringen formulera en vision för mobilitetens
            roll i samhällets digitalisering, och se över möjligheterna att
            inrätta ett statligt investeringsstöd för utbyggnad av infrastruktur
            för mobilt bredband på platser dit kommersiella krafter inte kan nå.
            Kommuner och regioner är mycket viktiga aktörer på
            bredbandsmarknaden, och arbetsgruppen föreslår bland annat att mål
            om mobilitet bör integreras i kommunala och regionala strategiska
            dokument. Nationella myndigheter kan även genomföra viktiga
            insatser, exempelvis uppmanas Post- och telestyrelsen undersöka
            möjligheterna att auktionera ut kvarvarande frekvensutrymme från
            2018 års tilldelning av 700 MHz-bandet, och se över möjligheterna
            att tilldela ytterligare frekvensband med anpassade täckningskrav.
          </p>
          <p>
            <a
              data-udi="umb://media/8e8a243c7e2941b39b6642fdbe6335eb"
              href="/media/1286/2020-infrastruktur-foer-mobilt-bredband-2023-slutrapport.pdf"
              title="2020 - Infrastruktur för mobilt bredband 2023 - Slutrapport.pdf"
            >
              Klicka här för att ladda ned arbetsgruppens slutrapport (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              Klicka här för att läsa mer om arbetsgruppen och ta del av övrigt
              material
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ner rapporten",
      asideBody: [
        <p>
          <a
            data-udi="umb://media/8e8a243c7e2941b39b6642fdbe6335eb"
            href="/media/1286/2020-infrastruktur-foer-mobilt-bredband-2023-slutrapport.pdf"
            title="2020 - Infrastruktur för mobilt bredband 2023 - Slutrapport.pdf"
          >
            Bredbandsforums arbetsgrupp Infrastruktur för mobilt bredband 2023 -
            Slutrapport
          </a>
        </p>,
      ],
    },
  },
  {
    route: "10-aar-med-bredbandsforum",

    pageData: {
      headline: "10 år med Bredbandsforum",
      createDate: "7 augusti 2020",
      textBody: [
        <div>
          <p>
            För lite drygt tio år sedan beslutade regeringen att det skulle
            inrättas ett Bredbandsforum – en mötesplats för dialog och samverkan
            mellan myndigheter, organisationer och företag som verkar på den
            svenska bredbandsmarknaden. Syftet med initiativet, enligt
            protokollet från regeringssammanträdet den 25 mars 2010, är att
            hitta konstruktiva lösningar som bidrar till en ökad samverkan om
            utbyggnad av infrastruktur. Ledamöterna i Bredbandsforums styrgrupp
            utnämndes av dåvarande IT-minister Åsa Torstensson, och styrgruppen
            visade redan från början att man förväntade sig konkreta resultat
            från det nya forumet. De första arbetsgrupperna tillsattes snabbt,
            med fokus på att helt enkelt identifiera problem och hinder för
            bredbandsutbyggnad – och förklara hur problemen och hindren skulle
            överkommas.
          </p>
          <p>
            10 år senare har Bredbandsforums styrgrupp sammanträtt 31 gånger och
            tillsätter fortfarande arbetsgrupper och prioriterar bland viktiga
            frågor. Fem olika it- eller digitaliseringsministrar har agerat
            ordförande och diskuterat bredbandsfrågor med aktörer som
            representerar olika perspektiv. Bredbandsforums arbetsgrupper har
            levererat 19 slutrapporter med hundratals förslag och
            rekommendationer.{" "}
          </p>
          <p>
            Så länge bredbandsmålen inte är uppfyllda och utbyggnaden pågår, så
            kan saker och ting alltid ske lite effektivare. Hur många processer
            som än förbättras, och hur mycket mer samverkan och samarbete man än
            skapar, så hittar man alltid en ny fråga att vrida och vända på.
            Bredbandsforums arbete på robusthetsområdet är ett bra exempel. De
            första arbetsgrupperna som fokuserade på hur infrastrukturen anlades
            utgjorde bland annat grunden för det som nu är konceptet Robust
            Fiber, medan de senare arbetsgrupperna på området har undersökt hur
            robust hela kedjan av aktörer och lösningar är vid leverans av
            välfärdstjänster. Bredbandsmarknaden rör dessutom på sig hela tiden,
            nya aktörer och tekniker för med sig nya frågetecken. Så länge
            Bredbandsutbyggnad pågår så kommer dessa frågeställningar behöva
            behandlas någonstans.
          </p>
          <p>
            En erfarenhet från arbetet är att diskussion kan leda till konkreta
            resultat, om det ges rätt förutsättningar. Att sitta och prata om
            ett problemområde utan att riktigt veta exakt var det kommer landa
            blir ibland komplicerat. Särskilt då det alltid finns olika viljor
            och idéer om hur verkligheten ser ut. Men om man hittar de
            nödvändiga kompromisserna, så kommer resultatet vara värt
            ansträngningen. En ingrediens för framgångsrik samverkan är att det
            måste få ta tid, Bredbandsforums arbetsgrupper får i de flesta fall
            pågå i nästan ett år. Även om ett samverkansprojekt inte skulle leda
            till en rapport eller förslagslista så är det självklart att den här
            typen av diskussioner alltid är värdefulla. Det leder till att olika
            aktörer eller personer förstår varandra bättre, och krattar manegen
            för framtida samarbeten. En framgångsfaktor för Bredbandsforum varit
            att det har funnits en projektorganisation med ett tydligt uppdrag
            att se till att diskussionerna alltid resulterar i konkreta
            produkter.
          </p>
          <p>
            På Bredbandsforums webbplats finns allt material som forumet arbetat
            fram genom åren tillgängligt. Här hittas material från alla
            Bredbandsforums{" "}
            <a
              data-udi="umb://document/c4deb14b8bd74601a6da2cbd5fec3dd1"
              href="/om-bredbandsforum/material-fraan-styrgruppen/"
              title="Material från styrgruppen"
            >
              styrgruppsmöten
            </a>
            , alla{" "}
            <a
              data-udi="umb://document/41c4cb777ea642549ca051fd42d036ad"
              href="/avslutade-arbetsgrupper/"
              title="Avslutade arbetsgrupper"
            >
              slutrapporter och produkter
            </a>{" "}
            från Bredbandsforums arbetsgrupper, samt annan{" "}
            <a
              data-udi="umb://document/8902f606cc684a9e845c547f57724fba"
              href="/nyheter/saa-kan-efteranslutningar-till-fibernaetet-fraemjas/"
              title="Så kan efteranslutningar till fibernätet främjas"
            >
              intressant information
            </a>
            .
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "snabbt-bredband-underlaettar-hemarbete",

    pageData: {
      headline: "Snabbt bredband underlättar hemarbete",
      createDate: "4 september 2020",
      textBody: [
        <div>
          <p>
            Sifo har på uppdrag av Ip-Only undersökt hur tillgången till eller
            avsaknaden av snabbt bredband påverkar möjligheterna till hemarbete
            och välmåendet i hushåll som hemarbetar. Undersökningen visar bland
            annat att svenskar förväntar sig att hemarbete kommer bli mycket
            vanligare i framtiden, och att en snabb uppkoppling underlättar
            många olika aspekter av att arbeta hemifrån. Exempelvis är det tre
            gånger så vanligt att uppleva störningar eller problem med
            hemarbetet i hushåll som saknar en fiberanslutning i jämförelse med
            hushåll som har tillgång till fiber.
          </p>
          <p>
            Läs mer om undersökningen på Ip-Onlys webbplats:{" "}
            <a href="https://www.ip-only.se/nyheter/undersokning-snabbt-bredband-paverkar-effektivitet-och-hur-vi-mar-nar-vi-jobbar-hemma-under-corona/">
              https://www.ip-only.se/nyheter/undersokning-snabbt-bredband-paverkar-effektivitet-och-hur-vi-mar-nar-vi-jobbar-hemma-under-corona/
            </a>{" "}
          </p>
          <p>
            Klicka här för att ladda ned rapporten:{" "}
            <a href="https://cms.ip-only.se/wp-content/uploads/2020/08/sifo-original-003.pdf">
              https://cms.ip-only.se/wp-content/uploads/2020/08/sifo-original-003.pdf
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "1-4-extra-miljarder-i-bredbandsstoed-2021",

    pageData: {
      headline: "1,4 extra miljarder i bredbandsstöd 2021",
      createDate: "23 september 2020",
      textBody: [
        <div>
          <p>
            Regeringen aviserar i budgetpropositionen för 2021 att det kommer
            satsas ytterligare 1,4 miljarder kronor år 2021, 500 miljoner kronor
            år 2022 och därefter 100 miljoner årligen under 2023-2025 på
            utbyggnaden av bredband i hela landet. Totalt kommer nu 2,85
            miljarder kronor att delas ut fram till och med 2025, jämfört med
            den tidigare siffran på 450 miljoner under samma tidsperiod.
          </p>
          <div>
            <p>
              Post- och telestyrelsen (PTS) är förvaltande myndighet för detta
              stöd, och kommer nu att förbereda för en utlysning av 1,4
              miljarder kronor under 2021. Den stödmodell myndigheten tagit fram
              kommer skalas upp efter dessa nya förutsättningar.
            </p>
            <p>
              Bredbandsstöd ges till bredbandsutbyggnad som garanterar
              hastigheter på 1 Gbit/s i glest befolkade områden. Både privata
              och offentliga nätbyggare har möjlighet att söka bredbandsstöd för
              utbyggnadsprojekt.
            </p>
            <p>
              Läs mer på regeringens webbplats:
              https://www.regeringen.se/pressmeddelanden/2020/09/kraftig-satsning-pa-befintliga-stod-till-bredbandsutbyggnad/
            </p>
          </div>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "pts-tilldelar-bredbandsstoed-till-44-projekt",

    pageData: {
      headline: "PTS tilldelar bredbandsstöd till 44 projekt",
      createDate: "12 oktober 2020",
      textBody: [
        <div>
          <p>
            Regeringen har beslutat att Post- och telestyrelsen (PTS) ska
            fördela stöd till bredbandsutbyggnad under de kommande åren. För
            2020 fick PTS 136 miljoner kronor att dela ut. Stödet riktas till
            glesbefolkade områden där PTS bedömer att det inte på kommersiell
            väg kommer att byggas snabbt bredband inom de närmsta tre åren.
          </p>
          <p>
            Nu har PTS fattat de första besluten om vilka projekt som ska
            tilldelas bredbandsstöd. Denna gång handlar det om sammanlagt 44
            projekt, som motsvarar en total summa om 106 miljoner kronor.
          </p>
          <p>
            <a href="https://pts.se/sv/bransch/internet/bredbandsutbyggnad/bredbandsstod/">
              Klicka här för att se vilka stödmottagare som beviljats stöd.
            </a>
          </p>
          <p>
            I november kommer PTS att fatta ytterligare beslut i årets
            tilldelning av bredbandsstöd. Det innebär att sökande, som ännu inte
            fått beslut, fortfarande kan vara aktuella för stöd. Det återstår
            fortfarande cirka 29 miljoner att tilldela.
          </p>
          <p>
            Inför nästa år har regeringen föreslagit ytterligare 1,4 miljarder
            kronor i bredbandsstöd. Sammanlagt kommer 2,85 miljarder kronor att
            delas ut fram till 2025.
          </p>
          <p>
            <a href="https://pts.se/sv/bransch/internet/bredbandsutbyggnad/bredbandsstod/">
              Klicka här för att läsa mer om PTS bredbandsstöd.
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "utmaningar-i-drift-och-underhaall-av-fastighetsnaet",

    pageData: {
      headline: "Utmaningar i drift och underhåll av fastighetsnät",
      createDate: "17 november 2020",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums kansli har genomfört en första kartläggning av hur
            drift och underhåll hanteras i svenska fastighetsnät. Undersökningen
            visar att det finns en djup kunskapsklyfta mellan inblandade
            aktörer, och att ett kunskapsmaterial för vägledning och
            kommunikation behövs för att främja möjligheterna till samarbete och
            samverkan.
          </p>
          <p>
            Bakgrunden till kartläggningen är att Bredbandsforums arbetsgrupp{" "}
            <a href="/media/1252/2019-infrastruktur-foer-digitalisering-slutrapport.pdf">
              Infrastruktur för digitalisering
            </a>{" "}
            tidigare har undersökt om Sveriges bredbandsnät är redo att bära
            dagens och morgondagens tjänster. Arbetet visade att det finns
            brister i den kedja av aktörer som behöver kopplas ihop och samspela
            för att leveransen av digitala samhällstjänster ska vara robust och
            tillförlitlig. Arbetsgruppen rekommenderade bland annat att roller
            och ansvarsförhållanden i frågor om fastighetsnätens drift och
            felavhjälpning bör undersökas. Ägare av fastighetsnät omfattas
            generellt sett inte av samma krav på driftsäkerhet som ägare av
            allmänna kommunikationsnät. Det råder därför en osäkerhet kring hur
            fastighetsnätens förvaltning hanteras. Syftet med kartläggningen är
            att övergripande beskriva hur drift och underhåll hanteras i svenska
            trådbundna fastighetsnät, kartlägga roller och ansvarsförhållanden,
            samt identifiera utmaningar respektive potentiella
            förbättringsförslag.
          </p>
          <p>
            En viktig slutsats är att fastighetsägare med mindre organisationer
            har små förutsättningar att bedöma behov, kravställa på rätt nivå,
            eller tolka avtal. Olika intressenter ser behov av att kunna
            kommunicera med fastighetsägare i syfte att exempelvis inplacera
            utrustning, hyra förbindelse, eller göra riskanalyser för leverans
            av samhällstjänster. Ett kunskapsmaterial behövs som kan fungera som
            vägledning för fastighetsägare, och som kan användas som gränssnitt
            för kommunikation. Nu kommer Bredbandsforums kansli arbeta vidare
            med att utveckla ett sådant kunskapsmaterial. Kontakta gärna
            kansliet på{" "}
            <a href="mailto:kontakt@bredbandsforum.se">
              kontakt@bredbandsforum.se
            </a>{" "}
            om ni har synpunkter på eller inspel i arbetet.
          </p>
          <p>
            <a
              data-udi="umb://media/bde21d29ff03407eabc0428518e8a133"
              href="/media/1302/utmaningar-i-drift-och-underhaall-av-fastighetsnaet.pdf"
              title="Utmaningar i drift och underhåll av fastighetsnät.pdf"
            >
              Klicka här för att ta del av kartläggningen{" "}
              <em>Utmaningar i drift och underhåll av fastighetsnät</em> (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Dokument",
      asideBody: [
        <p>
          <a
            data-udi="umb://media/bde21d29ff03407eabc0428518e8a133"
            href="/media/1302/utmaningar-i-drift-och-underhaall-av-fastighetsnaet.pdf"
            title="Utmaningar i drift och underhåll av fastighetsnät.pdf"
          >
            Utmaningar i drift och underhåll av fastighetsnät (PDF)
          </a>
        </p>,
      ],
    },
  },
  {
    route: "fortsatt-stoed-till-regionala-bredbandskoordinatorer",

    pageData: {
      headline:
        "Fortsatt arbete för Bredbandsforum och Bredbandskoordinatorerna till 2025",
      createDate: "20 november 2020",
      textBody: [
        <div>
          <p className="ingress">
            Regeringen har beslutat om fortsatt arbete för Bredbandsforum under
            perioden 2021–2025. Regeringen kommer även att erbjuda Sveriges
            regioner fortsatt stöd för att upprätthålla funktionen regional
            bredbandskoordinator under perioden 2021–2025. Post- och
            telestyrelsen (PTS) får som tidigare i uppdrag att stödja
            Bredbandsforum samt de regionala bredbandskoordinatorerna i arbetet
            med att främja tillgången till bredband.
          </p>
          <p>
            - Bredbandsforum har en viktig funktion i regeringens arbete med
            2025-målet, bredband i världsklass i hela landet, säger
            digitaliseringsminister Anders Ygeman.
          </p>
          <p>
            Bredbandsforum förbättrar förutsättningarna för
            bredbandsutbyggnaden, genom att underlätta dialogen mellan
            regeringen, myndigheter, organisationer och företag som verkar på
            den svenska bredbandsmarknaden. Forumet ska ge regeringen och
            medverkande aktörer idéer och förslag som främjar effektiv och säker
            digital infrastruktur i hela Sverige. Forumet ska bidra till att
            regeringens bredbandsmål och de riksdagsbundna målen för
            digitaliseringspolitiken kan uppnås.
          </p>
          <p>
            Även de regionala bredbandskoordinatorerna får fortsatt stöd under
            samma period. Bredbandskoordinatorerna har en viktig funktion i
            arbetet med att identifiera hinder för utbyggnad, analysera möjliga
            lösningar och bidra till en god samverkan mellan de aktörer som har
            viktiga roller i utbyggnaden.
          </p>
          <p>
            - Utbyggnaden av bredband ska fortsätta i hela landet. De regionala
            bredbandskoordinatorerna har en nyckelroll i att uppnå
            bredbandsmålen och bidra till en effektiv utbyggnad, säger energi-
            och digitaliseringsminister Anders Ygeman.
          </p>
          <p>
            Regeringen beslutar därför att erbjuda regionerna att upprätthålla
            en funktion som regional bredbandskoordinator även under perioden
            2021–2025. För ändamålet beviljar regeringen årligen 2021–2025 upp
            till 857 000 kronor per region. Regeringen har beräknat att 21
            miljoner kronor ska avsättas för satsningen på de regionala
            bredbandskoordinatorerna 2021 och 2022 och 15 miljoner kronor från
            och med 2023. Beslut om medel för 2022–2025 fattas årligen efter
            riksdagens beslut om budgetpropositionen.
          </p>
          <p>
            Regeringen har också gett Post- och telestyrelsen i uppdrag att vara
            ett stöd till Bredbandsforum samt att stödja de regionala
            bredbandskoordinatorerna.
          </p>
          <p>
            <a href="https://www.regeringen.se/pressmeddelanden/2020/11/forum-for-bredbandsutbyggnad-i-hela-landet/">
              Klicka här för att läsa pressmeddelandet på regeringens webbplats.
            </a>
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "digitalhjaelpen",

    pageData: {
      headline: "Digitalhjälpen – ny tjänst från PTS",
      createDate: "11 januari 2021",
      textBody: [
        <div>
          <p className="ingress">
            Post- och telestyrelsen (PTS) har tagit fram en ny del av
            webbplatsen som ska hjälpa äldre att förstå och använda digitala
            tjänster och verktyg. På Digitalhjälpen finns information,
            steg-för-steg-guider, instruktionsfilmer och tips om tjänster och
            insatser.
          </p>
          <p>
            Med anledning av den rådande pandemin och den ökade isolering som
            många äldre upplever gav regeringen ett uppdrag till PTS i april
            2020. PTS ska genomföra insatser som minskar den sociala isoleringen
            och ökar äldres digitala delaktighet och förmåga att använda
            digitala tjänster.
          </p>
          <p>
            En av dessa insatser är Digitalhjälpen - webbsidor med vägledning
            och tips som hjälper äldre att förstå och använda digitala tjänster
            och verktyg.
          </p>
          <p>
            PTS har samlat information om goda exempel på insatser, tjänster och
            verktyg på sin webbplats för att det ska vara lättare hitta det som
            erbjuds.
          </p>
          <p>
            På Digitalhjälpen finns även helt nytt stödmaterial som PTS har
            tagit fram, i form av steg-för-steg-guider, instruktionsfilmer och
            fördjupande information om videosamtal och hjälp på distans med
            fjärrstyrning. För personal som arbetar med äldre inom vård och
            omsorg finns en särskild sektion med samlade tips och information
            för de som arbetar med att stötta äldre.
          </p>
          <p>
            Digitalhjälpen finns på{" "}
            <a href="http://www.pts.se/digitalhjalpen">pts.se/digitalhjalpen</a>
          </p>
          <div>
            <div>
              <h3>Olika digitala tjänster</h3>
              <p>Här får du hjälp med sådant som hur du:</p>
              <ul>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/tips-pa-digitala-tjanster/#umg%C3%A5s-digitalt"
                    title="Umgås digitalt sektion"
                  >
                    &nbsp;Umgås digitalt
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/tips-pa-digitala-tjanster/#handla-digitalt"
                    title="Handla och betala digitalt sektion"
                  >
                    &nbsp;Handla och betala digitalt
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/tips-pa-digitala-tjanster/#legitimera-digitalt"
                    title="Legitimera dig digitalt sektion"
                  >
                    &nbsp;Legitimera dig digitalt
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/tips-pa-digitala-tjanster/#teknisk-hjalp"
                    title="Tips på teknisk hjälp sektion"
                  >
                    &nbsp;Tips på teknisk hjälp
                  </a>
                </li>
              </ul>
              <h3>Digital kompetensutveckling</h3>
              <p>Här kan du lära dig mer om:</p>
              <ul>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/utveckla-din-digitala-kompetens/#kunskap-inspiration"
                    title="Kunskap och inspiration sektion"
                  >
                    &nbsp;Kunskap och inspiration
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/utveckla-din-digitala-kompetens/#kurser-utbildningar"
                    title="Kurser och utbildningar sektion"
                  >
                    &nbsp;Kurser och utbildningar
                  </a>
                </li>
              </ul>
              <h3>Praktiska guider</h3>
              <p>
                Vet du direkt vilken guide du vill ta del av så klicka på
                länkarna nedan:
              </p>
              <ul>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/guider-for-digitala-tjanster/#videosamtal"
                    title="Kom igång med videosamtal sektion"
                  >
                    Kom igång med videosamtal
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/guider-for-digitala-tjanster/#distans"
                    title=" Hjälp på distans med fjärrstyrning sektion"
                  >
                    &nbsp;Hjälp på distans med fjärrstyrning
                  </a>
                </li>
                <li>
                  <a
                    href="https://pts.se/sv/digitalhjalpen/guider-for-digitala-tjanster/#handla"
                    title="Handla mat och läkemedel på internet sektion"
                  >
                    &nbsp;Handla mat och läkemedel på internet
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "byanaetsforum-blir-branschorganisation-foer-fiberfoereningar",

    pageData: {
      headline: "Byanätsforum blir branschorganisation för fiberföreningar",
      createDate: "2 februari 2021",
      textBody: [
        <div>
          <p className="ingress">
            Byanätsforum blir en ideell förening från och med den 1 mars 2021
            och arbetet inför att bli en nationell branschorganisation är snart
            färdigt.
          </p>
          <p>
            <img
              id="__mcenew"
              src="/media/1310/byanaetsforum.png"
              alt=""
              data-udi="umb://media/5859cc11ede344e1a578731460f6f377"
            />
          </p>
          <p>
            Byanätsforum är ett nätverk med bredbandsföreningar som bygger
            bredbandsnät på svensk landsbygd. Arbetet startades i Bredbandsforum
            som drev verksamheten från 2015 fram till våren 2018, då Hela
            Sverige ska leva, Coompanion och LRF tog över efter att ha fått
            projektpengar beviljade ur landsbygdsprogrammet.
          </p>
          <p>
            Under 2020 har projektet planerat för att ta nästa kliv, och bilda
            en nationell branschorganisation. Idag drivs Byanätsforum som ett
            nätverk utan att vara en egen juridisk person. Verksamheten är
            finansierad med ett stöd ur landsbygdsprogrammet och bakom nätverket
            finns Riksorganisationen Hela Sverige ska leva, Coompanion och LRF.
            Svenska Stadsnätsföreningen har varit med som samarbetspart under
            2020 och i den kommande föreningen är det tänkt att samtliga
            samarbetsorganisationer ska finnas med i styrelsen tillsammans med
            företrädare för landets bredbandsföreningar.
          </p>
          <p>
            Under februari kommer Byanätsforums kansli att komma med mer
            information om processen framåt och hur man ansöker om medlemskap,
            vilka fördelarna är med ett medlemskap och mer detaljer kring hur
            föreningen är uppbyggd.
          </p>
          <p>
            – Vi har lyssnat på våra medlemmar genom enkäter, enskilda samtal
            och genom att arrangera ett arbetsmöte med ett 20-tal av våra mest
            aktiva medlemmar. Vi tror nu att vi har skapat oss en förståelse för
            vad våra medlemmar behöver och vill se för typ av verksamhet när
            Byanätsforum blir en nationell förening, säger Anna Johansson på
            Byanätsforums kansli.
          </p>
          <p>
            Besök Byanätsforums webbplats på www.byanatsforum.se, eller kontakta
            Byanätsforums kansli på byanatsforum@helasverige.se för mer
            information om förändringen.
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route:
      "ny-arbetsgrupp-inom-bredbandsforum-avtal-som-verktyg-foer-samverkan",

    pageData: {
      headline:
        "Ny arbetsgrupp inom Bredbandsforum – Avtal som verktyg för samverkan",
      createDate: "1 mars 2021",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums styrgrupp har tillsatt en arbetsgrupp som ska
            undersöka olika aspekter av avtal om samverkan kring
            bredbandsutbyggnad. Arbetsgruppen leds av Terese Bengard,
            Verksamhetschef på Hela Sverige ska Leva och styrgruppsledamot i
            Bredbandsforum, och kommer slutredovisa sitt arbete i oktober 2021.
          </p>
          <p>
            På både lokal och regional nivå används idag avtal i olika former
            som verktyg för att bestämma upplägg och målsättningar för samverkan
            kring bredbandsutbyggnad. Dessa kallas oftast för ”samverkansavtal”,
            ”intentionsavtal” eller ”avsiktsförklaringar”, och tecknas av olika
            konstellationer av offentliga och privata aktörer runt om i landet,
            med varierande grad av formellt åtagande från de inblandade.
            Exempelvis i ett samverkansavtal som tecknas mellan en kommun och en
            privat nätägare så ingår ofta en gemensam ambition om hur många
            hushåll och företag som ska erbjudas anslutning samt till vilket
            pris. Klart är att det finns en stor potential i avtalen som en
            pådrivande faktor till samverkan kring bredbandsutbyggnad, men att
            det är oklart hur avtalen tillämpas samt vilka effekter de ger. En
            större kunskap om detta har efterfrågats av aktörerna i
            Bredbandsforum.
          </p>
          <p>
            I en tidigare arbetsgrupp inom ramen för Bredbandsforum,
            Landsbygdsgruppen, genomfördes en mindre förstudie över hur
            samverkansavtal mellan kommuner och marknadsaktörer hanterar frågor
            om bredbandsutbyggnad på landsbygden. Förstudien resulterade i ett
            diskussionsunderlag som visar att samverkansavtal i vissa fall kan
            vara en bra grund för bredbandsutbyggnad, men att det finns en stor
            variation i utformningen av avtalen, liksom i det upplevda utfallet.
            Med utgångspunkt i detta arbete har nu en arbetsgrupp startats.
          </p>
          <p>
            Arbetsgruppen kommer att ledas av Terese Bengard, Verksamhetschef på
            Hela Sverige ska Leva och styrgruppsledamot i Bredbandsforum, och
            slutredovisa sitt arbete i oktober 2021.
          </p>
          <h3>Arbetsgruppens uppdrag</h3>
          <p>
            Fokus för arbetsgruppen är att få en gemensam bild, utifrån
            erfarenheter från olika aktörer, av hur bredbandsutbyggnad främjas
            genom samverkansavtal.
          </p>
          <p>
            Med utgångspunkt i de frågor som finns ska arbetsgruppen beskriva:
          </p>
          <ul>
            <li>
              under vilka förutsättningar samverkansavtal kan vara särskilt
              effektiva för att främja bredbandsutbyggnad
            </li>
            <li>
              hur processen att ta fram samverkansavtal kan utformas och följas
              upp för att ge så stor genomslagskraft som möjligt
            </li>
            <li>
              hur samverkansavtalens syfte och innehåll bäst kan bidra till ökad
              bredbandsutbyggnad
            </li>
            <li>
              om det finns behov av ökad likformighet avseende samverkansavtal i
              syfte att öka deras effektivitet
            </li>
            <li>
              goda exempel på samverkansavtal som gett god effekt och processer
              för att ta fram dessa
            </li>
          </ul>
          <p>Arbetsgruppen ska därför bland annat:</p>
          <ul>
            <li>
              undersöka vilken effekt olika typer av avtal kan ge på
              utbyggnadstakten
            </li>
            <li>
              klargöra om avtal kan bidra till ökad tydlighet gällande roll- och
              ansvarsfördelning
            </li>
            <li>belysa hur geografiska utmaningar kan hanteras</li>
            <li>klarlägga hur alla de olika bredbandsmålen kan främjas</li>
          </ul>
          <p>
            Läs mer om uppdraget på{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              Bredbandsforums webbplats
            </a>
            .
          </p>
        </div>,
      ],
      asideTitle: "Information",
      asideBody: [
        <p>
          <strong>
            <a
              data-udi="umb://media/237995565559451ba61b6befe859c9fb"
              href="/media/1311/direktiv-ag-avtal-som-verktyg-foer-samverkan.pdf"
              title="Direktiv AG Avtal som verktyg för samverkan.pdf"
            >
              Ladda ned direktiv för arbetsgrupp Avtal som verktyg för samverkan
            </a>
          </strong>
        </p>,
      ],
    },
  },
  {
    route: "bredband-en-allt-viktigare-fraaga-foer-sveriges-regioner",

    pageData: {
      headline: "Bredband en allt viktigare fråga för Sveriges regioner",
      createDate: "22 mars 2021",
      textBody: [
        <div>
          <p>
            Ett aktivt arbete med samordning av bredbandsutbyggnaden på
            nationell, regional som kommunal nivå är viktigt om målen i
            regeringens bredbandsstrategi ska kunna uppnås. Därför beslutade
            regeringen att förlänga mandatet för de regionala
            bredbandskoordinatorer i Sveriges regioner under perioden 2020-2025.
            Det nationella sekretariatet följer årligen upp de regionala
            bredbandskoordinatorernas verksamhet.
          </p>
          <p>
            Den senaste uppföljningsrapporten visar att kartläggning av
            bredbandssituationen i länet har varit ett prioriterat område under
            året och är ett viktigt underlag för planering, uppföljning och
            inriktning i det regionala bredbandsarbetet. Detta fokus på
            kartläggning beror delvis på att de regionala
            bredbandskoordinatorerna har en viktig roll att spela i PTS process
            för det{" "}
            <a href="https://www.pts.se/bredbandsstod">
              nya nationella bredbandsstödet
            </a>
            .
          </p>
          <p>
            Stöd i kartläggningsarbetet och kunskaps- eller
            informationsaktiviteter är viktiga delar i koordinatorernas stöd
            till kommunerna. Exempel på andra stödjande aktiviteter är regional
            finansiering av bredbandsutbyggnad, samordning av projekt för
            ortssammanbindande nät samt genomförande av Bredbandslyftet,
            Bredbandsforums program för vägledning till kommuner.
          </p>
          <p>
            Under 2021 kommer frågor om det nya statliga bredbandsstödet,
            kartläggning, mobilt bredband, och alternativa tekniker för snabbt
            bredband ligga i fokus i koordinatorernas gemensamma arbete inom
            ramen för det nationella sekretariatet.
          </p>
          <p>
            Klicka här för att hämta rapporten{" "}
            <a
              data-udi="umb://media/7762ea510d5f448ab6c34815638c0ce2"
              href="/media/1312/uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2020.pdf"
              title="Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2020.pdf"
            >
              <em>
                Uppföljning av de regionala bredbandskoordinatorernas verksamhet
                2020
              </em>
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned rapporten",
      asideBody: [
        <p>
          <a
            data-udi="umb://media/7762ea510d5f448ab6c34815638c0ce2"
            href="/media/1312/uppfoeljning-av-de-regionala-bredbandskoordinatorernas-verksamhet-2020.pdf"
            title="Uppföljning av de regionala bredbandskoordinatorernas verksamhet 2020.pdf"
          >
            <em>
              <strong>
                Uppföljning av de regionala bredbandskoordinatorernas verksamhet
                2020 (PDF)
              </strong>
            </em>
          </a>
        </p>,
      ],
    },
  },
  {
    route: "fastighetsnat",

    pageData: {
      headline:
        'Vägledningen "Planering för drift och underhåll av fastighetsnät"',
      createDate: "20 april 2021",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums kansli har producerat en vägledning för planering av
            drift och underhåll i svenska fastighetsnät. Genom att följa ett
            flödesschema med relevanta frågeställningar och dokumentera
            resultatet i en checklista kan fastighetsägare identifiera möjliga
            förbättringsområden och åtgärdsförslag. Vägledningen är ett led i
            Bredbandsforums arbete att underlätta för kommunikationen mellan
            aktörer som är inblandade i förvaltningen av denna viktiga del av
            leveranskedjan för digitala samhällstjänster.
          </p>
          <p>
            Bredbandsforums kansli har{" "}
            <a
              data-udi="umb://document/8bfb438e5cfb4e38a2577a429f5c449c"
              href="/nyheter/utmaningar-i-drift-och-underhaall-av-fastighetsnaet/"
              title="Utmaningar i drift och underhåll av fastighetsnät"
            >
              tidigare beskrivit
            </a>{" "}
            hur drift och underhåll hanteras i svenska fastighetsnät och
            identifierat utmaningar på området. En viktig slutsats var att
            fastighetsägare med mindre organisationer har små förutsättningar
            att bedöma behov, kravställa på rätt nivå, eller tolka avtal. Olika
            intressenter ser samtidigt behov av att kunna kommunicera med
            fastighetsägare i syfte att exempelvis inplacera utrustning, hyra
            förbindelse, eller göra riskanalyser för leverans av
            samhällstjänster.
          </p>
          <p>
            Bredbandsforums kansli har nu producerat en vägledning för
            fastighetsägare, som kan användas som gränssnitt för kommunikation
            mellan olika aktörer på området. Vägledningen syftar till att hjälpa
            fastighetsägare att systematiskt hantera planering för drift och
            underhåll av det egna fastighetsnätet. Genom att besvara frågorna i
            flödesschemat och dokumentera resultatet i checklistan kan möjliga
            förbättringsområden och åtgärdsförslag identifieras.
          </p>
          <p>
            Kansliet kommer nu arbeta för att vägledningen ska införlivas i en
            befintlig struktur som säkrar den långsiktiga förvaltningen.
          </p>
          <p>
            Ladda ned vägledningen{" "}
            <a
              data-udi="umb://media/497841a0d57c480889e3422bf86278dd"
              href="/media/1313/vaegledning-planering-foer-drift-och-underhaall-av-fastighetsnaet-bredbandsforum.pdf"
              title="Vägledning Planering för drift och underhåll av fastighetsnät – Bredbandsforum.pdf"
            >
              "Planering för drift och
            </a>{" "}
            <a
              data-udi="umb://media/497841a0d57c480889e3422bf86278dd"
              href="/media/1313/vaegledning-planering-foer-drift-och-underhaall-av-fastighetsnaet-bredbandsforum.pdf"
              title="Vägledning Planering för drift och underhåll av fastighetsnät – Bredbandsforum.pdf"
            >
              underhåll av fastighetsnät" (PDF)
            </a>
          </p>
          <p>
            Ladda ned checklistan{" "}
            <a
              data-udi="umb://media/1e80a1649abd4ed7b25379ec13ea0ea6"
              href="/media/1316/checklista-planering-foer-drift-och-underhaall-av-fastighetsnaet-bredbandsforum.pdf"
              title="Checklista Planering för drift och underhåll av fastighetsnät – Bredbandsforum.pdf"
            >
              "Planering för drift och underhåll av fastighetsnät" (PDF)
            </a>
          </p>
          <p>
            Ladda ned artikeln{" "}
            <a
              data-udi="umb://media/bde21d29ff03407eabc0428518e8a133"
              href="/media/1302/utmaningar-i-drift-och-underhaall-av-fastighetsnaet.pdf"
              title="Utmaningar i drift och underhåll av fastighetsnät.pdf"
            >
              "Utmaningar i drift och underhåll av fastighetsnät" (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned vägledningen",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/497841a0d57c480889e3422bf86278dd"
              href="/media/1313/vaegledning-planering-foer-drift-och-underhaall-av-fastighetsnaet-bredbandsforum.pdf"
              title="Vägledning Planering för drift och underhåll av fastighetsnät – Bredbandsforum.pdf"
            >
              Vägledningen "Planering för drift och underhåll av fastighetsnät"
              (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/1e80a1649abd4ed7b25379ec13ea0ea6"
              href="/media/1316/checklista-planering-foer-drift-och-underhaall-av-fastighetsnaet-bredbandsforum.pdf"
              title="Checklista Planering för drift och underhåll av fastighetsnät – Bredbandsforum.pdf"
            >
              Checklista "Planering för drift och underhåll av fastighetsnät"
              (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/bde21d29ff03407eabc0428518e8a133"
              href="/media/1302/utmaningar-i-drift-och-underhaall-av-fastighetsnaet.pdf"
              title="Utmaningar i drift och underhåll av fastighetsnät.pdf"
            >
              Artikeln "Utmaningar i drift och underhåll av fastighetsnät" (PDF)
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route:
      "senaste-nytt-om-bredbandsforums-samverkansprojekt-foer-baettre-mobiltaeckning",

    pageData: {
      headline:
        "Senaste nytt om Bredbandsforums samverkansprojekt för bättre mobiltäckning",
      createDate: "17 september 2021",
      textBody: [
        <div>
          <p className="ingress">
            Sverige ligger långt fram i utbyggnaden av infrastruktur för mobilt
            bredband. För de allra flesta av oss fungerar det mobila bredbandet
            för de tjänster och applikationer vi använder, där vi befinner oss.
            Men i en del områden, särskilt på Sveriges landsbygder, upplever
            många att täckningen är bristfällig.
          </p>
          <p>
            I de allra flesta fall beror det helt enkelt på att det inte finns
            tillräckligt med sändarplatser – mobilmaster – på platsen. Det kan i
            sin tur bero på att operatörerna bedömer att kundunderlaget eller
            behovet är för litet för att motivera den stora investering som det
            är att bygga en mobilmast. Särskilt om både el och fiber behöver
            dras fram för att masten ska fungera. Men skulle bedömningen bli
            annorlunda om operatören hade tillgång till mer och bättre
            information, lokalkännedom, och det även fanns större möjligheter
            till samverkan mellan kommuner, regioner, och mobiloperatörer?
          </p>
          <p>
            Bredbandsforum samarbetar just nu med Region Värmland i ett
            spännande pilotprojekt, som utreder dessa frågor i kommunerna
            Filipstad, Torsby och Årjäng.
          </p>
          <p>
            Pilotprojektet syftar till att skapa en arbetsprocess för förbättrad
            samverkan mellan operatörer, regioner och kommuner. Genom att
            hjälpas åt skapas en mycket bättre bild av var täckningen är
            bristfällig och hur förutsättningarna ser ut för att etablera nya
            sändarplatser. Det kan exempelvis finnas utbyggnadsplaner från
            operatörerna som regioner och kommuner borde känna till, och
            invånare och verksamheter ute i kommunerna kan ha värdefull
            information som påverkar mobiloperatörernas kommersiella
            förutsättningar.
          </p>
          <p>
            Olika metoder används för att ta reda på hur det är ställt med
            täckningen i kommunerna såsom signalmätningar, intervjustudier och
            interaktiva kartor. När sedan specifika områden identifierats och de
            marknadsmässiga förutsättningarna undersökts tillsammans med
            mobiloperatörerna är förhoppningen att pilotprojektet resulterar i
            en process som kan användas även i andra delar av landet. Samtliga
            av landets fyra mobiloperatörer deltar i projektet.
          </p>
          <p>
            Projektet, som är ett resultat av Bredbandsforums arbetsgrupp{" "}
            <a
              data-udi="umb://document/d982f1bee33d45c9b2fc359567c8b172"
              href="/nyheter/infrastruktur2023/"
              title="Så skapar vi digital mobilitet i hela Sverige - Slutrapport från Infrastruktur för mobilt bredband 2023"
            >
              Infrastruktur för mobilt bredband
            </a>
            , kommer att avrapportera resultatet till Bredbandsforums styrgrupp
            under hösten.&nbsp;Läs mer om tankarna bakom projektet i
            arbetsgruppens{" "}
            <a
              data-udi="umb://media/c130d9ba11674a23be09e83359ada081"
              href="/media/1301/infrastruktur-foer-mobilt-bredband-2023.zip"
              title="Infrastruktur för mobilt bredband 2023.zip"
            >
              slutrapport
            </a>
            .
          </p>
        </div>,
      ],
      asideTitle: false,
      asideBody: [],
    },
  },
  {
    route: "bredbandslyftet-utvaerdering-och-framtid",

    pageData: {
      headline: "Bredbandslyftet – utvärdering och framtid",
      createDate: "27 oktober 2021",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandslyftet är ett vägledningsprogram från Bredbandsforums som
            syftar till att stimulera bredbandsutbyggnaden i Sveriges kommuner.
            Totalt har 24 Bredbandslyft genomförts mellan 2018 – 2021. Inga fler
            kommunala bredbandslyft är nu planerade, men med start 2022 kommer
            regionala bredbandslyft anordnas.
          </p>
          <p>
            <a
              data-udi="umb://document/f18c5ad8ff714c6088f24929f15aff14"
              href="/bredbandslyftet/"
              title="Bredbandslyftet"
            >
              Bredbandslyftet
            </a>{" "}
            är ett vägledningsprogram som kan hjälpa en kommun se över
            strategiska val som gjorts och hitta en ny eller justerad inriktning
            som främjar bredbandstillgången i kommunen. I programmet kartläggs
            kommunens nuvarande situation och investeringsbehovet för den
            utbyggnad som återstår uppskattas. Bredbandslyftet riktas till
            kommunledningen och genomförs på plats i kommunen.
          </p>
          <p>
            Totalt har Bredbandsforums kansli tillsammans med de regionala
            bredbandskoordinatorerna genomfört 24 Bredbandslyft mellan 2018 –
            2021. Intresset för programmet är nu inte lika stort och inga nya
            kommunala bredbandslyft har initierats. En{" "}
            <a
              data-udi="umb://media/b6a0efe0f73c4ebe8dd2a9dcb6f2d43e"
              href="/media/1318/uppfoeljning-bredbandslyftet-20210915.pdf"
              title="Uppföljning Bredbandslyftet 20210915.pdf"
            >
              utvärdering
            </a>{" "}
            av programmet har därför genomförts under sommaren 2021.
          </p>
          <p>
            Representanter från 18 kommuner har intervjuats, och generellt får
            Bredbandslyftet ett mycket gott omdöme. Bredbandslyftet har enligt
            deltagarna bidragit till att engagemanget hos den politiska
            ledningen har ökat och att bredbandsfrågan har fått högre prioritet
            och anses mer viktig. En majoritet av kommunerna uppger också att de
            har ändrat eller utvecklat strategierna för bredband, och att
            samverkan med externa aktörer har påverkats i positiv riktning.
          </p>
          <p>
            Inga fler kommunala bredbandslyft är nu planerade, men
            Bredbandsforums kansli kommer förvalta metoden och materialet som
            använts. Då flera kommuner och regioner har visat intresse för ett
            bredbandslyft på regional nivå, där deltagarna kan samverka och lära
            av andra kommuner, kommer nu regionala bredbandslyft erbjudas i
            samarbete med de regionala bredbandskoordinatorerna. Kontakta din
            regionala bredbandskoordinator om din organisation är intresserad av
            att delta:{" "}
            <a href="/bredbandskoordinatorer/">
              bredbandsforum.se/bredbandskoordinatorer/
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Information",
      asideBody: [
        <div>
          <p className="ingress">
            <a
              data-udi="umb://media/b6a0efe0f73c4ebe8dd2a9dcb6f2d43e"
              href="/media/1318/uppfoeljning-bredbandslyftet-20210915.pdf"
              title="Uppföljning Bredbandslyftet 20210915.pdf"
            >
              Utvärderingen av de kommunala bredbandslyften (PDF)
            </a>
          </p>

          <h4>
            <strong>Karta över genomförda kommunala bredbandslyft:</strong>
          </h4>
          <p>
            <img
              src="/media/1317/karta-bredbandslyftet.png?width=212.14099216710184&amp;height=500"
              alt="Karta över Sveriges kommuner med 24 kommuner markerade"
              data-udi="umb://media/0fd000d17b8b488ea8918d483765fdfe"
            />
          </p>
        </div>,
      ],
    },
  },
  {
    route:
      "ny-vaegledning-i-fraagor-om-oeverenskommelser-och-avtal-om-samverkan-kring-bredbandsutbyggnad",

    pageData: {
      headline:
        "Ny vägledning i frågor om överenskommelser och avtal om samverkan kring bredbandsutbyggnad",
      createDate: "11 november 2021",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums arbetsgrupp "Avtal som verktyg för samverkan" har nu
            lämnat sin slutrapport till Bredbandsforums styrgrupp. Arbetet har
            bland annat resulterat i ett diskussionsunderlag som olika aktörer
            kan använda för att öka sin kunskap och resonera i frågor om
            överenskommelser och avtal kring samverkan om bredbandsutbyggnad –
            s.k. samverkansavtal. Dessa typer av överenskommelser spelar en
            viktig roll i samverkan kring bredbandsutbyggnad på lokal nivå, och
            Bredbandsforums diskussionsunderlag kan vara ett viktigt stöd i
            denna samverkansprocess.
          </p>
          <p>
            På både lokal och regional nivå används idag överenskommelser i
            olika former som verktyg för att bestämma upplägg och målsättningar
            för samverkan kring bredbandsutbyggnad. Dessa kallas ofta exempelvis
            för ”samverkansavtal”, ”intentionsavtal” eller
            ”avsiktsförklaringar”. Bredbandsforums arbetsgrupp{" "}
            <em>Avtal som verktyg för samverkan</em> har haft i uppdrag att öka
            kunskapen om vilken roll överenskommelser kan spela i det fortsatta
            arbetet med att uppnå målen i regeringens bredbandsstrategi.
          </p>
          <p>
            Arbetsgruppens slutsats är att det finns många olika sätt att
            stimulera bredbandsutbyggnaden, och att samverkansavtal och andra
            överenskommelser kommer att utgöra en viktig pusselbit. Historiskt
            sett har många samverkansavtal gett en positiv effekt på utbyggnaden
            men detta är beroende av ett antal framgångsfaktorer. Arbetsgruppen
            har därför tagit fram ett diskussionsunderlag som kan ge vägledning
            i viktiga frågor – beroende på var i processen man befinner sig.
            Materialet förtydligar även vikten av att vara uppmärksam kring
            frågor om lagstiftning, upphandling och konkurrensutsättning.
          </p>
          <p>
            Diskussionsunderlaget är baserat på kartläggning, intervjuer, och
            erfarenheter från arbetsgruppens deltagare. I materialet varvas
            fakta med bilder som ger anledning till eftertanke och uppmuntrar
            till diskussion. Kartläggningsrapporten "Samverkansavtal som verktyg
            för bredbandsutbyggnad" har fungerat som ett underlag till
            arbetsgruppens diskussioner och publiceras tillsammans med detta
            material.
          </p>
          <p>
            De aktörer som deltagit i arbetsgruppen är: Perstorps kommun, Östra
            Göinge kommun, Kalmar kommun, Haparanda kommun, Filipstads kommun,
            Region Västmanland, Region Sörmland, Svenska Stadsnätsföreningen,
            Sveriges Kommuner och Regioner, Servanet, GlobalConnect/IP-Only,
            Telia Company och Open Infra.&nbsp;
          </p>
          <p>
            Här kan du ta del av materialet som arbetsgruppen slutrapporterat
            till Bredbandsforums styrgrupp:
          </p>
          <p>
            <a
              data-udi="umb://media/bc5570d688884c6881cd60ad1fb5f146"
              href="/media/1319/slutrapport-bredbandsforums-arbetsgrupp-avtal-som-verktyg-foer-samverkan-oktober-2021.pdf"
              title="Slutrapport – Bredbandsforums Arbetsgrupp Avtal som Verktyg för Samverkan – Oktober 2021.pdf"
            >
              Slutrapport från Bredbandsforums arbetsgrupp Avtal som verktyg för
              samverkan – oktober 2021 (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/7a0ad44e9ba049ee96543440224b6097"
              href="/media/1320/diskussionsunderlag-bredbandsforums-arbetsgrupp-avtal-som-verktyg-foer-samverkan-oktober-2021.pdf"
              title="Diskussionsunderlag – Bredbandsforums Arbetsgrupp Avtal som Verktyg för Samverkan – Oktober 2021.pdf"
            >
              Diskussionsunderlag om överenskommelser kring samverkan om
              bredbandsutbyggnad – oktober 2021 (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/ea3ac73c287c41329d5244c388e0eccd"
              href="/media/1321/kartlaeggning-avtal-som-verktyg-foer-samverkan.pdf"
              title="Kartläggning – Avtal som verktyg för samverkan.pdf"
            >
              Kartläggningen Avtal som verktyg för samverkan (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned diskussionsunderlag och slutrapport här",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/bc5570d688884c6881cd60ad1fb5f146"
              href="/media/1319/slutrapport-bredbandsforums-arbetsgrupp-avtal-som-verktyg-foer-samverkan-oktober-2021.pdf"
              title="Slutrapport – Bredbandsforums Arbetsgrupp Avtal som Verktyg för Samverkan – Oktober 2021.pdf"
            >
              Slutrapport från Bredbandsforums arbetsgrupp Avtal som verktyg för
              samverkan – oktober 2021 (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/7a0ad44e9ba049ee96543440224b6097"
              href="/media/1320/diskussionsunderlag-bredbandsforums-arbetsgrupp-avtal-som-verktyg-foer-samverkan-oktober-2021.pdf"
              title="Diskussionsunderlag – Bredbandsforums Arbetsgrupp Avtal som Verktyg för Samverkan – Oktober 2021.pdf"
            >
              Diskussionsunderlag om överenskommelser kring samverkan om
              bredbandsutbyggnad – oktober 2021 (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/ea3ac73c287c41329d5244c388e0eccd"
              href="/media/1321/kartlaeggning-avtal-som-verktyg-foer-samverkan.pdf"
              title="Kartläggning – Avtal som verktyg för samverkan.pdf"
            >
              Kartläggningen Avtal som verktyg för samverkan (PDF)
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "bredbandsforum-fokuserar-paa-maaluppfyllelse-2025",

    pageData: {
      headline: "Bredbandsforum fokuserar på måluppfyllelse 2025",
      createDate: "7 februari 2022",
      textBody: [
        <div>
          <p className="ingress">
            Under 2022 kommer Bredbandsforum bjuda in aktörer att samverka i ett
            antal projekt som fokuserar på frågor viktiga för att regeringens
            bredbandsmål om ett helt uppkopplat Sverige år 2025 ska bli
            verklighet. Det handlar bland annat om hushållens betalningsförmåga,
            infrastruktur för fast bredband via mobilnätet, och riskfaktorer för
            tillståndsgivning. Arbetet ska resultera i en färdplan som beskriver
            hur de kvarvarande utmaningarna för utbyggnad av både trådbunden och
            trådlös bredbandsinfrastruktur kan överkommas.
          </p>
          <p>
            Det är nu över fem år sedan regeringen presenterade en ny{" "}
            <a
              data-udi="umb://document/d58cd2cbb7ea49678db520079b9a1a85"
              href="/sveriges-bredbandsstrategi/"
              title="Sveriges bredbandsstrategi"
            >
              bredbandsstrategi
            </a>{" "}
            för ett helt uppkopplat Sverige. Ambitionen i strategin är att hela
            Sverige bör ha tillgång till snabbt bredband år 2025. Det innebär i
            praktiken att ytterligare ca tre procent av Sveriges hushåll och
            företag bör ha fått möjlighet att ansluta sig till ett fibernät de
            kommande fyra åren – från dagens ca 95 procent till 98 procent. Det
            är en stor utmaning – i många av de områden som ännu inte är
            utbyggda är marknadsunderlaget svagt och nätbyggare och operatörer
            har svårt att få ihop affären. Lyckligtvis finns ett nationellt
            bredbandsstöd om 2,85 miljarder kronor som möjliggör och stimulerar
            en fortsatt utbyggnad. Förutom tillräcklig finansiering vet vi att
            en hög grad av efterfrågan och effektiva tillståndsprocesser är
            grundförutsättningar för en fortsatt utbyggnad.
          </p>
          <p>
            Lyckas vi gemensamt uppnå den första målsättningen innebär det
            samtidigt enligt strategin att över 100&nbsp;000 av Sveriges hushåll
            och företag inte kommer att fått möjlighet att ansluta sig till
            fibernätet. Då är det oerhört viktigt att det finns tillgång till
            andra typer av bredbandsinfrastruktur som kan ge en anslutning med
            hög kapacitet. Och vi kan inte vänta till 2025 med att få reda på om
            alternativ verkligen kommer att finnas tillgängliga till en rimlig
            kostnad. Vi kan inte vänta med att förstå om det kommer finnas
            fysisk infrastruktur på plats, t ex i form av mobilmaster, som
            behövs för att erbjuda exempelvis fast bredband via mobilnätet i
            områden där marknadsförutsättningarna är svaga.
          </p>
          <p>
            Därför har Bredbandsforums styrgrupp bestämt att verksamheten år
            2022 ska fokusera på ett antal viktiga utmaningar som behöver ett
            förnyat fokus, och även ta fram en färdplan för måluppfyllelse år
            2025. Färdplanen kommer att sammanfatta de kvarstående utmaningarna
            och riskfaktorerna som kan påverka utbyggnaden av infrastrukturer
            som rör de två sidorna av bredbandsstrategins mål, vad som måste
            förändras nu om det ska kunna ge effekt i den pågående utbyggnaden,
            och vilken roll olika aktörer spelar i att förbättra samverkan på
            området.
          </p>
          <p>
            Mer specifikt kommer Bredbandsforum under året samla aktörernas
            perspektiv och insikter och
          </p>
          <ul>
            <li>
              presentera kunskapsunderlag om olika aspekter av utbyggnad av fast
              och trådlös bredbandsinfrastruktur,
            </li>
            <li>
              sätta strålkastarljuset på frågan om hushållens betalningsförmåga,
            </li>
            <li>beskriva och kommunicera om processer kring samhällsmaster,</li>
            <li>
              belysa olika riskfaktorer för tillståndsgivning i detta nya skede
              av utbyggnaden av infrastruktur för fast och trådlöst bredband.
            </li>
          </ul>
          <p>
            Kunskapsunderlagen och resultatet från aktiviteterna kommer sedan
            utgöra grunden för en framåtblickande färdplan som preciserar hur vi
            ska kunna nå regeringens Bredbandsmål för år 2025. Här på
            Bredbandsforums webbplats kommer du kunna ta del av allt material
            från arbetet.{" "}
            <a
              data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
              title="Bredbandsforums kansli"
            >
              Kontakta
            </a>{" "}
            gärna kansliet om du har frågor eller är intresserad av att bidra i
            arbetet!
          </p>
        </div>,
      ],
      asideTitle: 'Läs mer om fokusområdet "Måluppfyllnad 2025"',
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/17896ef286364975b9dc676335964ffc"
              href="/media/1332/fokus-foer-bredbandsforums-arbete-aar-2022.pdf"
              title="Fokus för Bredbandsforums arbete år 2022.pdf"
            >
              Fokus för Bredbandsforums arbete år 2022 (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/b49835ed89834105a920b5078776921b"
              href="/media/1331/bredbandsforums-handlingsplan-2022-2025.pdf"
              title="Bredbandsforums handlingsplan 2022-2025.pdf"
            >
              Bredbandsforums handlingsplan 2022-2025 (PDF)
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "kommer-regeringens-bredbandsmaal-att-uppnaas",

    pageData: {
      headline: "Kommer regeringens bredbandsmål att uppnås?",
      createDate: "14 april 2022",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforums kansli har skapat två kunskapsunderlag som beskriver
            hur det går i arbetet med att uppnå regeringens bredbandsmål för år
            2025. Baserat på bland annat uppgifter från marknadsaktörerna
            konstaterar kansliet att målet om att 98 procent bör ha tillgång
            till 1 Gbit/s är möjligt att uppnå, men att det finns riskfaktorer
            och en stor osäkerhet. Kunskapsunderlagen visar vidare att målet om
            att 99,9 procent av Sveriges hushåll och företag bör ha tillgång
            till 100 Mbit/s sannolikt inte kommer att uppnås. Åtminstone inte på
            det sätt som regeringen avsåg.
          </p>
          <p>
            Bredbandsforum fokuserar just nu på att skapa en{" "}
            <a
              data-udi="umb://document/2ac11b59595f4354a8fbe615a4113515"
              href="/nyheter/bredbandsforum-fokuserar-paa-maaluppfyllelse-2025/"
              title="Bredbandsforum fokuserar på måluppfyllelse 2025"
            >
              färdplan
            </a>{" "}
            för bredbandsstrategins måluppfyllnad år 2025. Som ett led i detta
            arbete har Bredbandsforums kansli producerat två kunskapsunderlag.
            De beskriver bakgrund, nuläge, utmaningar och möjligheter i arbetet
            med att uppnå{" "}
            <a
              data-udi="umb://document/d58cd2cbb7ea49678db520079b9a1a85"
              href="/sveriges-bredbandsstrategi/"
              title="Sveriges bredbandsstrategi"
            >
              den nationella bredbandsstrategins
            </a>{" "}
            tre delmål. Analyserna bygger på allmänt tillgänglig information så
            som nationella strategidokument och Post- och telestyrelsens
            marknadsdata och statistik, men även förstahandsuppgifter från
            organisationer i Bredbandsforums styrgrupp.
          </p>
          <p>
            Det{" "}
            <a
              data-udi="umb://media/a3ab6d970f9b4d8eacd83b638c8f26e3"
              href="/media/1334/kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 98 grundläggande procenten – Bredbandsforum 2022.pdf"
            >
              första kunskapsunderlaget
            </a>{" "}
            ger en samlad lägesbild av hur marknadsaktörer,
            intresseorganisationer, och offentliga aktörer ser på delmålet om 1
            Gbit/s. En förutsättning för måluppfyllnad enligt aktörerna är att
            fortsatta stödmedel finns att tillgå på samma nivå som nu fram till
            år 2025. Utan stödmedel riskerar utbyggnaden att stanna av helt i
            delar av landet. En stor riskfaktor om utbyggnaden saktar in eller
            stannar av är att tillgången till kompetenta entreprenörer minskar.
            Viktiga tillståndsprocesser och hantering av markavtal är den näst
            viktigaste riskfaktorn att ta hänsyn till. Detta innefattar många
            och vitt
            <br />
            skilda processer som behöver åskådliggöras tydligare.
          </p>
          <p>
            Även om delmålet uppnås kommer inte alla hushåll och företag vara
            anslutna, det krävs ett fortsatt arbete med att efteransluta hushåll
            och företag. Skälen till att vissa avböjer en anslutning skiljer
            sig, men en fråga som inte hanterats inom Bredbandsforum är att
            vissa hushåll inte har råd med anslutningsavgiften.
            Betalningsförmågan hos de hushåll som inte anslutit sig till
            bredbandsnät behöver diskuteras. Sammanfattningsvis tror vissa
            aktörer att delmålet om 1 Gbit/s till 98 procent av hushåll och
            företag inte kommer att uppnås, medan andra är mer hoppfulla.
          </p>
          <p>
            I det{" "}
            <a
              data-udi="umb://media/64cf47dcbbc74f7d81b7ce628cdc69a1"
              href="/media/1335/kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 2 avgörande procenten – Bredbandsforum 2022.pdf"
            >
              andra kunskapsunderlaget
            </a>{" "}
            konstaterar kansliet att marknadsaktörernas möjligheter att erbjuda
            bredband om 100 Mbit/s genom fast trådlöst bredband, t ex genom
            radiolänk eller mobilnäten, har överskattats. Det är idag mycket
            osäkert om målet om att 99,9 procent av Sveriges hushåll och företag
            bör ha tillgång till bredband om 100 Mbit/s år 2025 kan uppnås. I så
            fall kommer detta ske genom att satellitoperatörer börjar erbjuda
            abonnentanslutningar över hela landet och har kapacitet att betjäna
            ett mycket stort antal kunder. Det är dock tydligt i de
            bakomliggande resonemangen till målet att det var tänkt att uppnås
            genom tillgång till markbaserade trådlösa bredbandsnät, t ex genom
            radiolänk eller via mobilnäten. Det finns dock ett antal faktorer
            som skulle kunna förbättra möjligheterna till måluppfyllelse bortom
            år 2025.
          </p>
          <p>
            Bredbandsforum kan stödja offentliga aktörers strategiska hantering
            av frågor om tillgång till 100
            <br />
            Mbit/s, genom att exempelvis samla kunskap om och beskriva möjliga
            processer för upphandling, finansiering, drift och förvaltning av
            nya sändarplatser på landsbygder. Det är även viktigt att bidra till
            en ökad kunskap om slutanvändarperspektivet i de två avgörande
            procenten, exempelvis vad gäller betalningsförmåga, eller acceptans
            för olika tekniska lösningar för bredband. Bredbandsforum kommer
            även facilitera en strategisk diskussion om det nationella målet för
            tillgång till bredband om 100 Mbit/s år 2025.
          </p>
          <p>Ladda ned kunskapsunderlagen här:</p>
          <p>
            <a
              data-udi="umb://media/a3ab6d970f9b4d8eacd83b638c8f26e3"
              href="/media/1334/kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 98 grundläggande procenten – Bredbandsforum 2022.pdf"
            >
              Kunskapsunderlag om de 98 grundläggande procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/64cf47dcbbc74f7d81b7ce628cdc69a1"
              href="/media/1335/kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 2 avgörande procenten – Bredbandsforum 2022.pdf"
            >
              Kunskapsunderlag om de 2 avgörande procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/baa9ce5fdd2844cda8e4bd34422d4213"
              href="/media/1336/foerslag-till-aktiviteter-2022-bredbandsforum.pdf"
              title="Förslag till aktiviteter 2022 – Bredbandsforum.pdf"
            >
              Förslag till aktiviteter 2022 (PDF)
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned kunskapsunderlagen",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/a3ab6d970f9b4d8eacd83b638c8f26e3"
              href="/media/1334/kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 98 grundläggande procenten – Bredbandsforum 2022.pdf"
            >
              Kunskapsunderlag om de 98 grundläggande procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/64cf47dcbbc74f7d81b7ce628cdc69a1"
              href="/media/1335/kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
              title="Kunskapsunderlag om de 2 avgörande procenten – Bredbandsforum 2022.pdf"
            >
              Kunskapsunderlag om de 2 avgörande procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/baa9ce5fdd2844cda8e4bd34422d4213"
              href="/media/1336/foerslag-till-aktiviteter-2022-bredbandsforum.pdf"
              title="Förslag till aktiviteter 2022 – Bredbandsforum.pdf"
            >
              Förslag till aktiviteter 2022 (PDF)
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "bredbandsforum-undersoeker-intresset-foer-samhaellsmaster",

    pageData: {
      headline: "Bredbandsforum undersöker intresset för samhällsmaster",
      createDate: "15 september 2022",
      textBody: [
        <div>
          <p className="ingress">
            Mobilmaster som är helt eller delvis offentligt finansierade kallas
            även för samhällsmaster. I många områden saknas kommersiella
            förutsättningar för operatörer att förbättra mobilnätens täckning
            och kapacitet. Men kanske kan hjälp från offentliga aktörer, i form
            av exempelvis ekonomiska bidrag eller tillgång till mastfundament,
            fiber eller el, leda till att nya mobilmaster etableras i dessa
            områden.
          </p>
          <p>
            Bredbandsforum utreder just nu hur stort intresset för
            samhällsmaster är runt om i landet – och om frågan kan bli en viktig
            del i det framtida strategiska arbetet för att främja
            bredbandsutbyggnad. I denna första artikel berättar vi om några
            driftsatta samhällsmaster och vilka erfarenheter som kan dras från
            dessa exempel. I kommande artiklar berättar vi mer om planerade
            samhällsmaster och andra lösningar för en förbättrad mobiltäckning
            runt om i Sverige.
          </p>
          <h3>Brommös samhällsmast – Mariestad</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1343/brommoe.png"
              alt=""
              data-udi="umb://media/80dcdf4b62744a0c86c68fc589d188e5"
            />
          </p>
          <p>
            Vi börjar med att besöka ön <strong>Brommö</strong> där Mariestads
            kommun med hjälp av region Västra Götaland har etablerat en
            samhällsmast. Målet med projektet var främst att åtgärda problem med
            mobiltäckning på sjön runt ön, särskilt under turistsäsongen. Men
            masten beräknas även hjälpa kommunen och regionen att uppnå antagna
            mål om digitalisering och bredbandsutbyggnad.
          </p>
          <p>
            Genom en god kontakt med mobiloperatörer har kommunen lyckats
            säkerställa att den nya masten etablerades där ingen marknadsmässig
            utbyggnad förväntas ske. Operatörerna har även fått lämna synpunkter
            på mastens placering, i syfte att säkerställa att den så effektivt
            som möjligt förbättrar yttäckningen i området. Såväl en undersökning
            bland boende och besökande, och mobiloperatörernas täckningskartor,
            visade att täckningen i området var minst sagt bristfällig innan
            samhällsmasten togs i bruk. Masten har sedan 2021 förbättrat
            mobiltäckningen både på och runt Brommö och grannön Torsö markant.
          </p>
          <p>
            <strong>Allmän information</strong>
          </p>
          <ul>
            <li>Mastens höjd är 48 meter</li>
            <li>
              Masten är finansierad av Mariestads kommun och Västra Götalands
              regionen i samverkan.
            </li>
            <li>
              Samhällsmasten ägs på sikt av den entreprenör som vann
              anbudsgivningen för byggnation.
            </li>
            <li>
              Kostnaden för en mobiloperatör att inhysa sig i samhällsmasten är
              en årlig hyra på 13 000 kr. Då ingår inplacering av utrusning i
              bod samt rätt att placera 3 panelantenner i antennbärare.
            </li>
            <li>
              En kalkyl genomfördes av den långsiktiga affärsmässigheten
              inklusive investeringskostnader, driftskostnader och
              inplaceringsavgift. Kalkylen säger att minst 3 operatörer behöver
              vara inplacerade i masten för att uppnå affärsmässighet.
            </li>
            <li>
              I dagsläget är en operatör representerad med inplacering i
              samhällsmasten.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h3>Rockesholms samhällsmast – Hällefors</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1344/rockesholm.png"
              alt=""
              data-udi="umb://media/309d5f682a2a47979486854bbdc068ca"
            />
          </p>
          <p>
            I Hällefors i Örebro län finns behandlingshemmet{" "}
            <strong>Rockesholm</strong>. När kopparledningarna i området
            nedmonterades behövde intressenterna och de boende i området snabbt
            hitta en lösning som kunde förse t ex behandlingshemmet med en snabb
            och stabil uppkoppling. Mobiloperatörernas befintliga täckning i
            området räckte inte för att leverara 4G eller 5G med god kapacitet.
            Däremot fanns det fiber draget till den gamla telestationen i
            området. Kommunen initierade därför tillsammans med en entreprenör
            ett projekt för att få till stånd en samhällsmast.
          </p>
          <p>
            Kommunen lyckades tidigt engagera både boende och företagare i
            området, och många hushåll som sedan länge gett upp hoppet om att få
            köpa en fiberanslutning såg möjligheten att genom samhällsmasten
            ändå kunna få en snabb och stabil uppkoppling. Då ingen
            mobiloperatör valde att placera sig i masten fanns inte möjligheten
            att använda sig av frekvensband med goda utbredningsegenskaper.
            Tyvärr stötte projektet därför på en utmaning vid leverans av snabbt
            trådlöst bredband i vissa frekvensband – fri sikt. Trots att mastens
            höjd är 60 meter hade endast hälften av de intresserade hushållen
            "line-of-sight" till samhällsmasten – en förutsättning för att kunna
            koppla upp sig. De företag och hushåll som lyckades få till fri sikt
            genom att exempelvis fälla träd åtnjuter nu en bredbandsanslutning
            med höga hastighet och kapacitet. Samhällsmasten i Rockesholm är ett
            exempel på hur det i vissa fall kan krävas både engagemang och
            finansiering från just hela samhället i ett område. Här har
            verkligen alla – företagen på orten, fastighetsägare och boende,
            Länsstyrelsen i Örebro, entreprenören och kommunen bidragit till att
            hålla Rockesholm uppkopplat.
          </p>
          <p>
            <strong>Allmän information</strong>
          </p>
          <ul>
            <li>Mastens höjd är 60 meter.</li>
            <li>
              Viktig infrastruktur fanns på plats; fiber, el och ett väl
              tilltaget fundament.
            </li>
            <li>
              Kunder i området förses med en radiolänklösning för 100/100 Mbit/s
              och möjlighet att uppgradera.
            </li>
            <li>
              Installationskostnad för boende och verksamheter om 25 000 kr har
              gett intäkter om 275 000 kr.
            </li>
            <li>
              Ytterligare finansiering har kommit från länsstyrelsen (533 000
              kr), Hällefors kommun (100 000 kr), entreprenören (375 000 kr) och
              behandlingshemmet (58 000 kr).
            </li>
            <li>
              Samhällsmasten förbättrar i dagsläget inte yttäckningen i området.
              Det finns dock utrymme för mobiloperatörer att på sikt etablera
              sig i masten.
            </li>
          </ul>
          <h3>Selaöns samhällsmast – Strängnäs</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1345/selaoen.png"
              alt=""
              data-udi="umb://media/70a8cc8352854af2bd867883d503c4dd"
            />
          </p>
          <p>
            Till sist besöker vi Strängnäs och vackra <strong>Selaön</strong>.
            Boende och företagare på grannön Ringsön har fram tills för ett år
            sedan då samhällsmasten togs i bruk alltid haft dålig mobiltäckning.
            Trots riktantenn har det varit svårt att surfa och jobba hemifrån –
            och omöjligt att strömma video. Strängnäs kommun har ambitiösa mål
            för bredbandsutbyggnad och mobiltäckning och initierade därför ett
            samhällsmast-projekt tillsammans med en mobiloperatör. Precis som i
            tidigare exempel involverades operatörerna som fick beskriva sina
            planer för framtida utbyggnad. Finansiering för att på kommersiell
            grund etablera en mast fanns inte, och kommunen gick därför in med
            ett ekonomiskt stöd som fick vågskålen att tippa över.
          </p>
          <p>
            Samhällsmasten på Selaön ger både bättre yttäckning i området runt
            öarna, och används för att leverera bredband genom "fast trådlöst
            bredband" (även kallat Fixed Wireless Access - FWA). Den har gett
            boende och företagare på två öar där byggnation av fiber inte visat
            sig vara möjligt en alternativ väg till ett snabbt och stabilt
            bredband.
          </p>
          <p>
            <strong>Allmän information</strong>
          </p>
          <ul>
            <li>
              Mastens höjd är 42 meter – man kunde inte bygga en högre mast då
              det finns en flygzon i området.
            </li>
            <li>Installationskostnad för fast trådlöst bredband är 7200 kr.</li>
            <li>
              Datahastigheter om 100/10 Mbit/s garanteras kunder i området. Det
              finns god kapacitet för tillkommande kunder.
            </li>
            <li>
              Mobiloperatörers nyttjande av masten är reglerat genom hyresavtal.
            </li>
            <li>
              Projektet överkom många utmaningar i form av exempelvis oklarheter
              om bygglov, relation med markägare, skälig hyra för inplacering,
              och hur man får till stånd de praktiska förutsättningarna som el
              och annan infrastruktur.
            </li>
          </ul>
          <p>
            Detta var ett nedslag i de exempel som Bredbandsforum kommer
            beskriva i vår kommande <em>Färdplan för måluppfyllelse 2025.</em> I
            en kommande artikel berättar Bredbandsforum om ett antal planerade
            samhällsmaster runt om i landet.
          </p>
        </div>,
      ],
      asideTitle: "Bakgrund",
      asideBody: [
        <div>
          <p>
            Den nationella bredbandsstrategin är tydlig med att ett helt
            uppkopplat Sverige förutsätter att det finns kompletterande sätt att
            ansluta hushåll och företag till snabbt och tillförlitligt bredband.
            Detta för att minimera antalet som inte bara kommer sakna fiber –
            utan riskerar att sakna någon form av höghastighetsbredband
            överhuvudtaget.
          </p>
          <p>
            Nationella, regionala och kommunala målsättningar hänvisar främst
            till teknikutvecklingen inom trådlös bredbandsinfrastruktur och
            satellitkommunikation som en del av lösningen. Men idag finns inga
            indikationer på att marknadsaktörerna på kommersiell grund kommer
            kunna erbjuda höghastighetsbredband via mobilnätet till alla som
            inte är anslutna till fibernätet. Det skulle krävas tusentals nya
            sändarplatser runt om i landet. <br />
            Kan så kallade samhällsmaster vara en del av lösningen?
          </p>
          <p>
            Redan 2015 identifierade Post- och telestyrelsen (PTS)
            samhällsmaster som en möjlig lösning på problem med bristfällig
            mobiltäckning i exempelvis turist- och fritidsområden, runt vägar,
            och för vissa enskilda hushåll och arbetsställen utanför tätorter.
            Samtidigt var myndigheten tydlig med att det kan medföra höga
            kostnader och vara ett komplicerat projekt att få till stånd en
            samhällsmast i många av de områden som kunde vara aktuella – bland
            annat eftersom att det saknas grundläggande infrastruktur. Det är
            inte bara det faktum att kundunderlaget är litet som försvårar för
            operatörerna, det saknas t ex även el och fiber. PTS kartläggning
            visade att så väl offentliga som privata aktörer var intresserade av
            konceptet, men samtidigt avvaktande.
          </p>
          <p>
            Många år senare kan vi nu konstatera att samhällsmaster inte är en
            vanlig lösning på den svenska marknaden. Befintliga och planerade
            samhällsmaster är få, och koncentrerade till södra Sverige. Men
            problemen med bristfällig täckning och kapacitet i de områden PTS
            identifierade 2015 är inte lösta. Under 2022 utreder därför
            Bredbandsforum det aktuella intresset för samhällsmaster runt om i
            Sverige i syfte att kunna bedöma konceptets roll i det framtida
            strategiska arbetet med att främja bredbandsutbyggnad.
          </p>
        </div>,
      ],
    },
  },
  {
    route: "samhaellsmastens-framtid",

    pageData: {
      headline: "Samhällsmastens framtid?",
      createDate: "22 november 2022",
      textBody: [
        <div>
          <p className="ingress">
            Mobilmaster som är helt eller delvis offentligt finansierade kallas
            även för samhällsmaster. I många områden saknas kommersiella
            förutsättningar för operatörer att förbättra mobilnätens täckning
            och kapacitet. Men kanske kan hjälp från offentliga aktörer, i form
            av exempelvis ekonomiska bidrag eller tillgång till mastfundament,
            fiber eller el, leda till att nya mobilmaster etableras i dessa
            områden.
          </p>
          <p>
            Bredbandsforum utreder just nu hur stort intresset för
            samhällsmaster är runt om i landet – och om frågan kan bli en viktig
            del i det framtida strategiska arbetet för att främja
            bredbandsutbyggnad. I denna fortsättning på vår{" "}
            <a
              data-udi="umb://document/e09194edc84243c295b39c3635387616"
              href="/nyheter/bredbandsforum-undersoeker-intresset-foer-samhaellsmaster/"
              title="Bredbandsforum undersöker intresset för samhällsmaster"
            >
              första artikel
            </a>{" "}
            om samhällsmaster berättar vi om några planerade samhällsmaster och
            andra lösningar för en förbättrad mobiltäckning runt om i Sverige.
          </p>
          <h3>Storbrändön – Lule skärgård</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1350/33215587-gamla-farjelagets-smabatshamn-i-lulea.jpg"
              alt=""
              data-udi="umb://media/7254c8cc6d944da7b616f59ca4421315"
            />
          </p>
          <p>
            Luleå kommun och region Norrbotten deltar i projektet Rural ICT
            Testbed – <a href="https://www.fulltäckning.se/">#Fulltäckning</a> –
            som utforskar olika möjligheter för att förbättra mobiltäckning och
            minska den ökande digitala klyftan mellan landsbygder och urbana
            miljöer. I Luleås skärgård har boende och besöksnäringen länge
            upplevt problem med kapacitet och täckning i mobilnätet. Som i många
            andra skärgårdsområden mångdubblas antalet användare på
            sommarhalvåret. Ett hundratal fastboende får då sällskap och tusen
            fritidsboende och tiotusentals båtresenärer. Olika praktiska problem
            uppkommer när exempelvis redarnas betalterminaler som kräver
            uppkoppling inte fungerar. Men det innebär också säkerhetsrisker för
            alla som rör sig i området. Att kunna utnyttja digitaliseringens
            möjligheter även i ytterskärgården är så klart oerhört viktigt för
            kommunen och regionens egna verksamheter. Men man genomförde även en
            enkät bland fast- och fritidsboende som bekräftade att det finns ett
            stort intresse i att kunna beställa mobilabonnemang med högre
            kapacitet.
          </p>
          <p>
            Genom att kontakta mobiloperatörerna har projektet kunnat
            säkerställa att det inte kommer ske någon kommersiell utbyggnad av
            mobilnätet i närtid. Samtidigt är en förutsättning att minst en
            tjänsteleverantör installerar sig i samhällsmasten – en utmaning som
            vi känner igen från andra samhällsmaster som redan tagits i bruk.
            Kommunstyrelsen har beslutat att bevilja finansiering för
            samhällsmasten, och Luleå kommun kommer som det ser ut nu alltså bli
            en av föregångarna när det gäller offentlig-privat samverkan för att
            förbättra mobiltäckning – tillsammans med exempelvis Mariestad,
            Hällefors och Strängnäs.
          </p>
          <p>
            <strong>Allmän information</strong>
          </p>
          <ul>
            <li>
              Mastens höjd är planerad till 30 meter, då det finns en flygzon i
              området är inte 48 eller 72- meters master aktuella.
            </li>
            <li>Masten placeras på öns högsta punkt, ”Knöppeln”.</li>
            <li>
              Fiber finns inte på ön, i stället kommer masten förbindas genom
              radiolänk till en basstation på fastlandet.
            </li>
            <li>Det finns tillgång till el på ön.</li>
            <li>
              Finansiering kommer från Luleå kommun (1&nbsp;190&nbsp;000 kr) och
              #Fulltäckning (510&nbsp;000 kr).
            </li>
            <li>
              Det långsiktiga ägandet och förvaltningen av masten är ännu inte
              utrett – en möjlig lösning är att kommunen äger masten och
              operatörer nyttjar den genom hyresavtal.
            </li>
            <li>I dagsläget är en operatör intresserad av inplacering.</li>
          </ul>
          <p>&nbsp;</p>
          <h3>Västra Götalandsregionens bredbandsstöd</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1351/4493313-varmotiv-vid-vanern.jpg"
              alt=""
              data-udi="umb://media/233647ebf22f4b4c9417cabc41a3e6b4"
            />
          </p>
          <p>
            Redan 2015 identifierades samhällsmaster som ett viktigt verktyg för
            mobilt bredband på landsbygder i Västra Götalandsregionens
            ”Bredbandsstrategi 2.0”. Det är alltså inte konstigt att regionen är
            tidigt ute med ett eget finansiellt stöd för samhällsmaster. En
            kommun kan få stöd för en samhällsmast på en plats där ingen
            kommersiell utbyggnad kommer ske inom 3 –5 år. Masten och all
            infrastruktur ska också vara öppen för alla marknadsaktörer, ingen
            får nekas tillträde och inplacering. Till sist förutsätter stödet en
            god kontakt med marknadens aktörer, i slutändan ska en mobiloperatör
            vilja installera sig i masten och vara intresserad av att förbättra
            sin täckning i det aktuella området.
          </p>
          <p>
            Om allt faller på plats så innebär stödet att kommunen och regionen
            samfinansierar de passiva delarna av samhällmasten med 50 procent
            vardera. Kommunen gör en upphandling för att teckna avtal med en
            aktör som bygger (eller ordnar en entreprenör som bygger), äger och
            driver masten under avtalsperioden. Och till sist även avtala med
            mobiloperatörer om mobiltäckning för området. I den förra artikeln
            visade vi ett exempel från Mariestad på en medfinansierad mast som
            redan var i drift, med det finns även ett antal projekt som nått
            långt i planeringen.
          </p>
          <p>
            <strong>Munkedal</strong>
          </p>
          <ul>
            <li>
              Munkedals kommun har tecknat avtal med ett regionalt och kommunalt
              bolag som ska bygga, äga och driva en samhällsmast under
              avtalsperioden – 15 år.
            </li>
            <li>
              Masten på 72 meter kommer ge bättre täckning till områden runt
              Sannesjön i skogiga Sörbygden.
            </li>
            <li>En lokal fiberförening kommer att förse masten med fiber.</li>
            <li>
              Förhoppningen är att masten kan vara i drift innan år 2022 är
              slut.
            </li>
            <li>
              De 1,9 mkr som master kostar finansieras som det regionala stödet
              bestämt till hälften av kommunen respektive regionen.
            </li>
          </ul>
          <p>
            <strong>Kållandsö &amp; Kålland</strong>
          </p>
          <ul>
            <li>
              I Lidköpings kommun finns stora täckningsproblem runt Kålland,
              Kållandsö och den omkringliggande skärgården.
            </li>
            <li>
              En förstudie har visat att det saknas utbyggnadsplaner på
              kommersiell grund och att två master skulle kunna lösa
              täckningsproblemen som både boende och företag upplever.
            </li>
            <li>
              Skaraborgs flygflottilj F7 ligger relativt nära och därmed finns
              det begränsningar för att bygga allt som skulle kunna utgöra ett
              flyghinder.
            </li>
            <li>
              Det man undersöker är i stället möjligheten att sätta upp två
              mindre master, endast 20 meter höga.
            </li>
            <li>
              Går projektet i lås hoppas man kunna få masterna i drift under
              2023.
            </li>
          </ul>
          <p>
            <strong>Mark &amp; Vara</strong>
          </p>
          <ul>
            <li>
              I både Marks och Vara kommun har förstudier lämnats inför ett
              politiskt beslut om att ansöka om stöd för att få till stånd
              samhällsmaster.
            </li>
            <li>
              Tumleberg i Vara kommun ligger inom samma flyghinderområde som
              Kålland och även där kan det bli tal om en mer korthuggen mast.
            </li>
          </ul>
          <p>
            Sammanfattningsvis är det tydligt att det långsiktiga arbetet med
            att främja samhällsmaster har gett resultat i Västra Götaland.
            &nbsp;
          </p>
          <p>&nbsp;</p>
          <h3>Karlstads kommun</h3>
          <p>
            <img
              id="__mcenew"
              src="/media/1352/kronoparkens-vattentorn.png"
              alt=""
              data-udi="umb://media/aa0bb5b1370b4e5bbea79470cdd56322"
            />
          </p>
          <p>
            På andra håll kan det i avsaknad av ett finansiellt stöd å andra
            sidan finnas mycket starka incitament för exempelvis en kommun att
            undersöka verktyget samhällsmast. Som på många håll i landet finns i
            Karlstad finns sedan många år mobiloperatörerna inplacerade i
            kommunens vattentorn. Vattentorn är oftast placerade på en hög plats
            och att placera antenner där gör att mobiloperatörerna slipper bygga
            en mast på platsen eller i närheten, och kommunen kan se t ex
            inplaceringsavgift och hyra som en extra inkomst för att täcka
            drifts- och underhållskostnader. För att inte tala om att det är
            viktigt för både kommunens egna verksamheter som medborgare och
            företag att mobilnäten fungerar så bra som möjligt.
          </p>
          <p>
            Det finns dock utmaningar med lösningen. Då en del vattentorn anses
            vara skyddsobjekt kan det finnas höga krav på vem som får tillträde,
            underhåll och säkerhet för att hindra inbrott och sabotage eller
            annat som kan påverka dricksvattnet. Det finns många direktiv, lagar
            och föreskrifter som vår dricksvattenproduktion och dess
            anläggningar lyder under, och inom det tolkningsutrymme som dessa
            lämnar så kommer vissa fram till att det är lämpligt att tillåta
            inplacering av antenner och teknikbodar och annat vid vattentorn –
            andra inte.
          </p>
          <p>
            Varför denna långa utläggning om vattentorn? Jo – i Karlstad har nu
            kommunen bestämt att det inte är lämpligt att externa parter har
            tillträde till kommunens vattentorn framöver. Mobiloperatörernas
            avtal ska därför successivt sägas upp. Kommunen tar dock frågan om
            mobilnätens täckning på allvar och har bestämt att ge förvaltningen
            i uppdrag att utreda och projektera för upp till fem nya mobilmaster
            för att ersätta den förlust i täckning och kapacitet som åtgärden
            kan medföra. Och om fler kommuner väljer att skärpa säkerheten kring
            sina dricksvattenanläggningar och tolka lagar och föreskrifter mer
            restriktivt än innan är det föredömligt att som i Karlstad agera
            proaktivt för att ändå bibehålla mobilnätens täckning och kapacitet.
          </p>
          <p>
            Detta var ett nedslag i de exempel som Bredbandsforum kommer
            beskriva i vår kommande <em>Färdplan för måluppfyllelse 2025.</em> I
            slutrapporten kommer ni kunna ta del av Bredbandsforums utredning
            och rekommendationer kring samhällsmaster i det framtida strategiska
            arbetet med bredbandsutbyggnad i hela Sverige.
          </p>
        </div>,
      ],
      asideTitle: "Bakgrund",
      asideBody: [
        <div>
          <p>
            Den nationella bredbandsstrategin är tydlig med att ett helt
            uppkopplat Sverige förutsätter att det finns kompletterande sätt att
            ansluta hushåll och företag till snabbt och tillförlitligt bredband.
            Detta för att minimera antalet som inte bara kommer sakna fiber –
            utan riskerar att sakna någon form av höghastighetsbredband
            överhuvudtaget.
          </p>
          <p>
            Nationella, regionala och kommunala målsättningar hänvisar främst
            till teknikutvecklingen inom trådlös bredbandsinfrastruktur och
            satellitkommunikation som en del av lösningen. Men idag finns inga
            indikationer på att marknadsaktörerna på kommersiell grund kommer
            kunna erbjuda höghastighetsbredband via mobilnätet till alla som
            inte är anslutna till fibernätet. Det skulle krävas tusentals nya
            sändarplatser runt om i landet. <br />
            Kan så kallade samhällsmaster vara en del av lösningen?
          </p>
          <p>
            Redan 2015 identifierade Post- och telestyrelsen (PTS)
            samhällsmaster som en möjlig lösning på problem med bristfällig
            mobiltäckning i exempelvis turist- och fritidsområden, runt vägar,
            och för vissa enskilda hushåll och arbetsställen utanför tätorter.
            Samtidigt var myndigheten tydlig med att det kan medföra höga
            kostnader och vara ett komplicerat projekt att få till stånd en
            samhällsmast i många av de områden som kunde vara aktuella – bland
            annat eftersom att det saknas grundläggande infrastruktur. Det är
            inte bara det faktum att kundunderlaget är litet som försvårar för
            operatörerna, det saknas t ex även el och fiber. PTS kartläggning
            visade att så väl offentliga som privata aktörer var intresserade av
            konceptet, men samtidigt avvaktande.
          </p>
          <p>
            Många år senare kan vi nu konstatera att samhällsmaster inte är en
            vanlig lösning på den svenska marknaden. Befintliga och planerade
            samhällsmaster är få, och koncentrerade till södra Sverige. Men
            problemen med bristfällig täckning och kapacitet i de områden PTS
            identifierade 2015 är inte lösta. Under 2022 utreder därför
            Bredbandsforum det aktuella intresset för samhällsmaster runt om i
            Sverige i syfte att kunna bedöma konceptets roll i det framtida
            strategiska arbetet med att främja bredbandsutbyggnad.
          </p>
        </div>,
      ],
    },
  },
  {
    route:
      "bredbandsutbyggnad-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning",

    pageData: {
      headline:
        "Bredbandsutbyggnad och tillståndsgivande statliga myndigheter – en kartläggning",
      createDate: "13 december 2022",
      textBody: [
        <div>
          <p className="ingress">
            Marknadsaktörer behöver ofta söka olika typer av tillstånd från
            myndigheter på lokal, regional, och nationell nivå. Hur ärenden
            hanteras och hur lång tid handläggningen tar kan ha stor påverkan på
            bredbandsutbyggnaden. I en kartläggning visar Bredbandsforums kansli
            hur marknadsaktörerna i dagsläget upplever olika myndigheters
            hantering av ledningsärenden.
          </p>
          <p>
            Bredbandsforum arbetar just nu med att ta fram en{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbete"
            >
              färdplan för måluppfyllnad 2025
            </a>
            . Färdplanen kommer att sammanfatta de kvarstående utmaningarna och
            riskfaktorerna som kan påverka utbyggnaden av
            bredbandsinfrastruktur. Ett område som aktörerna i Bredbandsforum
            anser behöver ett förnyat fokus är hur tillståndsgivande myndigheter
            hanterar tillståndsgivning. Bredbandsforums kansli har därför
            kartlagt hur marknadsaktörerna i dagsläget upplever exempelvis
            statliga myndigheters handläggningstider, bedömningar i
            ledningsärenden, kommunikation och transparens. De myndigheter som
            oftast uppkommer i intervjuer och diskussioner är Trafikverket,
            Lantmäteriet, Länsstyrelserna och Försvarsmakten. Men kartläggningen
            innehåller även beskrivningar av en mängd olika myndigheter som på
            olika sätt kan bli inblandade i handläggningen av mer komplicerade
            ledningsärenden. Ladda ned dokumentet här:{" "}
            <a
              data-udi="umb://media/1a1a6675f79e4af3ab4ee7a0315b7cc9"
              href="/media/1355/bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
              title="Bredband och tillståndsgivande statliga myndigheter - en kartläggning.pdf"
            >
              Bredbandsutbyggnad och tillståndsgivande statliga myndigheter – en
              kartläggning
            </a>
          </p>
          <p>
            Enligt kartläggningen anser majoriteten av lokala och nationella
            aktörer på den svenska bredbandsmarknaden att tillståndsgivande
            myndigheters hantering av bredbandsärenden fortsatt är en
            strategiskt viktig fråga. Aktörerna upplever att de flesta
            myndigheters handläggning och processer utvecklats i en positiv
            riktning de senaste åren, men att det fortfarande finns
            förbättringspotential. Problem som uppkommer till följd av långa
            handläggningstider är det vanligast förekommande samtalsämnet i
            intervjuer och diskussioner. Men många nationella aktörer upplever
            även begränsningar på grund av inkonsekventa bedömningar och
            efterfrågar mer samordning både mellan och inom tillståndsgivande
            statliga myndigheter.
          </p>
          <p>
            Bredbandsforum lämnar inte några rekommendationer eller förslag till
            fortsatt arbete i kartläggningen utan återkommer till frågan i
            slutrapporten <em>färdplan för måluppfyllnad 2025</em>.
          </p>
          <p>Myndigheter som diskuteras i kartläggningen:</p>
          <p>Tillståndsgivande statliga myndigheter:</p>
          <ul>
            <li>Trafikverket</li>
            <li>Lantmäteriet</li>
            <li>Länsstyrelserna</li>
            <li>Försvarsmakten</li>
          </ul>
          <p>Övriga myndigheter:</p>
          <ul>
            <li>Sveriges kommuner</li>
            <li>Sveriges regioner</li>
            <li>Domstolsväsendet</li>
            <li>Statens geologiska undersökningar</li>
            <li>Riksarkivet</li>
            <li>Riksantikvarieämbetet</li>
            <li>Post- och telestyrelsen</li>
            <li>Tillväxtverket</li>
            <li>Jordbruksverket</li>
            <li>Naturvårdsverket</li>
            <li>Boverket</li>
            <li>Regeringskansliet</li>
            <li>Statliga bolag med markinnehav</li>
          </ul>
          <p>Ladda ned dokumentet här:</p>
          <p>
            <a
              data-udi="umb://media/1a1a6675f79e4af3ab4ee7a0315b7cc9"
              href="/media/1355/bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
              title="Bredband och tillståndsgivande statliga myndigheter - en kartläggning.pdf"
            >
              Bredbandsutbyggnad och tillståndsgivande statliga myndigheter – en
              kartläggning
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned kartläggningen",
      asideBody: [
        <p>
          <a
            data-udi="umb://media/3eb85e5e05974a508570eceba0c4981c"
            href="/media/1356/bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
            title="Bredband och tillståndsgivande statliga myndigheter - en kartläggning.pdf"
          >
            Bredbandsutbyggnad och tillståndsgivande statliga myndigheter – en
            kartläggning (PDF)
          </a>
        </p>,
      ],
    },
  },
  {
    route: "faerdplan-foer-maaluppfyllnad-2025",

    pageData: {
      headline: "Färdplan för måluppfyllnad 2025",
      createDate: "31 januari 2023",
      textBody: [
        <div>
          <p className="ingress">
            Det är nu över sex år sedan den dåvarande regeringen presenterade en
            ny nationell{" "}
            <a href="/sveriges-bredbandsstrategi/">bredbandsstrategi</a> för ett
            helt uppkopplat Sverige. Ambitionen i strategin är att hela Sverige
            bör ha tillgång till snabbt bredband år 2025. Den övergripande
            målsättningen kommer bli svår att uppnå, då de minst kommersiellt
            intressanta områdena kvarstår att ansluta, i huvudsak på våra
            landsbygder.
          </p>

          <p>
            Många lyfter behovet av nya visioner för bredbandsinfrastrukturen nu
            när vår nuvarande nationella bredbandsstrategi närmar sig sitt
            slutdatum. Samhällsutvecklingen medför att vi behöver ställa allt
            högre krav på bredbandsinfrastrukturens tillgänglighet och funktion
            i hela Sverige. Det förändrade säkerhetsläget, kriget i Ukraina, och
            den pandemi vi genomlevt belyser behovet av att vi måste kunna lita
            på bredbandsinfrastrukturen även under påfrestningar, och samhällets
            förväntningar på näten som helhet behöver klargöras. Tillgång till
            högpresterande digital mobilitet är en allt mer kritisk fråga för
            samtliga sektorer av Sveriges näringsliv.&nbsp;
          </p>
          <p>
            Det är dock viktigt att alla aktörer som kan påverka
            bredbandsutbyggnaden tänker på att mycket arbete återstår innan
            målsättningarna i dagens strategi är uppnådda. Det finns fortfarande
            viktiga områden där olika aktörer kan göra insatser för att främja
            en snabb och effektiv bredbandsutbyggnad. Under år 2022 har därför
            Bredbandsforum koncentrerat verksamheten på ett antal viktiga
            utmaningar som behöver ett förnyat fokus. Arbetet har resulterat i
            rapporten{" "}
            <a
              data-udi="umb://media/49b069c82359480481d2946c411e93da"
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              <em>Färdplan för måluppfyllnad 2025</em>
            </a>
            , som sammanfattar de kvarstående utmaningarna och riskfaktorerna
            för utbyggnaden, och som även innehåller ett antal rekommendationer
            som syftar till att främja en fortsatt utbyggnad av
            bredbandsinfrastruktur i hela Sverige. Rapporten behandlar
            exempelvis ämnen som hinder för tillträde till mark, otillräcklig
            tillgång till stödmedel, och otillräcklig samverkan- och
            rollfördelning mellan aktörer.
          </p>
          <p>
            Rekommendationerna presenteras i prioritetsordning, och är främst
            inriktade på att belysa regeringens möjligheter att påverka genom
            att formulera uppdrag och bistå med finansiering. De flesta av dessa
            hinder och rekommendationer har beskrivits många gånger tidigare i
            olika sammanhang. Ytterligare diskussion eller dialog om frågorna
            inom fokusområdet bedömer Bredbandsforum nu ha små möjligheter att
            bidra med ny kunskap eller nya perspektiv. Bredbandsforum kommer
            dock på andra områden fortsätta arbetet med att genom dialog och
            samverkan främja bredbandsinfrastrukturens utbyggnad, utveckling,
            och upprätthållande.
          </p>
          <p>
            <a
              data-udi="umb://media/49b069c82359480481d2946c411e93da"
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              Klicka här för att ladda ned rapporten{" "}
              <em>Färdplan för måluppfyllnad 2025</em> inklusive bilagor
            </a>
            .
          </p>
          <p>
            <img
              id="__mcenew"
              src="/media/1364/rekommendationer-foer-en-fortsatt-bredbandsutbyggnad-i-hela-sverige.png"
              alt=""
              data-udi="umb://media/19f0c16d371d408ab7153467be4c5a6d"
            />
          </p>
          <p>
            <a
              data-udi="umb://media/19f0c16d371d408ab7153467be4c5a6d"
              href="/media/1364/rekommendationer-foer-en-fortsatt-bredbandsutbyggnad-i-hela-sverige.png"
              title="Rekommendationer för en fortsatt bredbandsutbyggnad i hela Sverige.png"
            >
              Ladda ned schematisk översikt av rekommendationerna
            </a>
          </p>
        </div>,
      ],
      asideTitle: "Ladda ned rapporten",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/49b069c82359480481d2946c411e93da"
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              <strong>
                Färdplan för måluppfyllnad 2025 inklusive bilagor (PDF)
              </strong>
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/e501efcd70094cfba173fc3dc2a75b2c"
              href="/media/1366/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum.pdf"
            >
              Färdplan för måluppfyllnad 2025 exklusive bilagor (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/4b055b1806184c8098acbf50e53d58aa"
              href="/media/1363/bakgrundsbeskrivning-av-prioriterade-omraaden-bredbandsforum-2022-12-22.pdf"
              title="Bakgrundsbeskrivning av prioriterade områden – Bredbandsforum 2022-12-22.pdf"
            >
              Bakgrundsbeskrivning av prioriterade områden (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/11e3331e87d0435fac6ca5f12c1f5c24"
              href="/media/1362/kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
              title="kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
            >
              Kunskapsunderlag om de grundläggande 98 procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/74b9a285ecb647578c3e97ab34363f3f"
              href="/media/1361/kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
              title="kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
            >
              Kunskapsunderlag om de avgörande 2 procenten (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/929c8668983540babc3f38e57c34e3d5"
              href="/media/1359/bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
              title="bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
            >
              <span>
                Bredbandsutbyggnad och tillståndsgivande statliga myndigheter –
                en kartläggning
              </span>{" "}
              (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/b753fccd20294cafbbdf85639beacff5"
              href="/media/1358/kartlaeggning-av-intresset-foer-samhaellsmaster.pdf"
              title="Kartläggning av intresset för samhällsmaster.pdf"
            >
              <span>Kartläggning av intresset för samhällsmaster</span> (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/2502fad096964074bcd1022b2ded9e87"
              href="/media/1360/kunskapsunderlag-om-hushaallens-betalningsfoermaaga-foer-bredbandsinfrastruktur.pdf"
              title="Kunskapsunderlag om hushållens betalningsförmåga för bredbandsinfrastruktur.pdf"
            >
              <span>
                Kunskapsunderlag om hushållens betalningsförmåga för
                bredbandsinfrastruktur&nbsp;
              </span>{" "}
              (PDF)
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route:
      "bredbandsforums-samverkansprocess-foer-etablering-av-saendarplatser-i-omraaden-med-utmaningar",

    pageData: {
      headline:
        "Bredbandsforums samverkansprocess för etablering av sändarplatser i områden med utmaningar",
      createDate: "22 september 2023",
      textBody: [
        <div>
          <p className="ingress">
            Bredbandsforum har tillsammans med ett antal nyckelaktörer tagit
            fram en processbeskrivning för ökad samverkan kring etablering av
            nya sändarplatser i områden med utmaningar. Processen redogör för
            hur en offentlig aktör på ett systematiskt sätt kan kan identifiera
            områden med utmaningar och initiera en samverkan med mobiloperatörer
            i syfte att finna lösningar, alternativt definitivt konstatera ett
            marknadsmisslyckande. Där en marknadsdriven utbyggnad inte är möjlig
            har även Bredbandsforum tagit fram en grundläggande checklista med
            förutsättningar för en offentligt hel- eller delfinansierad
            sändarplats.
          </p>
          <p>
            Bredbandsforum har tillsammans med ett antal nyckelaktörer tagit
            fram en{" "}
            <a
              data-udi="umb://media/d3eafd3ad5f2440aa7105af2eb03f23f"
              href="/media/1378/samverkansprocess-foer-etablering-av-saendraplatser-bredbandsforum.pdf"
              title="Samverkansprocess för etablering av sändraplatser – Bredbandsforum.pdf"
            >
              samverkansprocess
            </a>{" "}
            som beskriver hur en ökad dialog och erfarenhetsutbyte på sikt kan
            leda till etablering av nya sändarplatser i områden med utmaningar.
            Processen redogör för hur en offentlig aktör på ett systematiskt
            sätt kan kan identifiera områden med utmaningar och initiera en
            samverkan med mobiloperatörer i syfte att finna lösningar. Ett
            pilotprojekt som genomförts i Region Värmland visar hur ett
            ömsesidigt engagemang och kunskapsutbyte kan leda till att områden
            identifieras där det – i den bästa av världar – finns tidigare
            oidentifierade möjligheter att på marknadsmässig grund förstärka
            tillgången till mobilt bredband. I de fall det ändå inte finns några
            som helst möjligheter att förstärka tillgången till mobilt bredband
            på marknadsmässig grund har man i stället genom processen
            identifierat områden där ett marknadsmisslyckande definitivt kan
            konstateras. Även denna slutsats kan ha ett stort värde i framtida
            diskussioner om exempelvis offentlig medfinansiering eller
            stödfinansiering för att förstärka tillgången till mobilt bredband.
          </p>
          <p>
            Bakgrunden till arbetet är att det nationella målet för tillgång
            till mobilt bredband fastställer att utbyggnaden i första hand ska
            vara marknadsdriven. Bredbandsutbyggnad ska i huvudsak ske på
            kommersiell grund genom marknadsaktörers och slutanvändares
            investeringar. Många regionala och lokala bredbandsstrategier har
            formulerat egna mål baserat resonemangen i det nationella. Men
            exempelvis Post- och telestyrelsen och även Bredbandsforum har i
            olika sammanhang konstaterat att det på många platser saknas
            sändarplatser inom ett lämpligt avstånd för att möjliggöra täckning
            i kapacitetsbanden, och att det av allt att döma saknas
            förutsättningar för att på helt marknadsmässig grund förstärka
            tillgången i dessa områden. Bredbandsforums arbetsgrupp
            ”Infrastruktur för mobilt bredband 2023” föreslog bland annat att
            metoder skulle tas fram för hur koordinering och finansiering av
            sändarplatser i områden med bristfällig täckning kan förbättras.
          </p>
          <p>
            Bredbandsforums kansli konstaterade i rapporten "
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Färdplan för måluppfyllnad 2025"
            >
              <em>Färdplan för måluppfyllnad 2025</em>
            </a>
            " att Bredbandsforum bör arbeta vidare med att stödja offentliga
            aktörers strategiska hantering av denna fråga, genom att exempelvis
            samla kunskap om och beskriva möjliga processer för upphandling,
            finansiering, drift och förvaltning av nya sändarplatser på
            landsbygder. Bredbandsforum har{" "}
            <a
              data-udi="umb://document/64b07cb00dde427294d8504af3026bed"
              href="/nyheter/samhaellsmastens-framtid/"
              title="Samhällsmastens framtid?"
            >
              tidigare berättat
            </a>{" "}
            att det pågår ett antal lokala projekt för att med hjälp av
            offentlig finansiering och samordning åstadkomma en förbättrad
            mobiltäckning i områden med bristfällig täckning. Det kan handla om
            att erbjuda stöd eller medfinansiering, upplåta mark eller
            inplacering, eller att etablera och drifta en mobilmast i en
            offentlig aktörs regi. Utvecklingen är lovande men det är oklart
            till vilken grad offentliga aktörer över hela landet kommer ha
            resurser eller kunskap att initiera liknande projekt kommande år.
          </p>
          <p>
            Oavsett hur man på lokal, regional eller nationell nivå väljer att
            adressera den grundläggande utmaningen att den ekonomiska kalkylen
            är svår att få ihop där marknadsunderlaget är litet så är ett
            viktigt steg i arbetet att definitivt konstatera var det finns
            respektive saknas teoretiska möjligheter att i dagsläget förstärka
            tillgången. En aktör som är intresserad av att arbeta mer konkret
            med att identifiera områden med utmaningar och öka förståelsen för
            vilka områden som är mest lämpliga att ingå i eventuella
            diskussioner om offentliga insatser kan med fördel använda
            Bredbandsforums samverkansprocess som inspiration och vägledning i
            arbetet.
          </p>
          <p>
            Ta även del av slutrapporten från{" "}
            <a
              data-udi="umb://media/eb09f5f61f104e06af390bc317c42e22"
              href="/media/1379/slutrapport-pilotprojekt-mobiltaeckning-region-vaermland.pdf"
              title="Slutrapport pilotprojekt mobiltäckning Region Värmland.pdf"
            >
              pilotprojektet
            </a>
            , där Region Värmland på en detaljerad nivå beskriver exempelvis hur
            en offentlig aktör på olika sätt kan identifiera områden med
            utmaningar:
          </p>
          <p>
            <em>
              <img
                id="__mcenew"
                src="/media/1380/exempel.png"
                alt=""
                data-udi="umb://media/634a508844164dd6af3b0821498a4e87"
              />
            </em>
          </p>
        </div>,
      ],
      asideTitle: "Dokument",
      asideBody: [
        <div>
          <p>
            <a
              data-udi="umb://media/d3eafd3ad5f2440aa7105af2eb03f23f"
              href="/media/1378/samverkansprocess-foer-etablering-av-saendraplatser-bredbandsforum.pdf"
              title="Samverkansprocess för etablering av sändraplatser – Bredbandsforum.pdf"
            >
              Samverkansprocess för etablering av sändarplatser (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://media/eb09f5f61f104e06af390bc317c42e22"
              href="/media/1379/slutrapport-pilotprojekt-mobiltaeckning-region-vaermland.pdf"
              title="Slutrapport pilotprojekt mobiltäckning Region Värmland.pdf"
            >
              Slutrapport från pilotprojekt i Region Värmland (PDF)
            </a>
          </p>
          <p>
            <a
              data-udi="umb://document/41c4cb777ea642549ca051fd42d036ad"
              href="/avslutade-arbetsgrupper/"
              title="Avslutade arbetsgrupper"
            >
              Slutrapport från Bredbandsforums arbetsgrupp Infrastruktur för
              mobilt bredband 2023
            </a>
          </p>
        </div>,
      ],
    },
  },
  {
    route: "Digital-Mobilitet-for-Framtiden",

    pageData: {
      headline: "Digital Mobilitet för Framtiden",
      createDate: "6 februari 2024",
      textBody: [
        <p className="ingress">
          Bredbandsforum presenterar i en ny rapport framgångsfaktorer för att
          skapa bättre tillgång till mobilt bredband i områden där det idag
          upplevs som bristfälligt samt effektivisera och förenkla övergången
          till nästa generations mobilnätverk – 5G. Genom samverkan och dialog
          har forumet identifierat utmaningar och diskuterat lösningar för vårt
          gemensamma mål om att skapa en digital mobilitet för framtiden i hela
          Sverige.
        </p>,
      ],
      asideTitle: "Dokument",
      asideBody: [],
    },
  },
  {
    route: "hallbarhet-och-efteranslutningar",

    pageData: {
      headline: "Bredbandsforum prioriterar hållbarhet och efteranslutningar",
      createDate: "11 juni 2024",
      textBody: [
        <p className="ingress">
          Bredbandsforums styrgrupp har beslutat om nya fokusområden för
          2024–2025: Bredband för hållbarhet och Efteranslutningar för
          konnektivitet. Dessa fokusområden syftar till att skapa
          kunskapsunderlag för att generera en fortsatt bredbandsutbyggnad.{" "}
        </p>,
      ],
      asideTitle: "Dokument",
      asideBody: [],
    },
  },
];

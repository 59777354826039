import DOMPurify from "dompurify";
import Card from "react-bootstrap/Card";
import "./style.css";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import React from "react";
import ReactDOMServer from "react-dom/server";

export const News = ({ createDate, title, description, link }: any) => {
  const elementString = ReactDOMServer.renderToString(description[0]);

  const tempElement = document.createElement("div");
  tempElement.innerHTML = elementString;
  const plainText = tempElement.textContent || tempElement.innerText;
  const preview = plainText.substring(0, 300) + "...";

  return (
    <>
      <div className="openPosition">
        <Card>
          <Card.Body>
            <Card.Title>
              <h2>
                <a href={`nyheter/${link}`}>{title}</a>
              </h2>
            </Card.Title>
            <Card.Text>
              {createDate && (
                <div className="newslistpublisheddate">{createDate}</div>
              )}
              {preview}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default News;

import Button from "react-bootstrap/Button";

import Card from "react-bootstrap/Card";
import "./style.css";

export const CardComponent = ({
  title,
  description,
  image,
  link,
  linkFunc,
}: any) => {
  return (
    <>
      <Card>
        <Card.Img
          className="img-startpage"
          variant="top"
          src={image}
          alt={title}
        />
        <Card.Body>
          <Card.Title>
            <h5>
              <a href={link}>{title}</a>
            </h5>
          </Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export const CardTextBoxComponent = ({
  title,
  linkText,
  link,
  cardBody = [],
}: any) => {
  return (
    <>
      <Card className="CardTextBoxComponent">
        <Card.Title>
          <h5>{title}</h5>
        </Card.Title>
        <Card.Body>
          {console.log(cardBody)}
          {cardBody &&
            cardBody.map((data: any) => {
              return (
                <div className="element">
                  {data.headline}
                  {data.textbody}
                </div>
              );
            })}

          <a href={link} className="linktext">
            {linkText}
          </a>
        </Card.Body>
      </Card>
    </>
  );
};

export const CardNewsBoxComponent = ({
  title,
  linkText,
  cardBody = [],
}: any) => {
  return (
    <>
      <Card className="CardTextBoxComponent">
        <Card.Title>
          <h5>{title}</h5>
        </Card.Title>
        <Card.Body>
          {console.log(cardBody)}
          {cardBody &&
            cardBody.map((data: any) => {
              return <div className="element">{data}</div>;
            })}

          <a className="linktext">{linkText}</a>
        </Card.Body>
      </Card>
    </>
  );
};

export const CardHeroComponent = ({
  title,
  description,
  image,
  link,
  linkFunc,
}: any) => {
  return (
    <>
      <Card onClick={() => linkFunc(link)}>
        <picture>
          <source
            media="(min-width: 2048px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=2240&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1920px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=2048&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1680px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1920&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1400px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1680&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1360px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1400&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1280px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1360&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 1024px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1280&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 800px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=1024&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 480px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=800&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <source
            media="(min-width: 0px)"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=480&amp;heightratio=0.23&amp;upskale=false"
            }
          />
          <img
            className="img-responsive"
            srcSet={
              image +
              "?anchor=center&amp;mode=crop&amp;width=2240&amp;heightratio=0.23&amp;upskale=false"
            }
            alt=""
          />
        </picture>

        <div className="container">
          <div className="row flex-wrap align-items-end">
            <div id="hero-content" className="ml-auto">
              <div id="hero">
                <div className="col hero-section-1">
                  <h1>Välkommen till Bredbandsforum</h1>
                  <p>
                    Bredbandsforum är en del av regeringens bredbandsstrategi
                    för ett helt uppkopplat Sverige. Tillsammans med företag,
                    myndigheter och intresseorganisationer hittar vi lösningar
                    för att alla ska få tillgång till snabbt och tillförlitligt
                    bredband.{" "}
                    <a href="/digital-mobilitet-och-5g/">
                      Läs mer om Bredbandsforums pågående arbete
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default CardComponent;

import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import CardComponent from "../../../components/Card/Card";
import { CardTextBoxComponent } from "../../../components/Card/Card";
import { Container } from "react-bootstrap";
import * as Data from "../../../data";
import { ReactNode } from "react";

function Page() {
  const cardData: { headline: ReactNode; textbody: ReactNode }[] = [];

  Data.newslist
    .slice(-3)
    .reverse()
    .map((element) => {
      console.log(element);
      cardData.push({
        headline: <h6>{element.pageData.createDate}</h6>,
        textbody: (
          <a href={`/nyheter/${element.route}`}>{element.pageData.headline}</a>
        ),
      });
    });

  return (
    <>
      <div className="startPageTopContainer">
        <img
          src="/media/1095/12605833-sommar-i-sverige.jpg"
          alt="Sommar i Sverige"
        />
        <div className="welcomeBox">
          <div>
            <h1>Välkommen till Bredbandsforum</h1>
            <p>
              Bredbandsforum är en del av regeringens bredbandsstrategi för ett
              helt uppkopplat Sverige. Tillsammans med företag, myndigheter och
              intresseorganisationer hittar vi lösningar för att alla ska få
              tillgång till snabbt och tillförlitligt bredband.
            </p>
          </div>

          <div className="readMore">
            <a href="/nyheter/hallbarhet-och-efteranslutningar/">
              Läs mer om Bredbandsforums pågående arbete
            </a>
          </div>
        </div>
      </div>

      <Container className="d-flex cards-startpage justify-content-center m-4 mx-auto">
        <CardComponent
          className="card-startpage"
          image={
            "/media/1400/Framgångsfaktorer Digital Mobilitet för Framtiden.png"
          }
          title={"Digital Mobilitet för Framtiden"}
          description={
            "Ladda ned Bredbandsforums slutrapport Digital Mobilitet för Framtiden"
          }
          link={"/nyheter/Digital-Mobilitet-for-Framtiden/"}
        />
        <CardComponent
          className="card-startpage"
          image={"/media/1400/hallbarhet.jpg"}
          title={"Bredbandsforum prioriterar hållbarhet och efteranslutningar"}
          description={
            "Bredbandsforums styrgrupp har beslutat om nya fokusområden för 2024–2025: Bredband för hållbarhet och Efteranslutningar för konnektivitet."
          }
          link={"/nyheter/hallbarhet-och-efteranslutningar/"}
        />
        <CardTextBoxComponent
          className="card-startpage"
          title={"Aktuellt"}
          linkText={"Se alla nyheter"}
          link={"/nyheter"}
          cardBody={cardData}
        />
      </Container>
    </>
  );
}
export default Page;

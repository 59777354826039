import { Col, Container } from "react-bootstrap";
import "./style.css";
import { CardNewsBoxComponent } from "../../../components/Card/Card";
import * as Data from "../../../data";

export function Templet2({ router }: any) {
  const page = Data.newslist.find((obj) => obj.route === router);
  return (
    <div className="templet2">
      <Container className="row mx-auto content">
        <Col className="main col-md-8">
          <h1>{page?.pageData.headline}</h1>
          <p className="createDate">{page?.pageData.createDate}</p>

          {page?.pageData.textBody}
        </Col>

        {page?.pageData.asideTitle && (
          <Col className="asaid col-md-4">
            <CardNewsBoxComponent
              title={page?.pageData.asideTitle}
              cardBody={page?.pageData.asideBody}
            />
          </Col>
        )}
      </Container>
    </div>
  );
}

import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Lättläst"
        topParagraph="Bredbandsforum är regeringens mötesplats för företag, myndigheter, och organisationer som arbetar för att alla ska få snabbt och tillförlitligt bredband."
        images="/media/1063/684484-using-a-laptop-on-campus.jpg"
        textbody={[
          <h3>Det här är Bredbandsforum</h3>,
          <p>
            Bredbandsforum arbetar på uppdrag av infrastrukturdepartementet.
          </p>,
          <p>
            Med bredband kan du koppla upp din dator, mobiltelefon, eller
            läsplatta till internet på ett snabbt sätt. Det är viktigt att alla
            i hela Sverige får en snabb och säker bredbandsuppkoppling.
          </p>,
          <p>Om du vill skriva till Bredbandsforum är adressen:</p>,
          <ul>
            <li>Bredbandsforum</li>
            <li>Box 6101</li>
            <li>102 32 Stockholm</li>
          </ul>,
          <p>
            Om du vill skicka e-post är adressen:{" "}
            <a href="mailto:kontakt@bredbandsforum.se">
              kontakt@bredbandsforum.se
            </a>
          </p>,
          <p>Om du vill besöka Bredbandsforum är adressen:</p>,
          <p>
            Bredbandsforum, Post- och telestyrelsen, Hälsingegatan 38,
            Stockholm.
          </p>,
          <p>Bredbandsforums telefonnummer är 08-678 55 00.</p>,
        ]}
        cardtitle="Mer information"
        carddata={[
          {
            headline: (
              <a href="https://www.pts.se/internet-och-telefoni/bredband/">
                Lättläst information om bredband på Post- och telestyrelsens
                webbplats »
              </a>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

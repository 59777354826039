import { Templet1 } from "../templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Bredbandsforums kansli"
        topParagraph="Bredbandsforums dagliga verksamhet drivs av ett kansli."
        images="/media/1110/5383484-landsbygdens-vag.jpg"
        textbody={[
          <h3>Kansliets uppdrag</h3>,
          <p>
            Bredbandsforums kansli ansvarar för det löpande arbetet i
            Bredbandsforum och samarbetar med de{" "}
            <a href="/bredbandskoordinatorer/" title="Bredbandskoordinatorer">
              regionala bredbandskoordinatorerna
            </a>
            .
          </p>,
          <p>
            I arbetet ingår att driva{" "}
            <a
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              arbetsgrupperna
            </a>{" "}
            och stödja dess ordförande. Det ingår vidare att stödja
            Regeringskansliet (Finansdepartementet) i arbetet med
            Bredbandsforums styrgrupp, genom att producera underlag, föreslå nya
            arbetsgrupper och andra aktiviteter, samt i övrigt främja
            bredbandsstrategins genomförande. Kansliet ska även verka för att
            öka kännedomen om{" "}
            <a href="/sveriges-bredbandsstrategi/" title="Bredbandsstrategin">
              den nationella bredbandsstrategin
            </a>{" "}
            och om Bredbandsforums arbete och resultat.
          </p>,
          <p>
            Post- och telestyrelsen (PTS) har regeringens uppdrag att ansvara
            för kansliet.
          </p>,
          <p>På kansliet jobbar:</p>,
          <ul>
            <li>Åsa Möller, kanslichef</li>
            <li>Christian Höglund</li>
            <li>Pernilla Rydmark</li>
            <li>Daniel Kawasaki</li>
          </ul>,
        ]}
        cardtitle="Kontakta Bredbandsforums kansli"
        carddata={[
          {
            headline: <h6>Telefon:</h6>,
            textbody: <p>08-678 55 00</p>,
          },

          {
            headline: <h6>E-post:</h6>,
            textbody: (
              <a href="mailto:kontakt@bredbandsforum.se">
                kontakt@bredbandsforum.se
              </a>
            ),
          },

          {
            headline: <h6>Adress</h6>,
            textbody: <p>Bredbandsforum, Box 6101, 102 32 Stockholm</p>,
          },
          {
            headline: <h6>Besöksadress:</h6>,
            textbody: (
              <p>Post- och telestyrelsen, Hälsingegatan 38, Stockholm</p>
            ),
          },
          {
            headline: <h6>Öppettider:</h6>,
            textbody: (
              <p>
                Växel och reception har öppet på vardagar mellan klockan 8 och
                17.
              </p>
            ),
          },
          {
            headline: <h6>Webbredaktionen:</h6>,
            textbody: (
              <p>
                Har du frågor om eller synpunkter på den här webbplatsen kan du
                skriva till{" "}
                <a href="mailto:bredbandsforum@pts.se">
                  kontakt@bredbandsforum.se.
                </a>
              </p>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

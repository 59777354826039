import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Digital Mobilitet för Framtiden"
        topParagraph=""
        images="/media/1400/Framgångsfaktorer Digital Mobilitet för Framtiden.png"
        textbody={[
          <p className="topParagraph ingress">
            Bredbandsforum presenterar i en ny{" "}
            <a href="/media/1400/Digital Mobilitet för Framtiden – Bredbandsforum_inkl bilagor.pdf">
              rapport
            </a>{" "}
            framgångsfaktorer för att skapa bättre tillgång till mobilt bredband
            i områden där det idag upplevs som bristfälligt samt effektivisera
            och förenkla övergången till nästa generations mobilnätverk – 5G.
            Genom samverkan och dialog har forumet identifierat utmaningar och
            diskuterat lösningar för vårt gemensamma mål om att skapa en digital
            mobilitet för framtiden i hela Sverige.
          </p>,
          <p>
            Tillgång till snabbt och tillförlitligt mobilt bredband har blivit
            ett grundläggande levnadsvillkor. Det är också en förutsättning för
            att uppnå målsättningen om att Sverige ska vara bäst i världen på
            att ta tillvara digitaliseringens möjligheter. Om stora grupper av
            människor, företag eller offentliga aktörer inte kan realisera
            potentialen i ny teknik och nya tjänster även utanför hemmet och
            arbetsplatsen kommer målet inte att uppnås. Mot bakgrund av detta
            har Bredbandsforum inom ramen för fokusområdet{" "}
            <a href="/media/1400/Bredbandsforums förslag till aktiviteter 2023 -230404.pdf">
              5G och Digital Mobilitet
            </a>{" "}
            analyserat nuläget och identifierat ett antal centrala utmaningar
            som kommer vara viktiga i arbetet med att formulera en ny vision vad
            gäller tillgången till digital mobilitet i Sverige.
          </p>,
          <p>
            Tillgången till mobilt bredband i Sverige har länge varit mycket god
            ur ett internationellt perspektiv. Marknadsaktörerna arbetar för att
            löpande förbättra nätens täckning och kapacitet, och det är viktigt
            att det fortsatt skapas goda förutsättningar att investera i
            utbyggnad och utveckling av infrastruktur för mobilt bredband. Dels
            finns områden – ofta på landsbygder – där tillgången fortfarande är
            bristfällig. Dels kräver konsumenter, företag och offentliga
            verksamheter över tid allt mer avancerade tjänster, även utanför
            hemmet och den fasta arbetsplatsen, och därmed kan behoven av högre
            prestanda och en stabil uppkoppling oavsett var man befinner sig
            öka.
          </p>,
          <p>
            Bredbandsforum anser att en digital mobilitet för framtiden behövs i
            hela landet, och att Sverige ska ha höga ambitioner för tillgången
            till mobilt bredband. Då krävs att mer infrastruktur etableras på
            nya platser i både landsbygder och stadsmiljöer. För att Sverige ska
            vara ledande inom området 5G och digital mobilitet bör därför
            digitaliseringspolitiken utformas så att den innehåller en tydlig
            viljeinriktning för infrastrukturens utbyggnad och utveckling.
            Tydliga och uppföljningsbara mål har varit en framgångsfaktor för
            samverkan och samarbete om bredbandsutbyggnad. Politiken bör
            innehålla specifika resonemang om infrastruktur för mobilt bredband
            både i områden där marknadsförutsättningarna är utmanande och i
            områden där efterfrågan kan förväntas vara hög.
          </p>,
          <p>
            Bredbandsforum har tidigare identifierat att mobiloperatörerna nu
            har mycket svårt att motivera en fortsatt utbyggnad av sändarplatser
            för mobilt bredband i landsbygder. En fortsatt förbättring av
            täckning och kapacitet i många områden där det rent kommersiella
            underlaget är otillräckligt förutsätter nu tillkommande
            finansiering. Bredbandsforum föreslår att PTS inom ramen för den nya
            digitaliseringspolitiken bör få i uppdrag att utreda möjligheterna
            för staten att i enlighet med EU:s regelverk initiera och finansiera
            ett statligt investeringsstöd för infrastruktur för mobilt bredband.
            I uppdraget bör ingå att utreda verksamheter och uppkopplade sakers
            behov av avancerad infrastruktur för mobila digitala tjänster och
            till vilken grad dessa behov överlappar geografiskt med befintlig
            infrastruktur.
          </p>,
          <p>
            Till sist behöver fler aktörer som kan spela en roll i att
            effektivisera utbyggnaden delta i diskussioner om utmaningar och
            lösningar. Det finns ett antal kvarvarande hinder för samverkan på
            området, och övergången till 5G aktualiserar också behovet av nya
            samverkansytor mellan aktörer på infrastrukturområdet och aktörer
            som tidigare inte deltagit i diskussionerna. Vissa aktörers
            betydelse, så som exempelvis fastighetsägares, för en effektiv
            utbyggnad kommer att öka. Staten spelar en viktig roll både i att
            skapa goda förutsättningar för aktörer att investera i utbyggnad,
            utveckling, och användning av nya nät och tjänster – och även i att
            uppmana fler nyckelspelare att förstå och acceptera sin roll i att
            främja tillgången till den infrastruktur som möjliggör både
            samhällets digitaliseringsresa såväl som deras egen.
          </p>,
          <br />,
          <p>Sammanfattningsvis konstaterar Bredbandsforum att:</p>,
          <ol>
            <li>
              För att Sverige ska vara ledande inom området 5G och digital
              mobilitet krävs att digitaliseringspolitiken utformas så att den
              innehåller en tydlig viljeinriktning för infrastrukturens
              utbyggnad och utveckling.
            </li>
            <li>
              För att Sverige ska vara ledande inom området 5G och digital
              mobilitet krävs tillkommande finansiering för att fortsätta
              utbyggnaden av infrastruktur för mobilt bredband i landsbygder och
              andra områden där det kommersiella underlaget är otillräckligt.
            </li>
            <li>
              För att Sverige ska vara ledande inom området 5G och digital
              mobilitet behöver regeringen agera för att stärka olika aktörers
              mandat och möjligheter att samverka för en effektiv
              bredbandsutbyggnad och en accelererad övergång till nästa
              generations mobilnät.
            </li>
          </ol>,
          <p>
            I slutrapporten utforskas framgångsfaktorerna djupare, och fler
            konkreta förslag för en fortsatt utbyggnad och utveckling av mobilt
            bredband presenteras.
          </p>,
          <p>
            I och med detta slutredovisar Bredbandsforum sitt arbete med att
            genom dialog och samverkan främja en ökad tillgång till digital
            mobilitet samt en accelererad övergång till nästa generations
            mobilnät.
          </p>,
          <p>
            <a href="/media/1400/Digital Mobilitet för Framtiden – Bredbandsforum_inkl bilagor.pdf">
              Klicka här för att ladda ned rapporten Digital Mobilitet för
              Framtiden inklusive bilagor.
            </a>
          </p>,

          <br />,
          <br />,
        ]}
        cardtitle="Läs mer"
        carddata={[
          {
            headline: (
              <div>
                <p>
                  <a
                    data-udi="/media/481439fbcf3f47d9ad9a2d4b114f8c7c"
                    href="/media/1400/Digital Mobilitet för Framtiden – Bredbandsforum_inkl bilagor.pdf"
                    title="Digital Mobilitet för Framtiden – Bredbandsforum_inkl bilagor.pdf"
                  >
                    Digital Mobilitet för Framtiden (PDF)
                  </a>
                </p>

                <p>
                  <a
                    data-udi="/media/481439fbcf3f47d9ad9a2d4b114f8c7c"
                    href="/media/1400/Bredbandsforums förslag till aktiviteter 2023 -230404.pdf"
                    title="Bredbandsforums förslag till aktiviteter 2023 -230404"
                  >
                    Förslag till aktiviteter 2023 (PDF)
                  </a>
                </p>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Sveriges bredbandsstrategi"
        topParagraph="Sverige ska vara bäst i världen på att ta tillvara på digitaliseringens möjligheter. För att detta ska vara möjligt måste hela Sverige vara uppkopplat."
        images="/media/1068/2126332-kindergarten-children-learning-how-to-use-computers.jpg"
        textbody={[
          <h3>Sverige helt uppkopplat 2025</h3>,
          <p>
            Den nationella politiken läggs fast av riksdag och regering genom
            beslut om regler, finansiering och politiska mål och inriktningar.
            Den nuvarande nationella bredbandspolitiken slås fast i dokumentet{" "}
            <a
              data-udi="umb://media/43512310724c47d8a2351a708c55be51"
              href="/media/1377/sverige-helt-uppkopplat-2025-slutlig.pdf"
              title="sverige-helt-uppkopplat-2025-slutlig.pdf"
            >
              Sverige helt uppkopplat 2025 - en bredbandsstrategi
            </a>
            , som regeringen beslutade i december 2016. De viktigaste målen i
            strategin är att:
          </p>,
          <ul>
            <li>
              År 2020 bör 95 procent av alla hushåll och företag ha tillgång
              till bredband om minst 100 Mbit/s.
            </li>
            <li>
              År 2023 bör hela Sverige ha tillgång till stabila mobila tjänster
              av god kvalitet.
            </li>
            <li>År 2025 bör hela Sverige ha tillgång till snabbt bredband.</li>
          </ul>,
          <p>
            Regeringens definition av målet att "hela Sverige ska ha tillgång
            till snabbt bredband" till 2025 beskrivs så här:
          </p>,
          <ul>
            <li>
              98 procent av alla hushåll och företag bör ha tillgång till 1
              Gbit/s
            </li>
            <li>
              1,9 procent av alla hushåll och företag bör ha tillgång till 100
              Mbit/s
            </li>
            <li>
              0,1 procent av alla hushåll och företag bör ha tillgång till 30
              Mbit/s
            </li>
          </ul>,
          <p>
            Politiken inom EU är en del av den nationella politiken och på
            bredbandsområdet är{" "}
            <a href="http://ec.europa.eu/information_society/digital-agenda/index_en.htm">
              Den digitala agendan för Europa
            </a>
            &nbsp;en av utgångspunkterna för den nationella poltiken i övrigt.
          </p>,
        ]}
        cardtitle="Mer information om Sveriges bredbandspolitik"
        carddata={[
          {
            textbody: (
              <div>
                <h6>
                  <a
                    href="https://bredbandsforum.se/media/1377/sverige-helt-uppkopplat-2025-slutlig.pdf"
                    title="Sveriges bredbandsstrategi"
                  >
                    Sverige helt uppkopplat 2025 - en bredbandsstrategi (PDF)
                  </a>
                </h6>
                <p>&nbsp;</p>
                <h6>
                  <a
                    href="https://www.regeringen.se/informationsmaterial/2017/05/for-ett-hallbart-digitaliserat-sverige-en-digitaliseringsstrategi/"
                    title="Sveriges digitaliseringsstrategi"
                  >
                    För ett hållbart digitaliserat Sverige - en
                    digitaliseringsstrategi
                  </a>
                </h6>
                <p>&nbsp;</p>
                <h6>
                  <a href="https://www.regeringen.se/regeringens-politik/digitaliseringspolitik/">
                    Läs mer om regeringens digitaliseringspolitik på
                    regeringen.se
                  </a>
                </h6>
                <p>&nbsp;</p>
                <h6>
                  <a
                    href="https://digital-strategy.ec.europa.eu/sv"
                    title="EU digital agenda"
                  >
                    Läs mer om EUs digitala agenda
                  </a>
                </h6>
                <p>&nbsp;</p>
                <h6>
                  <a href="http://www.pts.se">
                    Läs mer på Post- och telestyrelsens webbplats
                  </a>
                </h6>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

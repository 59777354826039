export const links = [
  {
    label: "Om Bredbandsforum",
    href: "/om-bredbandsforum",
    children: [
      {
        label: "Bredbandsforums styrgrupp",
        href: "/om-bredbandsforum/bredbandsforums-styrgrupp",
      },
      {
        label: "Bredbandsforums kansli",
        href: "/om-bredbandsforum/bredbandsforums-kansli",
      },
      {
        label: "Bredbandsforums nyhetsbrev",
        href: "/om-bredbandsforum/bredbandsforums-nyhetsbrev",
      },
      {
        label: "Riktlinjer för personuppgiftshantering",
        href: "/om-bredbandsforum/riktlinjer-foer-personuppgiftshantering",
      },
      {
        label: "Tillgänglighet för bredbandsforum.se",
        href: "/om-bredbandsforum/tillgaenglighet-foer-bredbandsforumse",
      },
    ],
  },
  {
    label: "Sveriges bredbandsstrategi",
    href: "/sveriges-bredbandsstrategi",
  },
  {
    label: "Färdplan för måluppfyllnad 2025",
    href: "/faerdplan-foer-maaluppfyllnad-2025",
  },
  {
    label: "Digital mobilitet och 5G",
    href: "/digital-mobilitet-och-5g",
  },
  {
    label: "Avslutade arbetsgrupper",
    href: "/avslutade-arbetsgrupper",
  },
  {
    label: "Bredbandskoordinatorer",
    href: "/bredbandskoordinatorer",
  },
  {
    label: "Bredbandslyftet",
    href: "/bredbandslyftet",
  },

  {
    label: "Lättläst",
    href: "/laettlaest",
  },

  {
    label: "English",
    href: "/english",
  },
];

import { Templet1 } from "../templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Bredbandsforums nyhetsbrev"
        topParagraph="Med Bredbandsforums nyhetsbrev vänder vi oss till dig som är intresserad av vad som är på gång inom Bredbandsforum och arbetet mot ett helt uppkopplat Sverige."
        images="/media/1113/6765971-teenage-boy-using-digital-tablet-and-mobile-phone-in-bedroom.jpg"
        textbody={[
          <h3>Ett nyhetsbrev om bredbandsutbyggnad och digitalisering</h3>,
          <p>
            Det artonde numret publicerades i december 2018 och innehåller
            följande artiklar:
          </p>,
          <ul>
            <li>Uppkopplad! – Nationell bredbandskonferens i Vara</li>
            <li>Bredbandslyftet</li>
            <li>Infrastruktur för digitalisering</li>
            <li>Nya Byanätsforum</li>
            <li>Två år med utbyggnadslagen</li>
            <li>Material från Bredbandsforums styrgrupp</li>
            <li>Nöjdare företag i kommuner med snabbt bredband</li>
            <li>Ny teknik för mobilt bredband</li>
          </ul>,
          <p>
            <a
              data-udi="umb://media/49ebd0f52e5b4d73845b37932b924d60"
              href="/media/1221/bredbandsforums-nyhetsbrev-nr-18-december-2018.pdf"
              title="Bredbandsforums nyhetsbrev nr 18 – december 2018.pdf"
            >
              Läs Bredbandsforums artonde nyhetsbrev – december 2018
            </a>
          </p>,
          <p>
            <a
              data-udi="umb://media/9934984e1615447c9a226804b0775b02"
              href="/media/1222/ny-teknik-foer-mobilt-bredband-bredbandsforums-kansli-augusti-2018.pdf"
              title="Ny teknik för mobilt bredband  Bredbandsforums kansli augusti 2018.pdf"
            >
              Ladda ned en PDF-version av artikeln "Ny teknik för mobilt
              bredband"
            </a>
          </p>,
        ]}
        cardtitle="Tidigare nyhetsbrev från Bredbandsforums kansli:"
        carddata={[
          {
            textbody: (
              <div>
                <h6>
                  <a
                    data-udi="umb://media/fb83c00df42d4b34b604b8d9d5fa855c"
                    href="/media/1220/bredbandsforums-nyhetsbrev-nr-17-november-2017.pdf"
                    title="Bredbandsforums nyhetsbrev nr 17 – november 2017.pdf"
                  >
                    Nyhetsbrev 17 <span>– november 2017</span>
                  </a>
                </h6>
                <h6>
                  <span></span>
                  <a
                    data-udi="umb://media/a00179d8587144888cb2a4c78b820d8c"
                    href="/media/1203/bredbandsforums-nyhetsbrev-specialnummer-februari-2017.pdf"
                    title="Bredbandsforums nyhetsbrev – Specialnummer – februari 2017.pdf"
                  >
                    <span id=">_som_pilhuvud"></span>
                  </a>
                  <a
                    data-udi="umb://media/774de17f50d34ae298f806918a4fc00f"
                    href="/media/1219/bredbandsforums-nyhetsbrev-nr-16-juni-2017.pdf"
                    title="Bredbandsforums nyhetsbrev nr 16 – juni 2017.pdf"
                  >
                    Nyhetsbrev 16 <span>– juni 2017</span>
                  </a>
                  <span></span>
                </h6>
                <p>
                  <span>
                    <a
                      data-udi="umb://media/a00179d8587144888cb2a4c78b820d8c"
                      href="/media/1203/bredbandsforums-nyhetsbrev-specialnummer-februari-2017.pdf"
                      title="Bredbandsforums nyhetsbrev – Specialnummer – februari 2017.pdf"
                    >
                      Specialnummer <span>–</span> februari 2017
                    </a>
                  </span>
                </p>
                <p>
                  <a
                    data-udi="umb://media/c0393a28b5e74326a9392a0cd35649f4"
                    href="/media/1218/bredbandsforums-nyhetsbrev-nr-15-november-2016.pdf"
                    title="Bredbandsforums nyhetsbrev nr 15 - november 2016.pdf"
                  >
                    Nyhetsbrev 15 <span>– november 2016</span>
                  </a>
                  <span></span>
                </p>
                <h6>
                  <a
                    data-udi="umb://media/a4a4b10f0e3f4f40915e6d8a8e02022b"
                    href="/media/1217/bredbandsforums-nyhetsbrev-nr-14-juni-2016.pdf"
                    title="Bredbandsforums nyhetsbrev nr 14 – juni 2016.pdf"
                  >
                    Nyhetsbrev 14 <span>– juni 2016</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/8e183814acf745e38aa9683d560f7ccf"
                    href="/media/1216/bredbandsforums-nyhetsbrev-nr-13-februari-2016.pdf"
                    title="Bredbandsforums nyhetsbrev nr 13 – februari 2016.pdf"
                  >
                    Nyhetsbrev 13 <span>– februari 2016</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/b33164c3ef234f148e5adbefb5d3707a"
                    href="/media/1215/bredbandsforums-nyhetsbrev-nr-12-oktober-2015.pdf"
                    title="Bredbandsforums nyhetsbrev nr 12 – oktober 2015.pdf"
                  >
                    Nyhetsbrev 12 <span>– oktober 2015</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/bb5593f233fc48c5b69da0f2fe326e03"
                    href="/media/1214/bredbandsforums-nyhetsbrev-nr-11-juni-2015.pdf"
                    title="Bredbandsforums nyhetsbrev nr 11 – juni 2015.pdf"
                  >
                    Nyhetsbrev 11 <span>– juni 2015</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/22950b9ea32848beba13fb5ec87d6a47"
                    href="/media/1213/bredbandsforums-nyhetsbrev-nr-10-mars-2015.pdf"
                    title="Bredbandsforums nyhetsbrev nr 10 – Mars 2015.pdf"
                  >
                    Nyhetsbrev 10 <span>– mars 2015</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/f38b4f350619405fa2ed0e0af9d1a8b1"
                    href="/media/1212/bredbandsforums-nyhetsbrev-nr-9-november-2014.pdf"
                    title="Bredbandsforums nyhetsbrev nr 9 – november 2014.pdf"
                  >
                    Nyhetsbrev 9 <span>– oktober 2014</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/7a87bd9494d0466081be0ce76d2a3531"
                    href="/media/1211/bredbandsforums-nyhetsbrev-nr-8-juni-2014.pdf"
                    title="Bredbandsforums nyhetsbrev nr 8 – juni 2014.pdf"
                  >
                    Nyhetsbrev 8 <span>– juni 2014</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/550fbd7c88394c0b9fe38e4b9b95f906"
                    href="/media/1210/bredbandsforums-nyhetsbrev-nr-7-februari-2014.pdf"
                    title="Bredbandsforums nyhetsbrev nr 7 – februari 2014.pdf"
                  >
                    Nyhetsbrev 7 <span>– februari 2014</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/7b2fff7f63d047399f93d5e5d46f3b9b"
                    href="/media/1209/bredbandsforums-nyhetsbrev-nr-6-oktober-2013.pdf"
                    title="Bredbandsforums nyhetsbrev nr 6 – Oktober 2013.pdf"
                  >
                    Nyhetsbrev 6 <span>– oktober 2013</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/bdebb6195079449397bd1f60e7e70252"
                    href="/media/1208/bredbandsforums-nyhetsbrev-nr-5-juni-2013.pdf"
                    title="Bredbandsforums nyhetsbrev nr 5 – juni 2013.pdf"
                  >
                    Nyhetsbrev 5 <span>– maj 2013</span>
                  </a>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/26f9b40a6dfd4d4fa5202fb1193b558f"
                    href="/media/1207/bredbandsforums-nyhetsbrev-nr-4-januari-2013.pdf"
                    title="Bredbandsforums nyhetsbrev nr 4 – januari 2013.pdf"
                  >
                    Nyhetsbrev 4 <span>– januari 2013</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/e0fd93d2cc6a44f98e7499ad2221ee5e"
                    href="/media/1206/bredbandsforums-nyhetsbrev-nr-3-september-2012.pdf"
                    title="Bredbandsforums nyhetsbrev nr 3 – september 2012.pdf"
                  >
                    Nyhetsbrev 3 <span>– september </span>
                    <span>2012</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/9e850e687a6e49928be6541dfc45e5ca"
                    href="/media/1205/bredbandsforums-nyhetsbrev-nr-2-maj-2012.pdf"
                    title="Bredbandsforums nyhetsbrev nr 2 – maj 2012.pdf"
                  >
                    Nyhetsbrev 2 <span>– maj 2012</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/7b055e822c37424aae415de8351b66eb"
                    href="/media/1204/bredbandsforums-nyhetsbrev-nr-1-februari-2012.pdf"
                    title="Bredbandsforums nyhetsbrev nr 1 – februari 2012.pdf"
                  >
                    Nyhetsbrev 1 <span>– februari 2012</span>
                  </a>
                </h6>
                <p>&nbsp;</p>
                <p>
                  <strong>Tidigare nyhetsbrev från Byanätsforum:</strong>
                </p>
                <p>
                  <a
                    data-udi="umb://media/2ad1859ec00c40c1b460f4781cf6815b"
                    href="/media/1235/byanaetsforums-nyhetsbrev-nr-13-november-2017.pdf"
                    title="Byanätsforums nyhetsbrev nr 13 – november 2017.pdf"
                  >
                    Nyhetsbrev 13 <span>– november 2017 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </p>
                <h6>
                  <a
                    data-udi="umb://media/ab2f7ce541c24888bfa2688fc97b2d1c"
                    href="/media/1234/byanaetsforums-nyhetsbrev-nr-12-maj-2017.pdf"
                    title="Byanätsforums nyhetsbrev nr 12 – maj 2017.pdf"
                  >
                    Nyhetsbrev 12 <span>– maj 2017 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/77d28237450e40cea9e5abd1e5e11b47"
                    href="/media/1233/byanaetsforums-nyhetsbrev-nr-11-januari-2017.pdf"
                    title="Byanätsforums nyhetsbrev nr 11 – januari 2017.pdf"
                  >
                    Nyhetsbrev 11 <span>– januari 2017 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/181ba9374fbf413e9d79481451c950a2"
                    href="/media/1232/byanaetsforums-nyhetsbrev-nr-10-oktober-2016.pdf"
                    title="Byanätsforums nyhetsbrev nr 10 – oktober 2016.pdf"
                  >
                    Nyhetsbrev 10 <span>– oktober 2016 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/a6426e11d03043f0952ca47169a281f3"
                    href="/media/1231/byanaetsforums-nyhetsbrev-nr-9-juli-2016.pdf"
                    title="Byanätsforums nyhetsbrev nr 9 – juli 2016.pdf"
                  >
                    Nyhetsbrev 9 <span>– juli 2016 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/c8122b4b5f2047ba970411c7fe5753b3"
                    href="/media/1230/byanaetsforums-nyhetsbrev-nr-8-maj-2016.pdf"
                    title="Byanätsforums nyhetsbrev nr 8 – maj 2016.pdf"
                  >
                    Nyhetsbrev 8 <span>– maj 2016 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/30a735163fb14f52ba46b58bc772f9e5"
                    href="/media/1229/byanaetsforums-nyhetsbrev-nr-7-mars-2016.pdf"
                    title="Byanätsforums nyhetsbrev nr 7 – mars 2016.pdf"
                  >
                    Nyhetsbrev 7 <span>– mars 2016 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/c44a1c89ccb9470fb03efa5edaa0fa5b"
                    href="/media/1228/byanaetsforums-nyhetsbrev-nr-6-december-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 6 – december 2015.pdf"
                  >
                    Nyhetsbrev 6 <span>– december 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/f5ceaad8220d4a8b90ba0a8de18684c1"
                    href="/media/1227/byanaetsforums-nyhetsbrev-nr-5-oktober-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 5 – oktober 2015.pdf"
                  >
                    Nyhetsbrev 5 <span>– oktober 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/f0ac7891b07d431abe0687fb1183d054"
                    href="/media/1226/byanaetsforums-nyhetsbrev-nr-4-augusti-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 4 – augusti 2015.pdf"
                  >
                    Nyhetsbrev 4 <span>– augusti 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/b74d7cb120634649916968292783d638"
                    href="/media/1225/byanaetsforums-nyhetsbrev-nr-3-juni-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 3 – juni 2015.pdf"
                  >
                    Nyhetsbrev 3 <span>– juni 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/42674fa7cbe14c2498263be0262f450a"
                    href="/media/1224/byanaetsforums-nyhetsbrev-nr-2-maj-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 2 – maj 2015.pdf"
                  >
                    Nyhetsbrev 2 <span>– maj 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                  <span></span>
                </h6>
                <h6>
                  <a
                    data-udi="umb://media/e8a37212c5d14035830917164c39087a"
                    href="/media/1223/byanaetsforums-nyhetsbrev-nr-1-april-2015.pdf"
                    title="Byanätsforums nyhetsbrev nr 1 – april 2015.pdf"
                  >
                    Nyhetsbrev 1 <span>– april 2015 </span>
                    <span id=">_som_pilhuvud">&gt;</span>
                  </a>
                </h6>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Bredbandsforum prioriterar hållbarhet och efteranslutningar"
        images="/media/1400/hallbarhet.jpg"
        textbody={[
          <p>
            Bredbandsforums styrgrupp har beslutat om nya fokusområden för
            2024–2025: Bredband för hållbarhet och Efteranslutningar för
            konnektivitet. Dessa fokusområden syftar till att skapa
            kunskapsunderlag för att generera en fortsatt bredbandsutbyggnad.
          </p>,
          <p>
            <strong>Bredband för hållbarhet</strong>
            <br />
            Inom hållbarhetsarbetet kommer flera olika aktiviteter att
            genomföras. Detta inkluderar sammanställningar av olika
            hållbarhetsaspekter och undersökningar om bredbandsutbyggnad kan
            motiveras genom hållbarhetsargument. Arbetet innebär även att
            sammanfatta hur olika aktörer beaktar hållbarhet i sitt
            bredbandsbyggande.&nbsp;
          </p>,
          <p>
            Sverige har ambitiösa klimatmål, precis som många andra länder.
            Detta arbete kommer att analysera hur bredbandsbranschen påverkar
            och påverkas av dessa klimatmål. Flera hållbarhetsmål och perspektiv
            i den gröna omställningen, vilket är av stor betydelse för
            telekombranschen och dess långvariga arbete med hållbarhet. Syftet
            är att lyfta fram goda exempel och inspirera till vidare åtgärder.
          </p>,
          <p>
            <strong>Efteranslutningar för konnektivitet</strong>
            <br />
            Arbetet med efteranslutningar kommer att samla in olika aktörers syn
            på faktiska anslutningar och hur marknadsaktörer främjar
            anslutningar i redan utbyggda områden. Målet är att säkerställa att
            fler byggnader har fungerande bredbandsanslutningar. Bredbandsforum
            kommer också att undersöka utmaningar och möjligheter med att uppnå
            full konnektivitet i hela Sverige.
          </p>,
          <p>
            Arbetet kommer att inkludera flera perspektiv, såsom de tekniska och
            praktiska utmaningar, betydelsen av teknikneutralitet, samt
            slutanvändarnas efterfrågan. Fler perspektiv kan tillkomma, och
            många aktörer förväntas bli involverade i detta arbete. Målet är att
            förbättra förståelsen för hur full konnektivitet kan uppnås över
            hela landet.
          </p>,
          <p>
            <strong>Avslutande rapportering</strong>
            <br />
            Slutredovisning av båda dessa arbeten kommer att ske i slutet av
            2025. Bredbandsforum planerar att avsluta med en konferens. Många
            olika aktiviteter kommer äga rum fram till dess. Informationen och
            kunskapen som genereras kommer att spridas och uppdateras på
            hemsidan.
          </p>,
        ]}
        cardtitle="DOKUMENT"
        carddata={[
          {
            textbody: (
              <a
                data-udi="umb://media/49b069c82359480481d2946c411e93da"
                href="/media/1400/Bredbandforhallbarhetforslagtillaktiviteter2024-2025.pdf"
                title="Fokusområde Bredband för hållbarhet"
              >
                <strong>Fokusområde Bredband för hållbarhet (PDF)</strong>
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/e501efcd70094cfba173fc3dc2a75b2c"
                href="/media/1400/Efteranslutningarforkonnektivitet-forslagtillaktiviteter2024-2025.pdf"
                title="Fokusområde Efteranslutningar för konnektivitet"
              >
                Fokusområde Efteranslutningar för konnektivitet (PDF)
              </a>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

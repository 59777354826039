import { Link, createBrowserRouter } from "react-router-dom";
import RootLayout from "../../../pages/root/Root";
import ErrorPage from "../../../pages/root/Error";
import StartPage from "../../../pages/bredbandsforum/startsida/startsida";
import English from "../../../pages/bredbandsforum/english";
import Laettlaest from "../../../pages/bredbandsforum/laettlaest";
import BredbandsforumsKansli from "../../../pages/bredbandsforum/OmBredbandsforum/bredbandsforums-kansli";
import DigitalMobilitetOch5G from "../../../pages/bredbandsforum/digital-mobilitet-och-5g";
import FaerdplanFoerMaaluppfyllnad2025 from "../../../pages/bredbandsforum/faerdplan-foer-maaluppfyllnad-2025";
import Bredbandslyftet from "../../../pages/bredbandsforum/bredbandslyftet";
import Bredbandskoordinatorer from "../../../pages/bredbandsforum/bredbandskoordinatorer";
import AvslutadeArbetsgrupper from "../../../pages/bredbandsforum/avslutade-arbetsgrupper";
import SverigesBredbandsstrategi from "../../../pages/bredbandsforum/sveriges-bredbandsstrategi";
import TillgaenglighetFoerBredbandsforumse from "../../../pages/bredbandsforum/OmBredbandsforum/tillgaenglighet-foer-bredbandsforumse";
import OmBredbandsforum from "../../../pages/bredbandsforum/om-bredbandsforum";
import RiktlinjerFoerPersonuppgiftshantering from "../../../pages/bredbandsforum/OmBredbandsforum/riktlinjer-foer-personuppgiftshantering";
import BredbandsforumsNyhetsbrev from "../../../pages/bredbandsforum/OmBredbandsforum/bredbandsforums-nyhetsbrev";
import MaterialFraanStyrgruppen from "../../../pages/bredbandsforum/OmBredbandsforum/material-fraan-styrgruppen";
import BredbandsforumsStyrgrupp from "../../../pages/bredbandsforum/OmBredbandsforum/bredbandsforums-styrgrupp";
import { Templet2 } from "../../../pages/bredbandsforum/templates/templet2";
import NewsList from "../../../components/NewsList/NewsList";
import Hallbarhet from "../../../pages/bredbandsforum/hallbarhet-och-efteranslutningar";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => <Link to="/">Startsida</Link>,
    },
    children: [
      { index: true, element: <StartPage /> },
      {
        path: "english",
        element: <English />,
        handle: {
          crumb: () => <Link to="english">english</Link>,
        },
      },
      {
        path: "laettlaest",
        element: <Laettlaest />,
        handle: {
          crumb: () => <Link to="laettlaest">laettlaest</Link>,
        },
      },
      {
        path: "digital-mobilitet-och-5g",
        element: <DigitalMobilitetOch5G />,
        handle: {
          crumb: () => (
            <Link to="digital-mobilitet-och-5g">digital-mobilitet-och-5g</Link>
          ),
        },
      },

      {
        path: "faerdplan-foer-maaluppfyllnad-2025",
        element: <FaerdplanFoerMaaluppfyllnad2025 />,
        handle: {
          crumb: () => (
            <Link to="faerdplan-foer-maaluppfyllnad-2025">
              faerdplan-foer-maaluppfyllnad-2025
            </Link>
          ),
        },
      },
      {
        path: "bredbandslyftet",
        element: <Bredbandslyftet />,
        handle: {
          crumb: () => <Link to="bredbandslyftet">bredbandslyftet</Link>,
        },
      },
      {
        path: "bredbandskoordinatorer",
        element: <Bredbandskoordinatorer />,
        handle: {
          crumb: () => (
            <Link to="bredbandskoordinatorer">bredbandskoordinatorer</Link>
          ),
        },
      },
      {
        path: "avslutade-arbetsgrupper",
        element: <AvslutadeArbetsgrupper />,
        handle: {
          crumb: () => (
            <Link to="avslutade-arbetsgrupper">avslutade-arbetsgrupper</Link>
          ),
        },
      },
      {
        path: "sveriges-bredbandsstrategi",
        element: <SverigesBredbandsstrategi />,
        handle: {
          crumb: () => (
            <Link to="sveriges-bredbandsstrategi">
              sveriges-bredbandsstrategi
            </Link>
          ),
        },
      },
      {
        path: "om-bredbandsforum",
        handle: {
          crumb: () => <Link to="om-bredbandsforum">om-bredbandsforum</Link>,
        },

        children: [
          {
            index: true,
            element: <OmBredbandsforum />,
          },
          {
            path: "tillgaenglighet-foer-bredbandsforumse",
            element: <TillgaenglighetFoerBredbandsforumse />,
            handle: {
              crumb: () => (
                <Link to="om-bredbandsforum/tillgaenglighet-foer-bredbandsforumse">
                  Bredbandsforums styrgrupp
                </Link>
              ),
            },
          },
          {
            path: "riktlinjer-foer-personuppgiftshantering",
            element: <RiktlinjerFoerPersonuppgiftshantering />,
            handle: {
              crumb: () => (
                <Link to="om-bredbandsforum/riktlinjer-foer-personuppgiftshantering">
                  riktlinjer-foer-personuppgiftshantering
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforums-nyhetsbrev",
            element: <BredbandsforumsNyhetsbrev />,
            handle: {
              crumb: () => (
                <Link to="om-bredbandsforum/bredbandsforums-nyhetsbrev">
                  bredbandsforums-nyhetsbrev
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforums-kansli",
            element: <BredbandsforumsKansli />,
            handle: {
              crumb: () => (
                <Link to="bredbandsforums-kansli">bredbandsforums-kansli</Link>
              ),
            },
          },

          {
            path: "bredbandsforums-styrgrupp",
            element: <BredbandsforumsStyrgrupp />,
            handle: {
              crumb: () => (
                <Link to="bredbandsforums-styrgrupp">
                  bredbandsforums-styrgrupp
                </Link>
              ),
            },
          },
        ],
      },

      {
        path: "nyheter",
        handle: {
          crumb: () => <Link to="nyheter">nyheter</Link>,
        },

        children: [
          {
            index: true,
            element: <NewsList />,
          },
          {
            path: "bredbandsforums-nyhetsbrev-december-2018",
            element: (
              <Templet2 router="bredbandsforums-nyhetsbrev-december-2018" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsforums-nyhetsbrev-december-2018">
                  bredbandsforums-nyhetsbrev-december-2018
                </Link>
              ),
            },
          },
          {
            path: "pts-vaegledning-om-traadloes-teknik",
            element: <Templet2 router="pts-vaegledning-om-traadloes-teknik" />,
            handle: {
              crumb: () => (
                <Link to="pts-vaegledning-om-traadloes-teknik">
                  pts-vaegledning-om-traadloes-teknik
                </Link>
              ),
            },
          },
          {
            path: "nya-bredbandsforumse",
            element: <Templet2 router="nya-bredbandsforumse" />,
            handle: {
              crumb: () => (
                <Link to="nya-bredbandsforumse">nya-bredbandsforumse</Link>
              ),
            },
          },
          {
            path: "efteranslutningar",
            element: <Templet2 router="efteranslutningar" />,
            handle: {
              crumb: () => (
                <Link to="efteranslutningar">efteranslutningar</Link>
              ),
            },
          },
          {
            path: "sveriges-digitala-konkurrenskraft-staar-sig-vael-i-internationell-jaemfoerelse",
            element: (
              <Templet2 router="sveriges-digitala-konkurrenskraft-staar-sig-vael-i-internationell-jaemfoerelse" />
            ),
            handle: {
              crumb: () => (
                <Link to="sveriges-digitala-konkurrenskraft-staar-sig-vael-i-internationell-jaemfoerelse">
                  sveriges-digitala-konkurrenskraft-staar-sig-vael-i-internationell-jaemfoerelse
                </Link>
              ),
            },
          },
          {
            path: "vaegledning-om-hur-kommuner-boer-agera-paa-bredbandsmarknaden",
            element: (
              <Templet2 router="vaegledning-om-hur-kommuner-boer-agera-paa-bredbandsmarknaden" />
            ),
            handle: {
              crumb: () => (
                <Link to="vaegledning-om-hur-kommuner-boer-agera-paa-bredbandsmarknaden">
                  vaegledning-om-hur-kommuner-boer-agera-paa-bredbandsmarknaden
                </Link>
              ),
            },
          },
          {
            path: "infrastruktur-for-digitalisering",
            element: <Templet2 router="infrastruktur-for-digitalisering" />,
            handle: {
              crumb: () => (
                <Link to="infrastruktur-for-digitalisering">
                  infrastruktur-for-digitalisering
                </Link>
              ),
            },
          },
          {
            path: "pts-ska-konkretisera-utformningen-av-framtidens-stoedinsatser-foer-bredbandsutbyggnaden",
            element: (
              <Templet2 router="pts-ska-konkretisera-utformningen-av-framtidens-stoedinsatser-foer-bredbandsutbyggnaden" />
            ),
            handle: {
              crumb: () => (
                <Link to="pts-ska-konkretisera-utformningen-av-framtidens-stoedinsatser-foer-bredbandsutbyggnaden">
                  pts-ska-konkretisera-utformningen-av-framtidens-stoedinsatser-foer-bredbandsutbyggnaden
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforum-ska-fraemja-infrastruktur-foer-mobilt-bredband",
            element: (
              <Templet2 router="bredbandsforum-ska-fraemja-infrastruktur-foer-mobilt-bredband" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsforum-ska-fraemja-infrastruktur-foer-mobilt-bredband">
                  bredbandsforum-ska-fraemja-infrastruktur-foer-mobilt-bredband
                </Link>
              ),
            },
          },
          {
            path: "nya-stoedpengar-till-bredbandsutbyggnad",
            element: (
              <Templet2 router="nya-stoedpengar-till-bredbandsutbyggnad" />
            ),
            handle: {
              crumb: () => (
                <Link to="nya-stoedpengar-till-bredbandsutbyggnad">
                  nya-stoedpengar-till-bredbandsutbyggnad
                </Link>
              ),
            },
          },
          {
            path: "byanaetsforum-anordnar-nationell-konferens",
            element: (
              <Templet2 router="byanaetsforum-anordnar-nationell-konferens" />
            ),
            handle: {
              crumb: () => (
                <Link to="byanaetsforum-anordnar-nationell-konferens">
                  byanaetsforum-anordnar-nationell-konferens
                </Link>
              ),
            },
          },
          {
            path: "22-miljarder-kraevs-foer-att-naa-bredbandsmaal-till-2025-enligt-post-och-telestyrelsen",
            element: (
              <Templet2 router="22-miljarder-kraevs-foer-att-naa-bredbandsmaal-till-2025-enligt-post-och-telestyrelsen" />
            ),
            handle: {
              crumb: () => (
                <Link to="22-miljarder-kraevs-foer-att-naa-bredbandsmaal-till-2025-enligt-post-och-telestyrelsen">
                  22-miljarder-kraevs-foer-att-naa-bredbandsmaal-till-2025-enligt-post-och-telestyrelsen
                </Link>
              ),
            },
          },
          {
            path: "nya-uppdrag-till-bredbandsforum",
            element: <Templet2 router="nya-uppdrag-till-bredbandsforum" />,
            handle: {
              crumb: () => (
                <Link to="nya-uppdrag-till-bredbandsforum">
                  nya-uppdrag-till-bredbandsforum
                </Link>
              ),
            },
          },
          {
            path: "noejdare-foeretag-i-kommuner-med-snabbt-bredband",
            element: (
              <Templet2 router="noejdare-foeretag-i-kommuner-med-snabbt-bredband" />
            ),
            handle: {
              crumb: () => (
                <Link to="noejdare-foeretag-i-kommuner-med-snabbt-bredband">
                  noejdare-foeretag-i-kommuner-med-snabbt-bredband
                </Link>
              ),
            },
          },
          {
            path: "sexton-bredbandslyft-senare",
            element: <Templet2 router="sexton-bredbandslyft-senare" />,
            handle: {
              crumb: () => (
                <Link to="sexton-bredbandslyft-senare">
                  sexton-bredbandslyft-senare
                </Link>
              ),
            },
          },
          {
            path: "pts-foerslag-till-nytt-bredbandsstoed",
            element: (
              <Templet2 router="pts-foerslag-till-nytt-bredbandsstoed" />
            ),
            handle: {
              crumb: () => (
                <Link to="pts-foerslag-till-nytt-bredbandsstoed">
                  pts-foerslag-till-nytt-bredbandsstoed
                </Link>
              ),
            },
          },
          {
            path: "saa-kan-efteranslutningar-till-fibernaetet-fraemjas",
            element: (
              <Templet2 router="saa-kan-efteranslutningar-till-fibernaetet-fraemjas" />
            ),
            handle: {
              crumb: () => (
                <Link to="saa-kan-efteranslutningar-till-fibernaetet-fraemjas">
                  saa-kan-efteranslutningar-till-fibernaetet-fraemjas
                </Link>
              ),
            },
          },
          {
            path: "fortsatt-fokus-paa-bredbandsutbyggnad-i-sveriges-regioner",
            element: (
              <Templet2 router="fortsatt-fokus-paa-bredbandsutbyggnad-i-sveriges-regioner" />
            ),
            handle: {
              crumb: () => (
                <Link to="fortsatt-fokus-paa-bredbandsutbyggnad-i-sveriges-regioner">
                  fortsatt-fokus-paa-bredbandsutbyggnad-i-sveriges-regioner
                </Link>
              ),
            },
          },
          {
            path: "infrastruktur2023",
            element: <Templet2 router="infrastruktur2023" />,
            handle: {
              crumb: () => (
                <Link to="infrastruktur2023">infrastruktur2023</Link>
              ),
            },
          },
          {
            path: "10-aar-med-bredbandsforum",
            element: <Templet2 router="10-aar-med-bredbandsforum" />,
            handle: {
              crumb: () => (
                <Link to="10-aar-med-bredbandsforum">
                  10-aar-med-bredbandsforum
                </Link>
              ),
            },
          },
          {
            path: "snabbt-bredband-underlaettar-hemarbete",
            element: (
              <Templet2 router="snabbt-bredband-underlaettar-hemarbete" />
            ),
            handle: {
              crumb: () => (
                <Link to="snabbt-bredband-underlaettar-hemarbete">
                  snabbt-bredband-underlaettar-hemarbete
                </Link>
              ),
            },
          },
          {
            path: "1-4-extra-miljarder-i-bredbandsstoed-2021",
            element: (
              <Templet2 router="1-4-extra-miljarder-i-bredbandsstoed-2021" />
            ),
            handle: {
              crumb: () => (
                <Link to="1-4-extra-miljarder-i-bredbandsstoed-2021">
                  1-4-extra-miljarder-i-bredbandsstoed-2021
                </Link>
              ),
            },
          },
          {
            path: "pts-tilldelar-bredbandsstoed-till-44-projekt",
            element: (
              <Templet2 router="pts-tilldelar-bredbandsstoed-till-44-projekt" />
            ),
            handle: {
              crumb: () => (
                <Link to="pts-tilldelar-bredbandsstoed-till-44-projekt">
                  pts-tilldelar-bredbandsstoed-till-44-projekt
                </Link>
              ),
            },
          },
          {
            path: "utmaningar-i-drift-och-underhaall-av-fastighetsnaet",
            element: (
              <Templet2 router="utmaningar-i-drift-och-underhaall-av-fastighetsnaet" />
            ),
            handle: {
              crumb: () => (
                <Link to="utmaningar-i-drift-och-underhaall-av-fastighetsnaet">
                  utmaningar-i-drift-och-underhaall-av-fastighetsnaet
                </Link>
              ),
            },
          },
          {
            path: "fortsatt-stoed-till-regionala-bredbandskoordinatorer",
            element: (
              <Templet2 router="fortsatt-stoed-till-regionala-bredbandskoordinatorer" />
            ),
            handle: {
              crumb: () => (
                <Link to="fortsatt-stoed-till-regionala-bredbandskoordinatorer">
                  fortsatt-stoed-till-regionala-bredbandskoordinatorer
                </Link>
              ),
            },
          },
          {
            path: "digitalhjaelpen",
            element: <Templet2 router="digitalhjaelpen" />,
            handle: {
              crumb: () => <Link to="digitalhjaelpen">digitalhjaelpen</Link>,
            },
          },
          {
            path: "byanaetsforum-blir-branschorganisation-foer-fiberfoereningar",
            element: (
              <Templet2 router="byanaetsforum-blir-branschorganisation-foer-fiberfoereningar" />
            ),
            handle: {
              crumb: () => (
                <Link to="byanaetsforum-blir-branschorganisation-foer-fiberfoereningar">
                  byanaetsforum-blir-branschorganisation-foer-fiberfoereningar
                </Link>
              ),
            },
          },
          {
            path: "ny-arbetsgrupp-inom-bredbandsforum-avtal-som-verktyg-foer-samverkan",
            element: (
              <Templet2 router="ny-arbetsgrupp-inom-bredbandsforum-avtal-som-verktyg-foer-samverkan" />
            ),
            handle: {
              crumb: () => (
                <Link to="ny-arbetsgrupp-inom-bredbandsforum-avtal-som-verktyg-foer-samverkan">
                  ny-arbetsgrupp-inom-bredbandsforum-avtal-som-verktyg-foer-samverkan
                </Link>
              ),
            },
          },
          {
            path: "bredband-en-allt-viktigare-fraaga-foer-sveriges-regioner",
            element: (
              <Templet2 router="bredband-en-allt-viktigare-fraaga-foer-sveriges-regioner" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredband-en-allt-viktigare-fraaga-foer-sveriges-regioner">
                  bredband-en-allt-viktigare-fraaga-foer-sveriges-regioner
                </Link>
              ),
            },
          },
          {
            path: "fastighetsnat",
            element: <Templet2 router="fastighetsnat" />,
            handle: {
              crumb: () => <Link to="fastighetsnat">fastighetsnat</Link>,
            },
          },
          {
            path: "senaste-nytt-om-bredbandsforums-samverkansprojekt-foer-baettre-mobiltaeckning",
            element: (
              <Templet2 router="senaste-nytt-om-bredbandsforums-samverkansprojekt-foer-baettre-mobiltaeckning" />
            ),
            handle: {
              crumb: () => (
                <Link to="senaste-nytt-om-bredbandsforums-samverkansprojekt-foer-baettre-mobiltaeckning">
                  senaste-nytt-om-bredbandsforums-samverkansprojekt-foer-baettre-mobiltaeckning
                </Link>
              ),
            },
          },
          {
            path: "bredbandslyftet-utvaerdering-och-framtid",
            element: (
              <Templet2 router="bredbandslyftet-utvaerdering-och-framtid" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandslyftet-utvaerdering-och-framtid">
                  bredbandslyftet-utvaerdering-och-framtid
                </Link>
              ),
            },
          },
          {
            path: "ny-vaegledning-i-fraagor-om-oeverenskommelser-och-avtal-om-samverkan-kring-bredbandsutbyggnad",
            element: (
              <Templet2 router="ny-vaegledning-i-fraagor-om-oeverenskommelser-och-avtal-om-samverkan-kring-bredbandsutbyggnad" />
            ),
            handle: {
              crumb: () => (
                <Link to="ny-vaegledning-i-fraagor-om-oeverenskommelser-och-avtal-om-samverkan-kring-bredbandsutbyggnad">
                  ny-vaegledning-i-fraagor-om-oeverenskommelser-och-avtal-om-samverkan-kring-bredbandsutbyggnad
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforum-fokuserar-paa-maaluppfyllelse-2025",
            element: (
              <Templet2 router="bredbandsforum-fokuserar-paa-maaluppfyllelse-2025" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsforum-fokuserar-paa-maaluppfyllelse-2025">
                  bredbandsforum-fokuserar-paa-maaluppfyllelse-2025
                </Link>
              ),
            },
          },
          {
            path: "kommer-regeringens-bredbandsmaal-att-uppnaas",
            element: (
              <Templet2 router="kommer-regeringens-bredbandsmaal-att-uppnaas" />
            ),
            handle: {
              crumb: () => (
                <Link to="kommer-regeringens-bredbandsmaal-att-uppnaas">
                  kommer-regeringens-bredbandsmaal-att-uppnaas
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforum-undersoeker-intresset-foer-samhaellsmaster",
            element: (
              <Templet2 router="bredbandsforum-undersoeker-intresset-foer-samhaellsmaster" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsforum-undersoeker-intresset-foer-samhaellsmaster">
                  bredbandsforum-undersoeker-intresset-foer-samhaellsmaster
                </Link>
              ),
            },
          },
          {
            path: "samhaellsmastens-framtid",
            element: <Templet2 router="samhaellsmastens-framtid" />,
            handle: {
              crumb: () => (
                <Link to="samhaellsmastens-framtid">
                  samhaellsmastens-framtid
                </Link>
              ),
            },
          },
          {
            path: "bredbandsutbyggnad-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning",
            element: (
              <Templet2 router="bredbandsutbyggnad-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsutbyggnad-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning">
                  bredbandsutbyggnad-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning
                </Link>
              ),
            },
          },
          {
            path: "faerdplan-foer-maaluppfyllnad-2025",
            element: <Templet2 router="faerdplan-foer-maaluppfyllnad-2025" />,
            handle: {
              crumb: () => (
                <Link to="faerdplan-foer-maaluppfyllnad-2025">
                  faerdplan-foer-maaluppfyllnad-2025
                </Link>
              ),
            },
          },
          {
            path: "bredbandsforums-samverkansprocess-foer-etablering-av-saendarplatser-i-omraaden-med-utmaningar",
            element: (
              <Templet2 router="bredbandsforums-samverkansprocess-foer-etablering-av-saendarplatser-i-omraaden-med-utmaningar" />
            ),
            handle: {
              crumb: () => (
                <Link to="bredbandsforums-samverkansprocess-foer-etablering-av-saendarplatser-i-omraaden-med-utmaningar">
                  bredbandsforums-samverkansprocess-foer-etablering-av-saendarplatser-i-omraaden-med-utmaningar
                </Link>
              ),
            },
          },
          {
            path: "Digital-Mobilitet-for-Framtiden",
            element: <DigitalMobilitetOch5G />,
            handle: {
              crumb: () => (
                <Link to="digital-mobilitet-och-5g">
                  Digital Mobilitet for Framtiden
                </Link>
              ),
            },
          },
          {
            path: "hallbarhet-och-efteranslutningar",
            element: <Hallbarhet />,
            handle: {
              crumb: () => (
                <Link to="hallbarhet-och-efteranslutningar">
                  hållbarhet-och-efteranslutningar
                </Link>
              ),
            },
          },
        ],
      },
    ],
  },
]);

import { Col, Container } from "react-bootstrap";
import "./style.css";
import { CardTextBoxComponent } from "../../../components/Card/Card";

export function Templet1({
  headline,
  topParagraph,
  images,
  textbody,
  cardtitle,
  carddata,
}: any) {
  return (
    <div className="templet1">
      <div className="top"></div>

      <Container className="row mx-auto content">
        <Col className="main col-md-8">
          <Container className="topContainer">
            <h1>{headline}</h1>
            <p className="topParagraph ingress">{topParagraph}</p>
          </Container>
          <Container className="mainContainer">
            <a href={images}>
              <img src={images} alt={headline}></img>
            </a>
            <hr></hr>
            {textbody}
          </Container>
        </Col>

        {cardtitle && (
          <Col className="asaid col-md-4">
            <CardTextBoxComponent title={cardtitle} cardBody={carddata} />
          </Col>
        )}
      </Container>
    </div>
  );
}

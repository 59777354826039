import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Avslutade arbetsgrupper"
        topParagraph="Arbetsgrupperna är centrala för Bredbandsforums arbete. Det är i arbetsgrupperna som fokusering sker genom att enskilda sakfrågor belyses och diskuteras från olika perspektiv."
        images="/media/1121/15052265-arbetsplats-for-bredbandsutbyggnad.jpg"
        textbody={[
          <h3>Bredbandsforum har genomfört 20 arbetsgrupper</h3>,
          <p>
            Sedan 2010 har Bredbandsforums arbetsgrupper identifierat utmaningar
            och hittat lösningar för att genomföra regeringens
            bredbandsstrategi. Klicka på länkarna till höger för att ladda ned
            komprimerade filer med allt material från Bredbandsforums
            arbetsgrupper. Här nedanför finns en kort beskrivning av varje
            arbetsgrupp, samt slutrapporten från arbetet.
          </p>,
          <hr />,
          <h4>Avtal som verktyg för samverkan (arbetsgrupp 20)</h4>,
          <p>
            Bredbandsforums arbetsgrupp{" "}
            <em>Avtal som verktyg för samverkan </em>undersökte överenskommelser
            i olika former som används för att bestämma upplägg och
            målsättningar för samverkan kring bredbandsutbyggnad. Dessa kallas
            ofta exempelvis för ”samverkansavtal”, ”intentionsavtal” eller
            ”avsiktsförklaringar”. Arbetsgruppen producerade ett
            diskussionsunderlag som kan ge vägledning i viktiga frågor om
            exempelvis lagstiftning, upphandling och konkurrensutsättning.
          </p>,
          <p>
            <a
              data-udi="umb://media/bc5570d688884c6881cd60ad1fb5f146"
              href="/media/1319/slutrapport-bredbandsforums-arbetsgrupp-avtal-som-verktyg-foer-samverkan-oktober-2021.pdf"
              title="Slutrapport – Bredbandsforums Arbetsgrupp Avtal som Verktyg för Samverkan – Oktober 2021.pdf"
            >
              Arbetsgruppens slutrapport – november 2021
            </a>
          </p>,
          <hr />,
          <h4>Infrastruktur för mobilt bredband 2023 (Arbetsgrupp 19)</h4>,
          <p>
            Bredbandsforums arbetsgrupp{" "}
            <em>Infrastruktur för mobilt bredband 2023</em> har undersökt hur
            tillgången till mobilt bredband i Sverige kan förbättras. Tillgången
            till mobilt bredband är i dagsläget förhållandevis god, men behöver
            utvecklas för att möta konsumenters, företags, och offentliga
            verksamheters kommande behov och krav. Arbetsgruppen lämnar i sin
            slutrapport konkreta förslag på hur olika aktörer kan främja
            utbyggnaden av infrastruktur för mobilt bredband.
          </p>,
          <p>
            <a
              data-udi="umb://media/8e8a243c7e2941b39b6642fdbe6335eb"
              href="/media/1286/2020-infrastruktur-foer-mobilt-bredband-2023-slutrapport.pdf"
              title="2020 - Infrastruktur för mobilt bredband 2023 - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2020
            </a>
          </p>,
          <hr />,
          <h4>Infrastruktur för digitalisering (Arbetsgrupp 18)</h4>,
          <p>
            Bredbandsforums arbetsgrupp{" "}
            <em>Infrastruktur för digitalisering</em> arbetade under hösten 2018
            - våren 2019 för en ökad robusthet och tillförlitlighet i
            bredbandsinfrastrukturen. I arbetsgruppens slutrapport kartläggs den
            kedja av olika typer av bredbandsinfrastruktur, aktörer och
            lösningar som måste samspela för att säkerställa en god robusthet
            och tillförlitlighet. Arbetsgruppens slutsats är att det finns
            brister, och att vi ännu inte är redo att möta de krav som
            morgondagens digitala tjänster kommer att ställa.
          </p>,
          <p>
            <a
              data-udi="umb://media/243c1ee8c4b640b08c8ea33d840eb041"
              href="/media/1252/2019-infrastruktur-foer-digitalisering-slutrapport.pdf"
              title="2019 – Infrastruktur för digitalisering – Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2019
            </a>
          </p>,
          <hr />,
          <h4>
            Kommungruppen för samverkan och processer på kommunal nivå
            (Arbetsgrupp 17)
          </h4>,
          <p>
            Bredbandsforums kommungrupp för bättre samordning och effektivare
            processer arbetade under hösten 2017 - våren 2018 med att ta fram en
            vägledning till kommuner,{" "}
            <a
              data-udi="umb://document/f18c5ad8ff714c6088f24929f15aff14"
              href="/bredbandslyftet/"
              title="Bredbandslyftet"
            >
              Bredbandslyftet
            </a>
            . Bredbandslyftet kan bidra till en mer effektiv bredbandsutbyggnad
            på lokal och regional nivå, och också underlätta för samverkan och
            erfarenhetsutbyte kring bredbandsutbyggnad inom regionen.
          </p>,
          <p>
            <a
              data-udi="umb://media/430e74323d5c4931a3b431fd4af66a22"
              href="/media/1163/2018-kommungruppen-slutrapport.pdf"
              title="2018 - Kommungruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2018
            </a>
          </p>,
          <hr />,
          <h4>Landsbygdsgruppen (Arbetsgrupp 16)</h4>,
          <p>
            Bredbandsforums arbetsgrupp för ett helt uppkopplat Sverige –
            oavsett var man bor eller verkar. Målet med arbetsgruppen var att
            med utgångspunkt i regeringens bredbandsstrategi lämna förslag på
            hur bredbandsutbyggnaden på landsbygden kan underlättas.
          </p>,
          <p>
            <a
              data-udi="umb://media/15936b7fc68b441f9033fda23fe0bf78"
              href="/media/1164/2018-landsbygdsgruppen-slutrapport.pdf"
              title="2018 - Landsbygdsgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – februari 2018
            </a>
          </p>,
          <hr />,
          <h4>Nystartsgruppen (Arbetsgrupp 15)</h4>,
          <p>
            Nystartsgruppen har identifierat och prioriterat de utmaningar som
            försvårar för hushållen och företagen i Sverige att få tillgång till
            framtidssäkert bredband. Arbetet ligger till grund för
            Bredbandsforums pågående och kommande arbetsgrupper.
          </p>,
          <p>
            <a
              data-udi="umb://media/83204b0cb159472ea6c5b4e547d81d3f"
              href="/media/1162/2017-nystartsgruppen-slutrapport.pdf"
              title="2017 - Nystartsgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2017
            </a>
          </p>,
          <hr />,
          <h4>Praktikfallsgruppen (Arbetsgrupp 14)</h4>,
          <p>
            Bredbandsforums praktikfallsgrupp arbetade under perioden oktober
            2015 - februari 2016 med att undersöka e-hälsoområdet och
            identifiera utmaningar som måste lösas för att hela Sveriges
            befolkning ska kunna ta del av vård och omsorg genom
            bredbandsinfrastruktur.
          </p>,
          <p>
            <a
              data-udi="umb://media/dcea0bc4d7c74181bb3ed9c8f0425f6a"
              href="/media/1161/2016-praktikfallsgruppen-slutrapport.pdf"
              title="2016 - Praktikfallsgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – februari 2016
            </a>
          </p>,
          <hr />,
          <h4>Samordnad Efterfrågan (Arbetsgrupp 13)</h4>,
          <p>
            Arbetsgruppen för Samordnad Efterfrågan undersökte hur vi med hjälp
            av samordning kan göra det mer attraktivt att investera i
            bredbandsinfrastruktur.
          </p>,
          <p>
            <a
              data-udi="umb://media/ad2a2e3592154a5b86331df843286189"
              href="/media/1159/2015-samordnad-efterfraagan-slutrapport.pdf"
              title="2015 - Samordnad efterfrågan - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2015
            </a>
          </p>,
          <hr />,
          <h4>Villagruppen (Arbetsgrupp 12)</h4>,
          <p>
            Bredbandsforums Villagrupp har analyserat förutsättningarna för
            bredbandsutbyggnad till villor och tillgängliggjort
            konsumentinformation om bredband till villa.
          </p>,
          <p>
            <a
              data-udi="umb://media/b07224c75e0145468b4f0ddedfa6df07"
              href="/media/1160/2015-villagruppen-slutrapport.pdf"
              title="2015 - Villagruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2015
            </a>
          </p>,
          <hr />,
          <h4>Mandatgruppen (Arbetsgrupp 11)</h4>,
          <p>
            Målet för Bredbandsforums Mandatgrupp var att visa nyckelfaktorer
            för regional samverkan och ge vägledning i hur vi kan samarbeta
            bättre på regional nivå.
          </p>,
          <p>
            <a
              data-udi="umb://media/71d2a4de290e47c780e35932454865ac"
              href="/media/1156/2014-mandatgruppen-slutrapport.pdf"
              title="2014 - Mandatgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – oktober 2014
            </a>
          </p>,
          <hr />,
          <h4>
            Den andra robusthetsgruppen – lösningar för robustare kommunikation
            (Arbetsgrupp 10)
          </h4>,
          <p>
            Beroendet av bredband ökar i hela samhället. Bredbandsforums
            Robusthetsgrupp har därför undersökt hur vi kan göra Sveriges
            fibernät mer driftssäkra och robusta. Initiativet{" "}
            <a href="http://www.robustfiber.se">Robust fiber</a>, en vägledning
            för anläggning av fibernät, var ett förslag från arbetsgruppen. En
            robusthetsguide som ska hjälpa verksamheter att minska sin sårbarhet
            producerades också av gruppen.
          </p>,
          <p>
            <a
              data-udi="umb://media/2ea20982d6114d198bb883b9866f20bd"
              href="/media/1158/2014-robusthetsgruppen-2-slutrapport.pdf"
              title="2014 - Robusthetsgruppen 2 - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – juni 2014
            </a>
          </p>,
          <hr />,
          <h4>Mobilt bredband i hela landet (Arbetsgrupp 9)</h4>,
          <p>
            Målet med arbetsgruppens arbete var att fler hushåll, företag och
            organisationer ska kunna använda tjänster via trådlös
            bredbandsinfrastruktur och att infrastrukturen ska vara anpassad för
            att motsvara användarnas behov.
          </p>,
          <p>
            <a
              data-udi="umb://media/3bbc4932a013481cba35c1ac0cebfc35"
              href="/media/1157/2014-mobilgruppen-slutrapport.pdf"
              title="2014 - Mobilgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – februari 2014
            </a>
          </p>,
          <hr />,
          <h4>Byanätsgruppen (Arbetsgrupp 8)</h4>,
          <p>
            Byanätsgruppens uppgift var att stödja alla de ekonomiska föreningar
            som arbetar ideellt med att bygga ut bredband på landsbygden.{" "}
            <a href="http://www.byanatsforum.se">Byanätsforum</a> var ett
            initiativ från arbetsgruppen.
          </p>,
          <p>
            <a
              data-udi="umb://media/f436b3e6bcf3446f8eaf3ba27de26f6b"
              href="/media/1154/2013-byanaetsgruppen-slutrapport.pdf"
              title="2013 - Byanätsgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – maj 2013
            </a>
          </p>,
          <hr />,
          <h4>Robusthetsgruppen (Arbetsgrupp 7)</h4>,
          <p>
            Syftet med arbetsgruppen var att identifiera hur infrastrukturens
            robusthetsnivå kan utvecklas. Arbetsgruppen kom med förslag på hur
            god kvalitet i och dokumentation av infrastrukturen långsiktigt kan
            säkerställas.
          </p>,
          <p>
            <a
              data-udi="umb://media/f3286bb209f041daa26e7543f41e63b7"
              href="/media/1155/2013-robusthetsgruppen-slutrapport.pdf"
              title="2013 - Robusthetsgruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – maj 2013
            </a>
          </p>,
          <hr />,
          <h4>Kommungruppen (Arbetsgrupp 6)</h4>,
          <p>
            Syftet med arbetsgruppen var att verka för att fler kommuner ska ta
            fram bredbandsstrategier samt stödja kommunerna i deras arbete med
            bredbandsfrågan och att skapa goda förutsättningar för
            bredbandsutbyggnad.
          </p>,
          <p>
            <a
              data-udi="umb://media/b0ba264c294d406a99847dc0052583e1"
              href="/media/1152/2012-kommungruppen-slutrapport.pdf"
              title="2012 - Kommungruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – november 2012
            </a>
          </p>,
          <hr />,
          <h4>
            Stöd- och strategigruppen (Arbetsgrupp 5)
            <strong>
              <br />
            </strong>
          </h4>,
          <p>
            Syftet med arbetsgruppen var att adressera två nyckelfaktorer i
            bredbandsutbyggnad – stöd och organisation. Gruppen tog fram förslag
            på långsiktiga lösningar för bredbandsutbyggnad i områden dit
            marknaden inte når.
          </p>,
          <p>
            <a
              data-udi="umb://media/6c89fc6a06c14a248ebb407aa6a751aa"
              href="/media/1153/2012-stoed-och-strategigruppen-slutrapport.pdf"
              title="2012 - Stöd- och strategigruppen - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – maj 2012
            </a>
          </p>,
          <hr />,
          <h4>Undanröjande av identifierade hinder (Arbetsgrupp 4)</h4>,
          <p>
            Under 2010 identifierade den andra arbetsgruppen inom
            Bredbandsforum, "Utmaningar vid utbyggnad av bredband i hela
            landet", ett antal hinder för bredbandsutbyggnad och målen i
            regeringens bredbandsstrategi.
          </p>,
          <p>
            Bredbandsforums arbetsgrupp "undanröjande av identifierade hinder"
            tillsattes för att hitta lösningar och lägga en grund för det
            framtida arbetet inom Bredbandsforum.
          </p>,
          <p>
            <a
              data-udi="umb://media/7042b608643d4f86abfc471f22ea55d5"
              href="/media/1150/2011-undanroejande-av-identifierade-hinder-slutrapport.pdf"
              title="2011 - Undanröjande av identifierade hinder - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – december 2011
            </a>
          </p>,
          <hr />,
          <h4>Nytta med bredband (Arbetsgrupp 3)</h4>,
          <p>
            Målet med arbetsgruppens arbete var att skapa en bred förståelse för
            nyttan av bredband och kunskap om vilka faktorer det är som
            förhindrar eller försvårar att olika grupper i samhället tillgodogör
            sig nyttan av bredband.
          </p>,
          <p>
            <a
              data-udi="umb://media/75524f4328b74fea83a301bb75ef56c4"
              href="/media/1149/2011-nyttan-med-bredband-slutrapport.pdf"
              title="2011 - Nyttan med bredband - Slutrapport.pdf"
            >
              Arbetsgruppens slutrapport – september 2011
            </a>
          </p>,
          <hr />,
          <h4>
            Utmaningar vid utbyggnad av bredband i hela landet (Arbetsgrupp 2)
          </h4>,
          <p>
            Målet med gruppens arbete var att kartlägga utmaningar och hinder
            för utbyggnad, förbättringar av nät och utnyttjande av befintlig
            infrastruktur. Arbetsgruppen identifierade ett flertal konkreta
            hinder som delades in i ett antal huvudområden. Sju huvudområden
            ansågs ha störst betydelse för en gynnsam utveckling av
            bredbandsmarknaden och för att uppfylla regeringens målsättning.
          </p>,
          <p>
            <a
              data-udi="umb://media/a9a91b7408a94bb3ba17c590e9bfe16b"
              href="/media/1151/2011-utmaningar-vid-utbyggnad-av-bredband-i-hela-landet-slutrapport.zip"
              title="2011 - Utmaningar vid utbyggnad av bredband i hela landet - Slutrapport.zip"
            >
              Arbetsgruppens slutrapport – januari 2011
            </a>
          </p>,
          <hr />,
          <h4>Finansieringsmöjligheter för bredband (Arbetsgrupp 1)</h4>,
          <p>
            Målet med gruppens arbete var att kartlägga och utreda
            finansieringsmöjligheter för utbyggnad av bredband med hjälp av
            offentliga stöd. För att tillgängliga stödmedel ska kunna åstadkomma
            största möjliga nytta är det viktigt att göra tilldelningen effektiv
            och sprida information. Då kan stödmedlen bidra till utbyggnad av
            infrastruktur i områden där marknadsaktörer inte på egen hand bygger
            infrastruktur.
          </p>,
          <p>
            <a
              data-udi="umb://media/ebe0586b766b4306b6e0f30f01d7b2c9"
              href="/media/1148/2011-finansieringsmoejligheter-foer-bredband-slutrapport.zip"
              title="2011 - Finansieringsmöjligheter för bredband - Slutrapport.zip"
            >
              Arbetsgruppens slutrapport – maj 2011
            </a>
          </p>,
        ]}
        cardtitle="Samlat material från Bredbandsforums arbetsgrupper"
        carddata={[
          {
            textbody: (
              <ul>
                <li>
                  <a
                    data-udi="umb://media/c130d9ba11674a23be09e83359ada081"
                    href="/media/1301/infrastruktur-foer-mobilt-bredband-2023.zip"
                    title="Infrastruktur för mobilt bredband 2023.zip"
                  >
                    Infrastruktur för mobilt bredband 2023 (Arbetsgrupp 19)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/026dff2581e645edbbcb59fce73b25ce"
                    href="/media/1253/infrastruktur-foer-digitalisering-arbetsgrupp-18.zip"
                    title="Infrastruktur för digitalisering - arbetsgrupp 18.zip"
                  >
                    Infrastruktur för digitalisering (Arbetsgrupp 18)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/4348141f681b49748a75f868cf520874"
                    href="/media/1127/kommungruppen-foer-samverkan-och-processer-paa-kommunal-nivaa-arbetsgrupp-17.zip"
                    title="Kommungruppen för samverkan och processer på kommunal nivå (Arbetsgrupp 17).zip"
                  >
                    Kommungruppen för samverkan och processer på kommunal nivå
                    (Arbetsgrupp 17)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/6d36f92eb7a94f2ea1c2d596c2554697"
                    href="/media/1128/landsbygdsgruppen-arbetsgrupp-16.zip"
                    title="Landsbygdsgruppen (Arbetsgrupp 16).zip"
                  >
                    Landsbygdsgruppen (Arbetsgrupp 16)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/c81424ffce6447beb96dcccd99de9b81"
                    href="/media/1131/nystartsgruppen-arbetsgrupp-15.zip"
                    title="Nystartsgruppen (Arbetsgrupp 15).zip"
                  >
                    Nystartsgruppen (Arbetsgrupp 15)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/6e70c95f4c9744618b28a8a2617c0a26"
                    href="/media/1133/praktikfallsgruppen-arbetsgrupp-14.zip"
                    title="Praktikfallsgruppen (Arbetsgrupp 14).zip"
                  >
                    Praktikfallsgruppen (Arbetsgrupp 14)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/009a5f311be84569b5250b0129a43c83"
                    href="/media/1135/samordnad-efterfraagan-arbetsgrupp-13.zip"
                    title="Samordnad Efterfrågan (Arbetsgrupp 13).zip"
                  >
                    Samordnad Efterfrågan (Arbetsgrupp 13)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/47c322c5f5244286bbce7693a88aef20"
                    href="/media/1139/villagruppen-arbetsgrupp-12.zip"
                    title="Villagruppen (Arbetsgrupp 12).zip"
                  >
                    Villagruppen (Arbetsgrupp 12)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/7f55d5be469b4b93a52c03b6f8c9df43"
                    href="/media/1129/mandatgruppen-arbetsgrupp-11.zip"
                    title="Mandatgruppen (Arbetsgrupp 11).zip"
                  >
                    Mandatgruppen (Arbetsgrupp 11)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/b75d5cedb0c644d88120479cf06c65e6"
                    href="/media/1124/den-andra-robusthetsgruppen-loesningar-foer-robustare-kommunikation-arbetsgrupp-10.zip"
                    title="Den andra robusthetsgruppen - lösningar för robustare kommunikation (Arbetsgrupp 10).zip"
                  >
                    Den andra robusthetsgruppen – lösningar för robustare
                    kommunikation (Arbetsgrupp 10)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/04a13c3a1a9d4739a3c1279b8efb4747"
                    href="/media/1130/mobilt-bredband-i-hela-landet-arbetsgrupp-9.zip"
                    title="Mobilt bredband i hela landet (Arbetsgrupp 9).zip"
                  >
                    Mobilt bredband i hela landet (Arbetsgrupp 9)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/da801966e9084f7e9cc4abcb041f6e2b"
                    href="/media/1123/byanaetsgruppen-arbetsgrupp-8.zip"
                    title="Byanätsgruppen (Arbetsgrupp 8).zip"
                  >
                    Byanätsgruppen (Arbetsgrupp 8)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/bfd4faee94be410e9b0380fe4c540109"
                    href="/media/1134/robusthetsgruppen-arbetsgrupp-7.zip"
                    title="Robusthetsgruppen (Arbetsgrupp 7).zip"
                  >
                    Robusthetsgruppen (Arbetsgrupp 7)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/8539136fac1044d6ab22e021b65a3db7"
                    href="/media/1126/kommungruppen-arbetsgrupp-6.zip"
                    title="Kommungruppen (Arbetsgrupp 6).zip"
                  >
                    Kommungruppen (Arbetsgrupp 6)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/bbbe3a0223c14a9684a6fdc550a01aba"
                    href="/media/1136/stoed-och-strategigruppen-arbetsgrupp-5.zip"
                    title="Stöd- och strategigruppen (Arbetsgrupp 5).zip"
                  >
                    Stöd- och strategigruppen (Arbetsgrupp 5)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/bfc5c2328ba2486abb985a2dcd410cdd"
                    href="/media/1137/undanroejande-av-identifierade-hinder-arbetsgrupp-4.zip"
                    title="Undanröjande av identifierade hinder (Arbetsgrupp 4).zip"
                  >
                    Undanröjande av identifierade hinder (Arbetsgrupp 4)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/75e8e9f3aa8a4b37abeb01a3667660c0"
                    href="/media/1132/nytta-med-bredband-arbetsgrupp-3.zip"
                    title="Nytta med bredband (Arbetsgrupp 3).zip"
                  >
                    Nytta med bredband (Arbetsgrupp 3)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/980cd99edc2b422080676cf14ac767e2"
                    href="/media/1138/utmaningar-vid-utbyggnad-av-bredband-i-hela-landet-arbetsgrupp-2.zip"
                    title="Utmaningar vid utbyggnad av bredband i hela landet (Arbetsgrupp 2).zip"
                  >
                    Utmaningar vid utbyggnad av bredband i hela landet
                    (Arbetsgrupp 2)
                  </a>
                </li>
                <li>
                  <a
                    data-udi="umb://media/d22090bdc2e84a71acfe309248887d36"
                    href="/media/1125/finansieringsmoejligheter-foer-bredband-arbetsgrupp-1.zip"
                    title="Finansieringsmöjligheter för bredband (Arbetsgrupp 1).zip"
                  >
                    Finansieringsmöjligheter för bredband (Arbetsgrupp 1)
                  </a>
                </li>
              </ul>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

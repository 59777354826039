import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Färdplan för måluppfyllnad 2025"
        images="/media/1364/rekommendationer-foer-en-fortsatt-bredbandsutbyggnad-i-hela-sverige.png"
        textbody={[
          <p>
            <a
              data-udi="umb://media/49b069c82359480481d2946c411e93da"
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              <span>»</span> Klicka här för att ladda ned rapporten{" "}
              <em>Färdplan för måluppfyllnad 2025</em>
            </a>
          </p>,
          <p>
            Det är nu över sex år sedan den dåvarande regeringen presenterade en
            ny nationell{" "}
            <a href="/sveriges-bredbandsstrategi/">bredbandsstrategi</a> för ett
            helt uppkopplat Sverige. Ambitionen i strategin är att hela Sverige
            bör ha tillgång till snabbt bredband år 2025. Den övergripande
            målsättningen kommer bli svår att uppnå, då de minst kommersiellt
            intressanta områdena kvarstår att ansluta, i huvudsak på våra
            landsbygder.
          </p>,
          <p>
            Många lyfter nu behovet av nya visioner för bredbandsinfrastrukturen
            när vår nuvarande nationella bredbandsstrategi närmar sig sitt
            slutdatum. Samhällsutvecklingen medför att vi behöver ställa allt
            högre krav på bredbandsinfrastrukturens tillgänglighet och funktion
            i hela Sverige. Det förändrade säkerhetsläget, kriget i Ukraina, och
            den pandemi vi genomlevt belyser behovet av att vi måste kunna lita
            på bredbandsinfrastrukturen även under påfrestningar, och samhällets
            förväntningar på näten som helhet behöver klargöras. Tillgång till
            högpresterande digital mobilitet är en allt mer kritisk fråga för
            samtliga sektorer av Sveriges näringsliv.&nbsp;
          </p>,
          <p>
            Det är dock viktigt att alla aktörer som kan påverka
            bredbandsutbyggnaden tänker på att mycket arbete återstår innan
            målsättningarna i dagens strategi är uppnådda. Det finns fortfarande
            viktiga områden där olika aktörer kan göra insatser för att främja
            en snabb och effektiv bredbandsutbyggnad. Under år 2022 har därför
            Bredbandsforum koncentrerat verksamheten på ett antal viktiga
            utmaningar som behöver ett förnyat fokus. Arbetet har resulterat i
            rapporten{" "}
            <a
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              <em>Färdplan för måluppfyllnad 2025</em>
            </a>
            , som sammanfattar de kvarstående utmaningarna och riskfaktorerna
            för utbyggnaden, och som även innehåller ett antal rekommendationer
            som syftar till att främja en fortsatt utbyggnad av
            bredbandsinfrastruktur i hela Sverige. Rapporten behandlar
            exempelvis ämnen som hinder för tillträde till mark, otillräcklig
            tillgång till stödmedel, och otillräcklig samverkan- och
            rollfördelning mellan aktörer.
          </p>,
          <p>
            Rekommendationerna presenteras i prioritetsordning, och är främst
            inriktade på att belysa regeringens möjligheter att påverka genom
            att formulera uppdrag och bistå med finansiering. De flesta av dessa
            hinder och rekommendationer har beskrivits många gånger tidigare i
            olika sammanhang. Ytterligare diskussion eller dialog om frågorna
            inom fokusområdet bedömer Bredbandsforum nu ha små möjligheter att
            bidra med ny kunskap eller nya perspektiv. Bredbandsforum kommer
            dock på andra områden fortsätta arbetet med att genom dialog och
            samverkan främja bredbandsinfrastrukturens utbyggnad, utveckling,
            och upprätthållande.
          </p>,
          <p>
            <a
              data-udi="umb://media/49b069c82359480481d2946c411e93da"
              href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
              title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
            >
              Klicka här för att ladda ned rapporten{" "}
              <em>Färdplan för måluppfyllnad 2025</em> inklusive bilagor
            </a>
            .
          </p>,
          <p>
            <a></a>
            <strong>
              Rekommendationer för en fortsatt bredbandsutbyggnad i hela
              Sverige:
            </strong>
          </p>,
          <ol>
            <li>
              Bredbandsforum rekommenderar att regeringen ger myndigheter som
              påverkar bredbandsutbyggnaden ett samstämmigt uppdrag att arbeta
              för genomförandet av nationella strategier för bredbandsutbyggnad
              och digitalisering.
            </li>
            <li>
              Bredbandsforum stödjer PTS förslag att regeringen bör ge
              myndigheten i uppdrag att utreda behov och utformning av
              efterfrågestimulerande stöd för bredband riktade till
              slutanvändare.
            </li>
            <li>
              Bredbandsforum rekommenderar att regeringen initierar och
              finansierar ett statligt investeringsstöd för utbyggnad av
              infrastruktur för trådlöst bredband. Detta kan utformas på olika
              sätt, exempelvis genom ett stöd för etablering av samhällsmaster
              inklusive bidrag för drift- och underhållskostnader, eller genom
              att öka möjligheterna att få bidrag för finansiering av trådlösa
              lösningar inom ramen för det pågående stödprogrammet.
            </li>
            <li>
              Bredbandsforum stödjer PTS förslag att regeringen undersöker
              möjligheten att erbjuda kommuner med behov en finansiering av en
              kommunal bredbandssamordnare. Bredbandsforum stödjer även PTS
              ambition att i samverkan med SKR och de regionala
              bredbandskoordinatorerna säkerställa att kommunala
              bredbandssamordnare får relevant stöd.
            </li>
            <li>
              Bredbandsforum stödjer PTS förslag att regeringen ger myndigheten
              i uppdrag att arrangera nationella och regionala dialogmöten om
              bredbandsutbyggnad genom radioaccessnät. Bredbandsforum
              rekommenderar att regeringen agerar för att Försvarsmakten ska
              delta i dessa och liknande dialogaktiviteter om utbyggnad av
              infrastruktur för trådlöst bredband.
            </li>
            <li>
              Bredbandsforum rekommenderar att regeringen ger myndigheter som
              påverkar bredbandsutbyggnaden i uppdrag att tillskapa en
              kontaktpunkt på respektive myndighet i syfte att främja samverkan
              med myndigheter, länsstyrelser, regioner och kommuner och
              marknadsaktörer.
            </li>
            <li>
              Bredbandsforum rekommenderar regeringen, länsstyrelserna, och PTS
              att möjliggöra en samverkansplattform för erfarenhetsutbyte och
              kompetensöverföring mellan olika länsstyrelser i syfte att skapa
              större enhetlighet vad gäller tillstånds- och samrådsprocesser
              relevanta för bredbandsutbyggnad.
            </li>
            <li>
              Bredbandsforum rekommenderar att regeringen ger Trafikverket
              förutsättningar i form av uppdrag och finansiering för att kunna
              digitalisera och tillgängliggöra viktiga underlag för
              bredbandsutbyggnaden.
            </li>
            <li>
              Bredbandsforum rekommenderar regeringen att ge förutsättningar
              till Boverket i form av uppdrag och finansiering att upprätthålla
              och vidareutveckla de kompetenshöjande insatser myndigheten
              arbetat med under åren 2014-2020. Uppdraget bör innehålla
              specifika skrivningar om stöd till kommuner och regioner angående
              elektroniska kommunikationer i planeringen.
            </li>
            <li>
              Bredbandsforum stödjer riksrevisionens rekommendation att
              regeringen bör se över Lantmäteriets nuvarande avgiftsmodell, med
              syftet att åstadkomma en mer kostnadseffektiv verksamhet och en
              mer förutsägbar avgiftssättning.
            </li>
            <li>
              Bredbandsforum stödjer Boverket i dess ambition om att i samverkan
              med relevanta myndigheter skapa en samhällsbyggnadsportal.
              Information om elektronisk kommunikation är en lämplig modul för
              pilotverksamhet i uppbyggnadsfasen.
            </li>
            <li>
              Bredbandsforum rekommenderar regeringen att inrätta ett
              upphandlingsråd under ledning av upphandlingsmyndigheten. Rådets
              uppdrag skulle vara att genom samverkan identifiera brister i
              kompetens, tydlighet och resurser hos olika aktörer som vill
              upphandla bredbandsinfrastruktur och tjänster.
            </li>
          </ol>,
          <p>
            <a
              data-udi="umb://media/19f0c16d371d408ab7153467be4c5a6d"
              href="/media/1364/rekommendationer-foer-en-fortsatt-bredbandsutbyggnad-i-hela-sverige.png"
              title="Rekommendationer för en fortsatt bredbandsutbyggnad i hela Sverige.png"
            >
              Ladda ned schematisk översikt av rekommendationerna
            </a>
          </p>,
        ]}
        cardtitle="Färdplan för måluppfyllnad 2025"
        carddata={[
          {
            textbody: (
              <a
                data-udi="umb://media/49b069c82359480481d2946c411e93da"
                href="/media/1368/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum_inklusive-bilagor.pdf"
                title="Färdplan för måluppfyllnad 2025 – Bredbandsforum_inklusive bilagor.pdf"
              >
                <strong>
                  Färdplan för måluppfyllnad 2025 inklusive bilagor (PDF)
                </strong>
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/e501efcd70094cfba173fc3dc2a75b2c"
                href="/media/1366/faerdplan-foer-maaluppfyllnad-2025-bredbandsforum.pdf"
                title="Färdplan för måluppfyllnad 2025 – Bredbandsforum.pdf"
              >
                Färdplan för måluppfyllnad 2025 exklusive bilagor (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/4b055b1806184c8098acbf50e53d58aa"
                href="/media/1363/bakgrundsbeskrivning-av-prioriterade-omraaden-bredbandsforum-2022-12-22.pdf"
                title="Bakgrundsbeskrivning av prioriterade områden – Bredbandsforum 2022-12-22.pdf"
              >
                Bakgrundsbeskrivning av prioriterade områden (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/11e3331e87d0435fac6ca5f12c1f5c24"
                href="/media/1362/kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
                title="kunskapsunderlag-om-de-98-grundlaeggande-procenten-bredbandsforum-2022.pdf"
              >
                Kunskapsunderlag om de grundläggande 98 procenten (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/74b9a285ecb647578c3e97ab34363f3f"
                href="/media/1361/kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
                title="kunskapsunderlag-om-de-2-avgoerande-procenten-bredbandsforum-2022.pdf"
              >
                Kunskapsunderlag om de avgörande 2 procenten (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/929c8668983540babc3f38e57c34e3d5"
                href="/media/1359/bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
                title="bredband-och-tillstaandsgivande-statliga-myndigheter-en-kartlaeggning.pdf"
              >
                <span>
                  Bredbandsutbyggnad och tillståndsgivande statliga myndigheter
                  – en kartläggning
                </span>{" "}
                (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/b753fccd20294cafbbdf85639beacff5"
                href="/media/1358/kartlaeggning-av-intresset-foer-samhaellsmaster.pdf"
                title="Kartläggning av intresset för samhällsmaster.pdf"
              >
                <span>Kartläggning av intresset för samhällsmaster</span> (PDF)
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/2502fad096964074bcd1022b2ded9e87"
                href="/media/1360/kunskapsunderlag-om-hushaallens-betalningsfoermaaga-foer-bredbandsinfrastruktur.pdf"
                title="Kunskapsunderlag om hushållens betalningsförmåga för bredbandsinfrastruktur.pdf"
              >
                <span>
                  Kunskapsunderlag om hushållens betalningsförmåga för
                  bredbandsinfrastruktur&nbsp;
                </span>{" "}
                (PDF)
              </a>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;

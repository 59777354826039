import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../Card/Card";
import News from "../News/News";
import * as Data from "../../data";

import "./style.css";
import { useEffect, useState } from "react";

const NewsList = ({ data, loading, linkFunc }: any) => {
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * 5;
  const endIndex = startIndex + 5;
  const orderByDateData = [...Data.newslist].reverse();
  const visibleItems = orderByDateData.slice(startIndex, endIndex);
  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };
  const pageButtons = [];
  for (let i = 1; i <= Math.ceil(Data.newslist.length / 5); i++) {
    pageButtons.push(
      <button
        key={i}
        onClick={() => handlePageChange(i)}
        disabled={i === currentPage}
      >
        {i}
      </button>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <Container className="newsListContainer">
        <h1>Nyheter</h1>
        {visibleItems &&
          visibleItems.map((newsElement, index) => {
            return (
              <Row className="newsList">
                <News
                  key={index}
                  createDate={newsElement.pageData.createDate}
                  title={newsElement.pageData.headline}
                  description={newsElement.pageData.textBody}
                  link={newsElement.route}
                />
              </Row>
            );
          })}

        <div className="buttonsNewsList">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Föregående
          </button>
          {pageButtons}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(Data.newslist.length / 5)}
          >
            Nästa
          </button>
        </div>
      </Container>
    </>
  );
};

export default NewsList;

import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Bredbandslyftet"
        topParagraph="Bredbandslyftet är Bredbandsforums program för vägledning till kommuner som vill se över strategiska vägval i bredbandsfrågor."
        images="/media/1266/508720-rows-of-turnip-plants-in-a-field.jpg"
        textbody={[
          <p>
            <strong>
              Bredbandslyftet har avslutats. Inget nytt erbjudande planeras.
            </strong>
          </p>,
          <h3>Bakgrunden till Bredbandslyftet</h3>,

          <p>
            Bredbandsforums kommungrupp arbetade under 2017 och 2018 med att ta
            fram en vägledning för kommuner som vill se över strategiska vägval
            i bredbandsfrågor. Arbetsgruppen valde att inte arbeta med
            traditionell vägledning i form av till exempel en broschyr med
            allmänna råd och rekommendationer. Istället har de operatörer,
            myndigheter, intresseorganisationer, kommuner och regioner som
            deltagit i arbetet utvecklat ett program, Bredbandslyftet, som genom
            dialog och diskussion kan vägleda kommuner i de utmaningar och
            vägval man står inför i bredbandsfrågan. I{" "}
            <a
              data-udi="umb://media/430e74323d5c4931a3b431fd4af66a22"
              href="/media/1163/2018-kommungruppen-slutrapport.pdf"
              title="2018 - Kommungruppen - Slutrapport.pdf"
            >
              kommungruppens slutrapport
            </a>{" "}
            kan du läsa mer om bakgrunden till Bredbandslyftet.
          </p>,
          <p>
            Bredbandsforums kansli genomförde Bredbandslyftet med 20 kommuner
            mellan 2018-2020. Nu ska programmet erbjudas till ytterligare 10
            kommuner under våren 2021.
          </p>,
          <h3>Bredbandslyftets innehåll</h3>,
          <p>
            Sveriges kommuner väljer inriktning och påverkar förutsättningarna
            för bredbandsutbyggnaden på lokal nivå, och för att uppnå de
            nationella bredbandsmålen behöver Sveriges kommuner ha ambitiösa mål
            och ett brett engagemang. Många kommuner har kommit långt. Vissa har
            haft goda förutsättningar och resurser att ta sig an viktiga frågor
            som påverkar bredbandsutbyggnaden. Andra har haft större utmaningar,
            kommit igång sent, eller stött på problem som är svåra att
            överkomma. I vissa fall kan planer och strategiska beslut behöva
            revideras.
          </p>,
          <p>
            Bredbandsforums kommungrupp har därför utvecklat ett program för
            vägledning, som kan hjälpa en kommun se över strategiska val som
            gjorts och hitta en ny eller justerad inriktning som främjar
            bredbandstillgången i kommunen. I programmet kartläggs också
            kommunens nuvarande situation och investeringsbehovet för den
            utbyggnad som återstår uppskattas. Bredbandslyftet riktas till
            kommunledningen och genomförs på plats i kommunen.
          </p>,
          <p>
            <span>–</span> Vi vet att kommunens ledning spelar en avgörande roll
            för ett framgångsrikt arbete med bredbandsfrågor. Erfarenheterna och
            engagemanget från nyckelpersoner på politisk- och tjänstemannanivå
            behöver tas tillvara. Bredbandslyftet, som genomförs genom dialog
            och diskussion kring strategiska frågor tillsammans med dessa
            nyckelpersoner tror vi är ett bra sätt för en kommun att hitta sin
            egen väg framåt, säger Gunilla Glasare, avdelningschef på SKL och
            ordförande i Bredbandsforums kommungrupp.
          </p>,
          <p>
            Genom att genomföra Bredbandslyftet tillsammans med kommuner vill
            Bredbandsforums kommungrupp bidra till att utveckla engagemanget för
            bredbandsfrågor i Sverige. Bredbandslyftet kan bidra till en mer
            effektiv bredbandsutbyggnad på lokal och regional nivå, och också
            underlätta för samverkan och erfarenhetsutbyte kring
            bredbandsutbyggnad inom regionen.
          </p>,
          <p>
            Representerar du en kommun och är intresserad av Bredbandslyftet kan
            du kontakta din{" "}
            <a
              data-udi="umb://document/ac9b855af4a24bb788ff63a7501d274c"
              href="/bredbandskoordinatorer/"
              title="Bredbandskoordinatorer"
            >
              regionala bredbandskoordinator
            </a>
            . De regionala bredbandskoordinatorerna kommer samordna
            genomförandet av Bredbandslyftet i sin respektive region.
          </p>,
        ]}
        cardtitle="Mer information om Bredbandslyftet"
        carddata={[
          {
            textbody: (
              <a
                data-udi="umb://media/cb83fe353a3f4d3abcf89269200d3caf"
                href="/media/1236/programbeskrivning-bredbandslyftet.pdf"
                title="programbeskrivning BREDBANDSLYFTET.pdf"
              >
                Programbeskrivning Bredbandslyftet
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://media/430e74323d5c4931a3b431fd4af66a22"
                href="/media/1163/2018-kommungruppen-slutrapport.pdf"
                title="2018 - Kommungruppen - Slutrapport.pdf"
              >
                Kommungruppens slutrapport
              </a>
            ),
          },
          {
            textbody: (
              <a
                data-udi="umb://document/ac9b855af4a24bb788ff63a7501d274c"
                href="/bredbandskoordinatorer/"
                title="Bredbandskoordinatorer"
              >
                Regionala bredbandskoordinatorer
              </a>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;
